import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Check } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "@/integrations/supabase/client";

const Pricing = () => {
  const navigate = useNavigate();

  const plans = [
    {
      name: "Basic",
      price: "$29",
      description: "Perfect for small teams and startups",
      features: [
        "Up to 10 devices",
        "Basic monitoring",
        "Email support",
        "7-day history",
      ],
    },
    {
      name: "Pro",
      price: "$79",
      description: "Ideal for growing businesses",
      features: [
        "Up to 50 devices",
        "Advanced monitoring",
        "Priority support",
        "30-day history",
        "Custom alerts",
        "API access",
      ],
    },
    {
      name: "Enterprise",
      price: "Custom",
      description: "For large organizations",
      features: [
        "Unlimited devices",
        "Advanced monitoring",
        "24/7 premium support",
        "Unlimited history",
        "Custom alerts",
        "API access",
        "Custom integrations",
        "Dedicated account manager",
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-brand-50 to-brand-100">
      <div className="px-6 py-4">
        <Button
          variant="ghost"
          className="flex items-center gap-2"
          onClick={() => navigate("/")}
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Home
        </Button>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-10 pb-24 sm:pb-32">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Simple, Transparent Pricing
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Choose the plan that best fits your needs. All plans include our core features.
          </p>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-24">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className="rounded-3xl p-8 bg-white shadow-sm ring-1 ring-gray-200 xl:p-10"
            >
              <div className="flex flex-col">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">
                  {plan.name}
                </h3>
                <p className="mt-4 text-base leading-7 text-gray-600">
                  {plan.description}
                </p>
                <div className="mt-6">
                  <p className="flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {plan.price}
                    </span>
                    {plan.price !== "Custom" && (
                      <span className="text-sm font-semibold leading-6 text-gray-600">
                        /month
                      </span>
                    )}
                  </p>
                </div>
                <div className="mt-8">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button className="w-full" size="lg">
                        Get Started
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
                      <Auth
                        supabaseClient={supabase}
                        appearance={{
                          theme: ThemeSupa,
                          variables: {
                            default: {
                              colors: {
                                brand: '#2563eb',
                                brandAccent: '#1d4ed8',
                              }
                            }
                          },
                          className: {
                            container: 'w-full',
                            button: 'w-full bg-brand-500 hover:bg-brand-600 text-white',
                            input: 'rounded-md',
                          }
                        }}
                        providers={[]}
                        redirectTo={window.location.origin}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <Check className="h-5 w-5 flex-none text-brand-600" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
