
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Cookies = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Cookie Policy</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">2.1 What Are Cookies?</h2>
          <p>Cookies are small text files that are stored on your device when you visit our Website. They help us provide you with a better experience by remembering your preferences and enabling certain features.</p>

          <h2 className="text-xl font-semibold mt-6">2.2 Types of Cookies We Use</h2>
          <p>2.2.1 Essential Cookies:</p>
          <ul>
            <li>Authentication cookies to remember your login status</li>
            <li>Security cookies to help maintain the security of our Website</li>
            <li>Technical cookies necessary for the Website's operation</li>
          </ul>

          <p className="mt-4">2.2.2 Performance & Analytics Cookies:</p>
          <ul>
            <li>To understand how visitors interact with our Website</li>
            <li>To identify and resolve technical issues</li>
            <li>To improve our Website's performance</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">2.3 Cookie Management</h2>
          <p>You can control cookies through your browser settings:</p>
          <ul>
            <li>Block all cookies</li>
            <li>Delete existing cookies</li>
            <li>Allow cookies from specific websites</li>
          </ul>
          <p>Please note that blocking some types of cookies may impact your experience on our Website.</p>

          <h2 className="text-xl font-semibold mt-6">2.4 Third-Party Cookies</h2>
          <p>Some cookies may be set by third-party services we use, such as:</p>
          <ul>
            <li>Analytics services</li>
            <li>Payment processors</li>
            <li>Social media platforms</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">2.5 Updates to This Policy</h2>
          <p>We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>

          <h2 className="text-xl font-semibold mt-6">2.6 Contact Us</h2>
          <p>If you have any questions about our Cookie Policy, please contact us at:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default Cookies;
