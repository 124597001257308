
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

const Invite = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isValidating, setIsValidating] = useState(true);

  useEffect(() => {
    const validateInvitation = async () => {
      const email = searchParams.get("email");
      const orgId = searchParams.get("org");

      if (!email || !orgId) {
        toast.error("Invalid invitation link");
        navigate("/");
        return;
      }

      try {
        // Check if invitation exists and is valid (not expired)
        const { data: invitation, error } = await supabase
          .from('user_invitations')
          .select('*')
          .eq('email', email)
          .eq('organization_id', orgId)
          .eq('status', 'pending')
          .gte('expires_at', new Date().toISOString())
          .maybeSingle();

        if (error) throw error;

        if (!invitation) {
          // Specifically check if invitation has expired
          const { data: expiredInvite, error: expiredError } = await supabase
            .from('user_invitations')
            .select('expires_at')
            .eq('email', email)
            .eq('organization_id', orgId)
            .eq('status', 'pending')
            .lt('expires_at', new Date().toISOString())
            .maybeSingle();

          if (expiredError) throw expiredError;

          if (expiredInvite) {
            toast.error("This invitation has expired. Please request a new invitation.");
          } else {
            toast.error("Invalid invitation");
          }
          
          navigate("/");
          return;
        }

        // Store invitation details for after signup
        localStorage.setItem('pendingInvitation', JSON.stringify({
          email,
          organizationId: orgId,
          deviceIds: invitation.device_ids
        }));

        // Check if user is already logged in
        const { data: { user } } = await supabase.auth.getUser();
        
        if (user) {
          // If user is already logged in, handle invitation acceptance
          await handleInvitationAcceptance(user.id, invitation);
          navigate("/dashboard");
          return;
        }

        // Redirect to signup page with email pre-filled
        navigate("/?signup=true&email=" + encodeURIComponent(email));
        toast.success("Please create an account to accept the invitation");

      } catch (error) {
        console.error("Error validating invitation:", error);
        toast.error("Failed to validate invitation");
        navigate("/");
      } finally {
        setIsValidating(false);
      }
    };

    validateInvitation();
  }, [navigate, searchParams]);

  const handleInvitationAcceptance = async (userId: string, invitation: any) => {
    try {
      // Create user role for the organization
      const { error: roleError } = await supabase
        .from('user_roles')
        .insert([{
          user_id: userId,
          organization_id: invitation.organization_id,
          role: 'standard'
        }]);

      if (roleError) throw roleError;

      // Assign devices to the user if any
      if (invitation.device_ids && invitation.device_ids.length > 0) {
        const deviceAssignments = invitation.device_ids.map((deviceId: string) => ({
          user_id: userId,
          device_id: deviceId
        }));

        const { error: assignmentError } = await supabase
          .from('device_assignments')
          .insert(deviceAssignments);

        if (assignmentError) throw assignmentError;
      }

      // Update invitation status to processed
      const { error: updateError } = await supabase
        .from('user_invitations')
        .update({ status: 'processed' })
        .eq('email', invitation.email)
        .eq('organization_id', invitation.organization_id);

      if (updateError) throw updateError;

      localStorage.removeItem('pendingInvitation');
      toast.success("Invitation accepted successfully");
    } catch (error) {
      console.error("Error accepting invitation:", error);
      toast.error("Failed to process invitation");
      throw error;
    }
  };

  if (isValidating) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-brand-50 to-brand-100">
        <p className="text-lg text-gray-600">Validating invitation...</p>
      </div>
    );
  }

  return null;
};

export default Invite;
