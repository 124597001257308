import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { AlertCircle, Loader2, Calendar } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";

type InquiryType = 'information_request' | 'book_meeting' | 'trial_request';
type Industry = 'construction' | 'manufacturing' | 'infrastructure' | 'agriculture' | 'events_management' | 'destination_marketing' | 'other';

interface FormData {
  fullName: string;
  company: string;
  jobTitle: string;
  phone: string;
  email: string;
  address: string;
  inquiryType: InquiryType;
  industry?: Industry;
  quantity?: number;
  problemDescription?: string;
  neededBy?: string;
  marketingEmail: boolean;
  marketingSms: boolean;
  marketingPhone: boolean;
}

interface TrialRequestFormProps {
  onOpenChange?: (open: boolean) => void;
}

export default function TrialRequestForm({ onOpenChange }: TrialRequestFormProps) {
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showThankYou, setShowThankYou] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    company: '',
    jobTitle: '',
    phone: '',
    email: '',
    address: '',
    inquiryType: 'information_request',
    marketingEmail: false,
    marketingSms: false,
    marketingPhone: false
  });

  const showCalendarButton = formData.inquiryType === 'book_meeting' || formData.inquiryType === 'trial_request';

  const handleCalendarClick = () => {
    window.open("https://calendar.app.google/4pVgu9v6KFRPNJju8", "_blank");
  };

  useEffect(() => {
    console.log('Thank you dialog state changed:', showThankYou);
  }, [showThankYou]);

  useEffect(() => {
    if (onOpenChange) {
      console.log('Form dialog prop onOpenChange received:', onOpenChange);
    }
  }, [onOpenChange]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submission started');
    
    setIsSubmitting(true);
    setError(null);

    try {
      const { error: dbError } = await supabase
        .from('trial_requests')
        .insert({
          full_name: formData.fullName,
          company: formData.company,
          job_title: formData.jobTitle,
          phone: formData.phone,
          email: formData.email,
          address: formData.address,
          inquiry_type: formData.inquiryType,
          industry: formData.industry,
          quantity: formData.quantity,
          problem_description: formData.problemDescription,
          needed_by: formData.neededBy,
          marketing_email: formData.marketingEmail,
          marketing_sms: formData.marketingSms,
          marketing_phone: formData.marketingPhone,
        });

      if (dbError) throw new Error(dbError.message);

      const { error: emailError } = await supabase.functions.invoke('send-inquiry', {
        body: {
          fullName: formData.fullName,
          company: formData.company,
          jobTitle: formData.jobTitle,
          phone: formData.phone,
          email: formData.email,
          address: formData.address,
          inquiryType: formData.inquiryType,
          industry: formData.industry,
          quantity: formData.quantity,
          problemDescription: formData.problemDescription,
          neededBy: formData.neededBy,
          marketingEmail: formData.marketingEmail,
          marketingSms: formData.marketingSms,
          marketingPhone: formData.marketingPhone,
        }
      });

      if (emailError) throw new Error('Failed to send email notification');

      console.log('Form submission successful');
      toast({
        title: "Success",
        description: "Form submitted successfully",
      });

      setFormData({
        fullName: '',
        company: '',
        jobTitle: '',
        phone: '',
        email: '',
        address: '',
        inquiryType: 'information_request',
        marketingEmail: false,
        marketingSms: false,
        marketingPhone: false
      });

      console.log('Before closing form dialog');
      if (onOpenChange) {
        onOpenChange(false);
      }
      console.log('After closing form dialog');

      setTimeout(() => {
        console.log('Inside timeout, setting showThankYou to true');
        setShowThankYou(true);
      }, 300);

    } catch (err) {
      console.error('Form submission error:', err);
      setError(err instanceof Error ? err.message : 'An unexpected error occurred');
      toast({
        variant: "destructive",
        title: "Error",
        description: "There was a problem submitting your request. Please try again.",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="space-y-6">
        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="grid gap-4">
          <div>
            <Label htmlFor="fullName">Full Name *</Label>
            <Input
              id="fullName"
              required
              value={formData.fullName}
              onChange={(e) => setFormData(prev => ({ ...prev, fullName: e.target.value }))}
            />
          </div>

          <div>
            <Label htmlFor="company">Company Name *</Label>
            <Input
              id="company"
              required
              value={formData.company}
              onChange={(e) => setFormData(prev => ({ ...prev, company: e.target.value }))}
            />
          </div>

          <div>
            <Label htmlFor="jobTitle">Job Title *</Label>
            <Input
              id="jobTitle"
              required
              value={formData.jobTitle}
              onChange={(e) => setFormData(prev => ({ ...prev, jobTitle: e.target.value }))}
            />
          </div>

          <div>
            <Label htmlFor="phone">Phone Number *</Label>
            <Input
              id="phone"
              type="tel"
              required
              value={formData.phone}
              onChange={(e) => setFormData(prev => ({ ...prev, phone: e.target.value }))}
            />
          </div>

          <div>
            <Label htmlFor="email">Email *</Label>
            <Input
              id="email"
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
            />
          </div>

          <div>
            <Label htmlFor="address">Address *</Label>
            <Input
              id="address"
              required
              value={formData.address}
              onChange={(e) => setFormData(prev => ({ ...prev, address: e.target.value }))}
            />
          </div>
        </div>

        <div className="space-y-4">
          <Label>What can we help you with? *</Label>
          <RadioGroup
            value={formData.inquiryType}
            onValueChange={(value: InquiryType) => setFormData(prev => ({ ...prev, inquiryType: value }))}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="information_request" id="info" />
              <Label htmlFor="info">Send me information</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="book_meeting" id="meeting" />
              <Label htmlFor="meeting">Book a meeting</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="trial_request" id="trial" />
              <Label htmlFor="trial">Request a trial</Label>
            </div>
          </RadioGroup>

          {showCalendarButton && (
            <div className="mt-4 p-4 bg-accent/20 rounded-lg">
              <p className="text-sm text-muted-foreground mb-3">Don't want to wait? Book a meeting with us right away:</p>
              <Button 
                variant="secondary"
                onClick={handleCalendarClick}
                type="button"
                className="w-full"
              >
                <Calendar className="mr-2 h-4 w-4" />
                Schedule a Meeting Now
              </Button>
            </div>
          )}
        </div>

        {formData.inquiryType === 'trial_request' && (
          <div className="space-y-4">
            <div>
              <Label htmlFor="industry">Industry</Label>
              <select
                id="industry"
                className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                value={formData.industry || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, industry: e.target.value as Industry }))}
              >
                <option value="">Select an industry</option>
                <option value="construction">Construction</option>
                <option value="manufacturing">Manufacturing</option>
                <option value="infrastructure">Infrastructure</option>
                <option value="agriculture">Agriculture</option>
                <option value="events_management">Events Management</option>
                <option value="destination_marketing">Tourism</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div>
              <Label htmlFor="quantity">Number of devices needed</Label>
              <Input
                id="quantity"
                type="number"
                min="1"
                value={formData.quantity || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, quantity: parseInt(e.target.value) || undefined }))}
              />
            </div>

            <div>
              <Label htmlFor="neededBy">When do you need the devices?</Label>
              <Input
                id="neededBy"
                type="date"
                value={formData.neededBy || ''}
                onChange={(e) => setFormData(prev => ({ ...prev, neededBy: e.target.value }))}
              />
            </div>
          </div>
        )}

        {(formData.inquiryType === 'trial_request' || formData.inquiryType === 'book_meeting') && (
          <div>
            <Label htmlFor="problemDescription">Please describe your monitoring needs</Label>
            <Textarea
              id="problemDescription"
              value={formData.problemDescription || ''}
              onChange={(e) => setFormData(prev => ({ ...prev, problemDescription: e.target.value }))}
              className="min-h-[100px]"
            />
          </div>
        )}

        <div className="space-y-4">
          <Label>Marketing Preferences</Label>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="marketingEmail"
                checked={formData.marketingEmail}
                onCheckedChange={(checked) => 
                  setFormData(prev => ({ ...prev, marketingEmail: checked === true }))
                }
              />
              <Label htmlFor="marketingEmail">I agree to receive marketing emails</Label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="marketingSms"
                checked={formData.marketingSms}
                onCheckedChange={(checked) => 
                  setFormData(prev => ({ ...prev, marketingSms: checked === true }))
                }
              />
              <Label htmlFor="marketingSms">I agree to receive marketing SMS messages</Label>
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                id="marketingPhone"
                checked={formData.marketingPhone}
                onCheckedChange={(checked) => 
                  setFormData(prev => ({ ...prev, marketingPhone: checked === true }))
                }
              />
              <Label htmlFor="marketingPhone">I agree to receive marketing phone calls</Label>
            </div>
          </div>
        </div>

        <Button type="submit" disabled={isSubmitting} className="w-full">
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Submitting...
            </>
          ) : (
            'Submit'
          )}
        </Button>
      </form>

      <Dialog open={showThankYou} onOpenChange={setShowThankYou}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Thank You!</DialogTitle>
            <DialogDescription>
              Thank you for your interest! We've received your request and will be in touch soon.
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
}
