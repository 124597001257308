
import { Camera } from "lucide-react";

interface ImageCountDisplayProps {
  scheduleStart: string | number;
  scheduleEnd: string | number;
  intervalMs: number;
  imagesAllowed: number;
}

export const ImageCountDisplay = ({ 
  scheduleStart, 
  scheduleEnd, 
  intervalMs,
  imagesAllowed
}: ImageCountDisplayProps) => {
  const calculateImagesPerDay = () => {
    const start = Number(scheduleStart);
    const end = Number(scheduleEnd);
    
    // Handle cases where end is before start (e.g., 23 to 5)
    const hoursPerDay = end > start ? end - start : (24 - start) + end;
    const msPerDay = hoursPerDay * 60 * 60 * 1000;
    
    return Math.floor(msPerDay / intervalMs);
  };

  const imagesPerDay = calculateImagesPerDay();
  const imagesPerMonth = imagesPerDay * 30;
  const isWithinLimit = imagesPerMonth <= imagesAllowed;

  return (
    <div className="flex items-center gap-2 text-sm text-muted-foreground mt-2">
      <Camera className="h-4 w-4" />
      <span>~{imagesPerDay} images/day</span>
      <span className="text-muted-foreground">·</span>
      <span className={isWithinLimit ? "text-green-500" : "text-red-500"}>
        ~{imagesPerMonth} images/month ({imagesAllowed} allowed)
      </span>
    </div>
  );
};
