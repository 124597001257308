
import React, { useCallback } from 'react';
import { QrReader } from 'react-qr-reader';
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

interface QRScannerProps {
  onScan: (deviceId: string) => void;
  onClose: () => void;
}

export const QRScanner = ({ onScan, onClose }: QRScannerProps) => {
  const handleScan = useCallback((result: any) => {
    if (result) {
      onScan(result?.text);
    }
  }, [onScan]);

  return (
    <div className="relative">
      <Button 
        variant="ghost" 
        size="icon"
        className="absolute top-2 right-2 z-10"
        onClick={onClose}
      >
        <X className="h-4 w-4" />
      </Button>
      <QrReader
        constraints={{ facingMode: 'environment' }}
        onResult={handleScan}
        containerStyle={{ width: '100%' }}
        videoStyle={{ width: '100%' }}
      />
    </div>
  );
};
