export async function generateTimelapse(images: string[], fps: number = 2): Promise<Blob> {
  console.log('🎬 Starting timelapse generation with:', { imageCount: images.length, fps });

  // Validate input
  if (images.length === 0) {
    throw new Error('No images provided');
  }
  if (images.length > 100) {
    throw new Error('Maximum of 100 images allowed for timelapse generation');
  }

  // Create a canvas element
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  // Preload images in batches to prevent memory issues
  const BATCH_SIZE = 10;
  const loadedImages: HTMLImageElement[] = [];
  console.log('🖼️ Starting image preload in batches of', BATCH_SIZE);

  try {
    for (let i = 0; i < images.length; i += BATCH_SIZE) {
      const batch = images.slice(i, i + BATCH_SIZE);
      const batchImages = await Promise.all(
        batch.map(async (url) => {
          const img = await loadImage(url);
          return img;
        })
      );
      loadedImages.push(...batchImages);
      console.log(`📦 Loaded batch ${Math.floor(i / BATCH_SIZE) + 1}/${Math.ceil(images.length / BATCH_SIZE)}`);
    }
  } catch (error) {
    console.error('❌ Error preloading images:', error);
    throw new Error('Failed to load images. Please try again.');
  }

  // Set canvas size with max dimension of 1280px for better performance
  const maxDimension = 1280;
  const firstImage = loadedImages[0];
  let width = firstImage.width;
  let height = firstImage.height;

  if (width > maxDimension || height > maxDimension) {
    const ratio = Math.min(maxDimension / width, maxDimension / height);
    width = Math.round(width * ratio);
    height = Math.round(height * ratio);
  }

  canvas.width = width;
  canvas.height = height;
  console.log('📐 Canvas dimensions set to:', { width, height });

  // Create MediaRecorder with optimized settings for better performance
  const stream = canvas.captureStream(fps);
  const mimeType = getSupportedMimeType();
  console.log('🎥 Using MIME type:', mimeType);

  const mediaRecorder = new MediaRecorder(stream, {
    mimeType,
    videoBitsPerSecond: 5000000 // 5 Mbps for better performance
  });

  console.log('📼 MediaRecorder configured:', {
    mimeType: mediaRecorder.mimeType,
    bitrate: '5 Mbps',
    state: mediaRecorder.state
  });

  const chunks: Blob[] = [];
  let currentFrame = 0;
  let isRecording = true;

  // Add timeout protection
  const timeout = setTimeout(() => {
    if (isRecording) {
      console.error('⏰ Timelapse generation timed out');
      isRecording = false;
      mediaRecorder.stop();
    }
  }, 180000); // 3 minutes timeout

  return new Promise((resolve, reject) => {
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        console.log('💾 Received data chunk:', { size: event.data.size });
        chunks.push(event.data);
      }
    };

    mediaRecorder.onerror = (event) => {
      console.error('❌ MediaRecorder error:', event);
      clearTimeout(timeout);
      isRecording = false;
      reject(new Error('MediaRecorder error occurred'));
    };

    mediaRecorder.onstop = () => {
      clearTimeout(timeout);
      console.log('🎥 MediaRecorder stopped');

      if (chunks.length === 0) {
        reject(new Error('No video data was recorded'));
        return;
      }

      const webmBlob = new Blob(chunks, { type: mimeType });
      console.log('📦 Timelapse generated successfully:', {
        size: webmBlob.size,
        type: webmBlob.type
      });

      if (webmBlob.size === 0) {
        reject(new Error('Generated video has 0 bytes'));
        return;
      }

      // Clean up
      loadedImages.length = 0;
      chunks.length = 0;

      resolve(webmBlob);
    };

    const processNextFrame = () => {
      if (!isRecording || currentFrame >= loadedImages.length) {
        console.log('✅ Finished processing frames');
        mediaRecorder.stop();
        return;
      }

      try {
        console.log(`📸 Processing frame ${currentFrame + 1}/${loadedImages.length}`);
        const img = loadedImages[currentFrame];

        // Clear previous frame
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Draw new frame with proper scaling
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        currentFrame++;

        // Use setTimeout for more consistent frame timing
        setTimeout(processNextFrame, 1000 / fps);
      } catch (error) {
        console.error(`❌ Error processing frame ${currentFrame + 1}:`, error);
        isRecording = false;
        mediaRecorder.stop();
        reject(error);
      }
    };

    // Start recording and process frames
    console.log('▶️ Starting recording');
    mediaRecorder.start();
    processNextFrame();
  });
}

function getSupportedMimeType(): string {
  const types = [
    'video/webm;codecs=vp9',
    'video/webm;codecs=vp8',
    'video/webm;codecs=h264',
    'video/webm'
  ];
  
  for (const type of types) {
    if (MediaRecorder.isTypeSupported(type)) {
      return type;
    }
  }
  
  throw new Error('No supported video MIME type found');
}

function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';  // Enable CORS
    img.onload = () => resolve(img);
    img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
    img.src = url;
  });
}
