
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { DeviceTile } from "./DeviceTile";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { DeviceDetails } from "./DeviceDetails";
import { useState, useEffect } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

export interface DeviceAssignment {
  id: string;
  device_id: string;
  user_id: string;
}

export const DeviceGrid = () => {
  const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: assignments, isLoading, error, refetch } = useQuery({
    queryKey: ["device-assignments"],
    queryFn: async () => {
      const { data: session } = await supabase.auth.getSession();
      
      if (!session?.session) {
        navigate("/");
        throw new Error("No session");
      }

      // Updated query to only fetch assignments for the current user
      const { data, error } = await supabase
        .from("device_assignments")
        .select("*")
        .eq('user_id', session.session.user.id); // Filter by current user's ID
      
      if (error) {
        console.error("Error fetching assignments:", error);
        throw error;
      }
      return data as DeviceAssignment[];
    },
    staleTime: Infinity,
    retry: 1,
  });

  // Effect to trigger refetch and refresh device data when component mounts
  useEffect(() => {
    const loadData = async () => {
      // First refetch assignments
      await refetch();
      
      // Then ensure each device's data is refreshed
      if (assignments) {
        assignments.forEach((assignment) => {
          queryClient.invalidateQueries({ queryKey: ["device-details", assignment.device_id] });
          queryClient.invalidateQueries({ queryKey: ["device", assignment.device_id] });
        });
      }
    };

    loadData();
  }, [assignments, queryClient, refetch]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ["device-assignments"] });
    assignments?.forEach((assignment) => {
      queryClient.invalidateQueries({ queryKey: ["device-details", assignment.device_id] });
      queryClient.invalidateQueries({ queryKey: ["device", assignment.device_id] });
    });
    toast.success("Refreshing device data...");
  };

  if (error) {
    return (
      <div className="text-sm text-red-500">
        Error loading devices. Please try refreshing the page.
      </div>
    );
  }

  if (isLoading) {
    return <div className="text-sm">Loading devices...</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Your Devices</h2>
        <Button
          onClick={handleRefresh}
          variant="outline"
          size="sm"
          className="gap-2 text-xs"
        >
          <RefreshCw className="h-3 w-3" />
          Refresh
        </Button>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {assignments?.map((assignment) => (
          <Sheet key={assignment.id}>
            <SheetTrigger asChild>
              <div onClick={() => setSelectedDevice(assignment.device_id)}>
                <DeviceTile deviceId={assignment.device_id} />
              </div>
            </SheetTrigger>
            <SheetContent side="right" className="w-full sm:max-w-xl">
              <ScrollArea className="h-full">
                {selectedDevice === assignment.device_id && (
                  <DeviceDetails deviceId={assignment.device_id} />
                )}
              </ScrollArea>
            </SheetContent>
          </Sheet>
        ))}
      </div>
    </div>
  );
};

