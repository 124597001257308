
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Camera, Shield, Clock, Brain, Sun, AlertTriangle, Eye, CheckCircle2, Download } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
const Features = () => {
  const navigate = useNavigate();
  return <div className="min-h-screen bg-gradient-to-b from-white via-brand-50 to-brand-100">
      {/* Navigation */}
      <div className="px-6 py-4">
        <Button variant="ghost" className="flex items-center gap-2 hover:bg-brand-100/50" onClick={() => navigate("/")}>
          <ArrowLeft className="h-4 w-4" />
          Back to Home
        </Button>
      </div>

      {/* Header */}
      <div className="relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-brand-100/50 to-transparent" />
        <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-16 pb-24 relative">
          <div className="mx-auto max-w-2xl text-center animate-fade-in">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-500 sm:text-4xl py-[5px]">Discover a smarter way to oversee your
operations, saving you time and money</h1>
            
          </div>
        </div>
      </div>

      {/* Workflow Steps */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 -mt-8 pb-24 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[{
          image: "/lovable-uploads/6f49f005-fd73-47e8-8f94-2e77e91779e5.png",
          title: "1. Install the Camera",
          description: "Install the Camera at your chosen location. Press the Wake-Up button and walk away. Your done."
        }, {
          image: "/lovable-uploads/75a406b7-99e3-4727-a55e-9f8582cada06.png",
          title: "2. Camera Schedule",
          description: "The camera will wake-up on a set schedule, and upload photos wirelessly via the 4G network."
        }, {
          image: "/lovable-uploads/2d9e7b2b-9158-4abd-9145-8de2fb9b1ece.png",
          title: "3. Image Analysis",
          description: "Images are Analysed using your chosen questions, before being watermarked and stored online."
        }, {
          image: "/lovable-uploads/2ffeae3c-b68c-4360-be7a-4e90720cd66b.png",
          title: "4. Get Notified",
          description: "Setup and receive notifications for safety events. Look back in time to generate repots."
        }].map((step, index) => <div key={index} className="group hover:scale-105 transition-transform duration-300">
              <div className="space-y-4 bg-white/80 backdrop-blur-sm rounded-xl shadow-sm hover:shadow-md transition-shadow p-6">
                <div className="aspect-video rounded-lg overflow-hidden">
                  <img src={step.image} alt={step.title} className="w-full h-full object-contain bg-white rounded-lg group-hover:scale-105 transition-transform duration-300" loading="lazy" width={320} height={180} decoding="async" />
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-900">{step.title}</h3>
                  <p className="mt-2 text-gray-600">{step.description}</p>
                </div>
              </div>
            </div>)}
        </div>
      </div>

      {/* Main Features */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <Card className="bg-white/90 backdrop-blur-sm transform hover:scale-105 transition-all duration-300">
            <CardHeader>
              <CardTitle className="flex items-center gap-3">
                <Sun className="h-6 w-6 text-brand-600" />
                Off-Grid Autonomy
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Solar panels and 4G connectivity allow year-round operation in remote areas, even with limited sunlight. No need for an expensive setup, installation is plug-and-play no matter if you are indoors or outside.
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white/90 backdrop-blur-sm transform hover:scale-105 transition-all duration-300">
            <CardHeader>
              <CardTitle className="flex items-center gap-3">
                <Brain className="h-6 w-6 text-brand-600" />
                Intelligent Monitoring
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Set any question and use AI to detect and inform you of unsafe activity, be it blocked fire doors, missing guards, fallen barrier or liquid on the floor. You set the question, then focus your team's efforts on critical tasks.
              </p>
            </CardContent>
          </Card>

          <Card className="bg-white/90 backdrop-blur-sm transform hover:scale-105 transition-all duration-300">
            <CardHeader>
              <CardTitle className="flex items-center gap-3">
                <Clock className="h-6 w-6 text-brand-600" />
                Customised Image Capture
              </CardTitle>
            </CardHeader>
            <CardContent>
              <p className="text-gray-600">
                Schedule your cameras to take one picture per day for routine updates, or ramp up to one picture per hour for detailed oversight of fast-changing situations. Time-stamped images document progress and maintain records.
              </p>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Benefits */}
      <div className="bg-gradient-to-b from-white to-brand-50 py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center mb-12 bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-500">
            Your Benefits
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <Card className="bg-white/90 backdrop-blur-sm transform hover:scale-105 transition-all duration-300">
              <CardHeader>
                <CardTitle className="flex items-center gap-3">
                  <Shield className="h-6 w-6 text-brand-600" />
                  Robust Documentation
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600">
                  Visual record of each location for accident investigation and training, ensuring comprehensive coverage and accountability.
                </p>
              </CardContent>
            </Card>

            <Card className="bg-white/90 backdrop-blur-sm transform hover:scale-105 transition-all duration-300">
              <CardHeader>
                <CardTitle className="flex items-center gap-3">
                  <CheckCircle2 className="h-6 w-6 text-brand-600" />
                  Optimise Resource Allocation
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600">
                  Deploy staff more strategically by focusing human resources where they're needed most, improving efficiency and effectiveness.
                </p>
              </CardContent>
            </Card>
          </div>

          {/* New Image Section */}
          <div className="mt-16 flex flex-col items-center gap-8">
            <div className="relative max-w-3xl w-full rounded-2xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img src="/lovable-uploads/b72587cf-e3f4-44e6-b7de-b7a5bf2d98ed.png" alt="Snapshot Live camera mounted outdoors" className="w-full h-auto rounded-2xl" width={1200} height={800} loading="eager" decoding="async" fetchPriority="high" />
              <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent pointer-events-none" />
            </div>
            <Button size="lg" className="bg-brand-600 text-white hover:bg-brand-700 transition-colors duration-300 gap-2" onClick={() => window.open("https://ik.imagekit.io/SavySnap/Marketing/SSL%20Data%20Sheet%20V1.pdf", "_blank")}>
              <Download className="w-5 h-5" />
              Download Data Sheet
            </Button>
          </div>
        </div>
      </div>

      {/* Unique Features */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-24">
        <h2 className="text-3xl font-bold text-center mb-16 bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-500">
          What Makes Us Unique
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[{
          icon: AlertTriangle,
          title: "Peace of Mind",
          description: "Know you will get told automatically if something happens"
        }, {
          icon: Eye,
          title: "Seeing is Believing",
          description: "Instant visual confirmation to verify response"
        }, {
          icon: Camera,
          title: "Spot Trends",
          description: "Time lapse image library for each location"
        }].map((feature, index) => <div key={index} className="group bg-white/80 backdrop-blur-sm rounded-xl p-8 text-center transform hover:scale-105 transition-all duration-300 shadow-sm hover:shadow-md">
              <feature.icon className="h-12 w-12 text-brand-600 mx-auto mb-6 group-hover:scale-110 transition-transform duration-300" />
              <h3 className="font-semibold text-xl mb-3">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>)}
        </div>
      </div>

      {/* Bottom CTA */}
      <div className="relative overflow-hidden bg-gradient-to-r from-brand-600 to-brand-500 py-24">
        <div className="absolute inset-0 bg-grid-white/[0.1] bg-[size:16px]" />
        <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center relative z-10">
          <h2 className="text-3xl font-bold mb-6 text-white">Focus Where It Matters</h2>
          <p className="text-lg text-white/90 max-w-2xl mx-auto mb-8">
            Health and Safety too often depends on manual routines, scattered paperwork and trusting individuals to spot every potential hazard. It can feel overwhelming, with the smallest oversight leading to severe consequences.
          </p>
          <Button size="lg" onClick={() => navigate("/applications")} className="bg-white text-brand-600 hover:bg-brand-50 transition-colors duration-300">
            See Our Applications
          </Button>
        </div>
      </div>
    </div>;
};
export default Features;
