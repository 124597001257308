import { Link, useLocation, useNavigate } from "react-router-dom";
import { Home, Map as MapIcon, GalleryHorizontal, Shield, User, LogOut, Images, Server, Database, BookOpen } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Sidebar, SidebarContent, SidebarGroup, SidebarGroupContent, SidebarGroupLabel, SidebarHeader, SidebarMenu, SidebarMenuItem, SidebarMenuButton, SidebarFooter } from "@/components/ui/sidebar";
import { useQuery } from "@tanstack/react-query";
import { useIsMobile } from "@/hooks/use-mobile";

interface DeviceAssignment {
  device_id: string;
  device_details: {
    device_name: string | null;
    device_description: string | null;
  } | null;
}

export function AppSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
      toast.success("Logged out successfully");
    } catch (error) {
      toast.error("Error logging out");
      console.error("Error:", error);
    }
  };

  const {
    data: userRole
  } = useQuery({
    queryKey: ["user-role"],
    queryFn: async () => {
      const {
        data: {
          session
        }
      } = await supabase.auth.getSession();
      if (!session) return null;
      const {
        data: role
      } = await supabase.rpc('get_user_role', {
        user_id: session.user.id
      });
      return role;
    }
  });

  const {
    data: devices
  } = useQuery({
    queryKey: ["device-assignments-with-names"],
    queryFn: async () => {
      const {
        data: {
          session
        }
      } = await supabase.auth.getSession();
      if (!session) {
        navigate("/");
        throw new Error("No session");
      }
      const {
        data,
        error
      } = await supabase.from('device_assignments').select(`
          device_id,
          device_details:device_details!device_assignments_device_id_fkey(
            device_name,
            device_description
          )
        `).eq('user_id', session.user.id);
      if (error) {
        console.error("Error fetching device assignments:", error);
        throw error;
      }
      return data as DeviceAssignment[];
    }
  });

  return <Sidebar>
      <SidebarHeader className="border-b border-border p-4">
        <h2 className="text-sm font-semibold">Snapshot Live Web Portal</h2>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel>Navigation</SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton asChild className={location.pathname === '/dashboard' ? 'font-bold' : ''}>
                  <Link to="/dashboard" className="flex items-center gap-2 text-sm">
                    <Home className="h-4 w-4" />
                    <span>Home</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild className={location.pathname === '/dashboard/map' ? 'font-bold' : ''}>
                  <Link to="/dashboard/map" className="flex items-center gap-2 text-sm">
                    <MapIcon className="h-4 w-4" />
                    <span>Map</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton asChild className={location.pathname === '/dashboard/gallery' ? 'font-bold' : ''}>
                  <Link to="/dashboard/gallery" className="flex items-center gap-2 text-sm">
                    <GalleryHorizontal className="h-4 w-4" />
                    <span>Gallery</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {userRole === 'admin' && <SidebarMenuItem>
                  <SidebarMenuButton asChild className={location.pathname === '/dashboard/admin' ? 'font-bold' : ''}>
                    <Link to="/dashboard/admin" className="flex items-center gap-2 text-sm">
                      <Shield className="h-4 w-4" />
                      <span>Admin</span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>}
              {userRole === 'superuser' && <>
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild className={location.pathname === '/dashboard/user-roles' ? 'font-bold' : ''}>
                      <Link to="/dashboard/user-roles" className="flex items-center gap-2 text-sm">
                        <User className="h-4 w-4" />
                        <span>User Roles</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild className={location.pathname === '/dashboard/device-details' ? 'font-bold' : ''}>
                      <Link to="/dashboard/device-details" className="flex items-center gap-2 text-sm">
                        <Database className="h-4 w-4" />
                        <span>Device Details</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                  <SidebarMenuItem>
                    <SidebarMenuButton asChild className={location.pathname === '/dashboard/device-assignments' ? 'font-bold' : ''}>
                      <Link to="/dashboard/device-assignments" className="flex items-center gap-2 text-sm">
                        <Server className="h-4 w-4" />
                        <span>Device Assignments</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                </>}
              <SidebarMenuItem>
                <SidebarMenuButton asChild className={location.pathname === '/dashboard/profile' ? 'font-bold' : ''}>
                  <Link to="/dashboard/profile" className="flex items-center gap-2 text-sm">
                    <User className="h-4 w-4" />
                    <span>Profile</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>

        {!isMobile && devices && devices.length > 0 && <SidebarGroup>
            <SidebarGroupLabel>Reporting</SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {devices.map(assignment => <SidebarMenuItem key={assignment.device_id}>
                    <SidebarMenuButton asChild className={location.pathname === `/dashboard/device/${assignment.device_id}` ? 'font-bold' : ''}>
                      <Link to={`/dashboard/device/${assignment.device_id}`} className="flex items-center gap-2 text-sm">
                        <Images className="h-4 w-4" />
                        <span>{assignment.device_details?.device_name || assignment.device_id}</span>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>)}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>}
      </SidebarContent>
      <SidebarFooter>
        <div className="flex flex-col gap-2">
          <div className="border-t border-border p-4">
            <Link to="/dashboard/documentation" className="flex w-full items-center gap-2 rounded-md px-2 py-1.5 text-sm text-muted-foreground hover:bg-accent hover:text-accent-foreground">
              <BookOpen className="h-4 w-4" />
              <span>Documentation</span>
            </Link>
          </div>
          <div className="border-t border-border p-4">
            <button onClick={handleLogout} className="flex w-full items-center gap-2 rounded-md px-2 py-1.5 text-sm text-red-500 hover:text-red-600 hover:bg-red-50">
              <LogOut className="h-4 w-4" />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </SidebarFooter>
    </Sidebar>;
}
