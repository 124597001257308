import { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { formatDistanceToNow } from "date-fns";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { Battery, Signal } from "lucide-react";

export const DeviceOverview = ({ device }: { device: any }) => {
  const [localName, setLocalName] = useState(device.deviceName || "");
  const [localDescription, setLocalDescription] = useState(device.deviceDescription || "");
  const [isUpdating, setIsUpdating] = useState(false);
  const queryClient = useQueryClient();

  const updateField = async (field: string, value: string) => {
    setIsUpdating(true);
    try {
      const params = new URLSearchParams();
      params.append("deviceID", device.deviceID);
      params.append("field", field);
      params.append("value", value);

      console.log("Updating device with params:", Object.fromEntries(params));

      const response = await fetch("https://i56o7w.buildship.run/updateDevice", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error("Server error:", errorData);
        throw new Error(errorData.message || "Failed to update device");
      }

      // Invalidate and refetch device details
      queryClient.invalidateQueries({ queryKey: ["device-details", device.deviceID] });
      queryClient.invalidateQueries({ queryKey: ["device", device.deviceID] });
      
      toast.success("Device updated successfully");
    } catch (error) {
      console.error("Error updating device:", error);
      toast.error("Failed to update device");
    } finally {
      setIsUpdating(false);
    }
  };

  // Helper function to get cellular network type
  const getCellularNetworkType = (rat: string) => {
    const types: { [key: string]: string } = {
      "1": "GSM (2G)",
      "2": "GPRS (2G)",
      "3": "EDGE (2.5G)",
      "4": "WCDMA (3G)",
      "5": "HSDPA (3.5G)",
      "6": "LTE (4G)",
      "7": "5G NR",
    };
    return types[rat] || "Unknown";
  };

  // Add date validation helper
  const getFormattedDate = (dateString: string | null | undefined) => {
    if (!dateString) return "Unknown";
    const date = new Date(dateString);
    return !isNaN(date.getTime()) 
      ? formatDistanceToNow(date, { addSuffix: true })
      : "Unknown";
  };

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle className="text-base sm:text-lg">Device Information</CardTitle>
        </CardHeader>
        <CardContent>
          {device.imageKit?.url && (
            <img
              src={device.imageKit.url}
              alt={device.deviceName}
              className="w-full rounded-lg mb-4"
            />
          )}
          <dl className="grid grid-cols-1 gap-4">
            <div className="space-y-2">
              <dt className="font-medium text-xs sm:text-sm">Name</dt>
              <dd className="flex gap-2">
                <Input
                  value={localName}
                  onChange={(e) => setLocalName(e.target.value)}
                  placeholder="Enter device name"
                  className="h-8 sm:h-10 text-xs sm:text-sm"
                  disabled={isUpdating}
                />
                <Button 
                  variant="outline" 
                  onClick={() => updateField("deviceName", localName)}
                  className="h-8 sm:h-10 text-xs sm:text-sm"
                  disabled={isUpdating}
                >
                  Update
                </Button>
              </dd>
            </div>
            <div className="space-y-2">
              <dt className="font-medium text-xs sm:text-sm">Description</dt>
              <dd className="flex gap-2">
                <Textarea
                  value={localDescription}
                  onChange={(e) => setLocalDescription(e.target.value)}
                  placeholder="Enter device description"
                  className="min-h-[80px] sm:min-h-[100px] text-xs sm:text-sm"
                  disabled={isUpdating}
                />
                <Button 
                  variant="outline" 
                  onClick={() => updateField("deviceDescription", localDescription)}
                  className="h-8 sm:h-10 text-xs sm:text-sm"
                  disabled={isUpdating}
                >
                  Update
                </Button>
              </dd>
            </div>

            {/* Cellular Information Card */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <Card className="p-4">
                <div className="flex items-center gap-2 mb-3">
                  <Signal className="h-5 w-5 text-brand-500" />
                  <h3 className="font-medium">Cellular Status</h3>
                </div>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Network Type:</span>
                    <span className="font-medium">
                      {getCellularNetworkType(device.cellular?.cellularRat)}
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Signal Strength:</span>
                    <span className="font-medium">
                      {device.cellular?.cellularStrength}%
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Signal Quality:</span>
                    <span className="font-medium">
                      {device.cellular?.cellularQuality}%
                    </span>
                  </div>
                </div>
              </Card>

              {/* Battery Information Card */}
              <Card className="p-4">
                <div className="flex items-center gap-2 mb-3">
                  <Battery className="h-5 w-5 text-brand-500" />
                  <h3 className="font-medium">Battery Status</h3>
                </div>
                <div className="space-y-2 text-sm">
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Charge Level:</span>
                    <span className="font-medium">
                      {device.battery?.batterySoC}%
                    </span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Voltage:</span>
                    <span className="font-medium">
                      {device.battery?.batteryVoltage}V
                    </span>
                  </div>
                </div>
              </Card>
            </div>

            <div>
              <dt className="font-medium text-xs sm:text-sm">Firmware Version</dt>
              <dd className="text-xs sm:text-sm">{device.deviceFirmware}</dd>
            </div>
            <div>
              <dt className="font-medium text-xs sm:text-sm">Last Updated</dt>
              <dd className="text-xs sm:text-sm">
                {getFormattedDate(device.deviceLastUpdated)}
              </dd>
            </div>
            <div>
              <dt className="font-medium text-xs sm:text-sm">Status</dt>
              <dd className="text-xs sm:text-sm">{device.deviceStatus}</dd>
            </div>
          </dl>
        </CardContent>
      </Card>
    </div>
  );
};
