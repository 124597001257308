import { Card } from "@/components/ui/card";
import { formatDistanceToNow } from "date-fns";
import { useDeviceDetails } from "@/hooks/use-device-details";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";
import { ImageQuotaPieChart } from "./ImageQuotaPieChart";

interface DeviceResponse {
  deviceName: string;
  deviceDescription: string;
  deviceLastUpdated: string;
  notification: {
    result: string;
    message: string;
  };
  imageKit: {
    thumbnailUrl: string;
  };
  location?: {
    map: string;
  };
  currentHdop?: string;
  latWGS84?: number;
  longWGS84?: number;
  contract?: {
    imagesAllowed: number;
    imagesCurrent: number;
    imagesOverflow: number;
  };
}

interface DeviceTileProps {
  deviceId: string;
}

export const DeviceTile = ({ deviceId }: DeviceTileProps) => {
  const { device, isLoading } = useDeviceDetails(deviceId);

  if (isLoading || !device) {
    return (
      <Card className="p-4 cursor-pointer animate-pulse">
        <div className="h-40 bg-muted rounded-md mb-4" />
        <div className="space-y-2">
          <div className="h-4 bg-muted rounded w-3/4" />
          <div className="h-4 bg-muted rounded w-1/2" />
        </div>
      </Card>
    );
  }

  const hasNotification = device.notification?.result === "true";
  const lastUpdatedDate = device.deviceLastUpdated ? new Date(device.deviceLastUpdated) : null;
  const lastUpdatedText = lastUpdatedDate && !isNaN(lastUpdatedDate.getTime())
    ? formatDistanceToNow(lastUpdatedDate, { addSuffix: true })
    : 'Unknown';

  console.log('Device contract data:', device.contract);

  return (
    <Card
      className={`p-4 cursor-pointer transition-colors relative ${
        hasNotification ? "bg-red-50 hover:bg-red-100" : "hover:bg-accent"
      }`}
    >
      <Button
        variant="secondary"
        size="icon"
        className="absolute right-2 top-2 z-20"
        aria-label="Edit device settings"
      >
        <Pencil className="h-4 w-4" />
      </Button>
      {device.imageKit?.thumbnailUrl && (
        <img
          src={device.imageKit.thumbnailUrl}
          alt={device.deviceName}
          className="w-full h-40 object-cover rounded-md mb-4"
          loading="lazy"
          width={320}
          height={160}
        />
      )}
      <div className="space-y-2">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold">{device.deviceName || "Unnamed Device"}</h3>
          <ImageQuotaPieChart 
            imagesAllowed={device.contract?.imagesAllowed || 0} 
            imagesCurrent={device.contract?.imagesCurrent || 0}
          />
        </div>
        <p className="text-sm text-muted-foreground line-clamp-2">
          {device.deviceDescription || "No description"}
        </p>
        <p className="text-xs text-muted-foreground">
          Last updated: {lastUpdatedText}
        </p>
        {hasNotification && (
          <p className="text-sm text-red-600 font-medium">
            {device.notification.message}
          </p>
        )}
      </div>
    </Card>
  );
};
