
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { useState, useEffect } from "react";
import { AspectRatio } from "@/components/ui/aspect-ratio";

interface ImageData {
  url: string;
}

type ImageType = string | ImageData;

export const DevicePastImages = ({ device }: { device: any }) => {
  const [selectedImage, setSelectedImage] = useState<ImageType | null>(null);
  const [imageArray, setImageArray] = useState<ImageType[]>([]);

  useEffect(() => {
    if (device?.imageList) {
      const processedArray = Array.isArray(device.imageList) 
        ? [...device.imageList].reverse() 
        : Object.values(device.imageList).reverse();
      setImageArray(processedArray);
      console.log("Updated image array:", processedArray);
    }
  }, [device]);

  if (!imageArray.length) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="text-base sm:text-lg">Past Images</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-xs sm:text-sm text-muted-foreground">No past images available</p>
        </CardContent>
      </Card>
    );
  }

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle className="text-base sm:text-lg">Past Images</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
            {imageArray.map((image: ImageType, index: number) => (
              <Dialog key={index}>
                <DialogTrigger asChild>
                  <button
                    className="w-full rounded-lg border border-border hover:border-primary transition-colors"
                    onClick={() => setSelectedImage(image)}
                  >
                    <AspectRatio ratio={1}>
                      <img
                        src={typeof image === 'string' ? image : image.url}
                        alt={`Past image ${index + 1}`}
                        className="object-contain w-full h-full hover:scale-105 transition-transform duration-200"
                        loading="lazy"
                        width={320}
                        height={320}
                      />
                    </AspectRatio>
                  </button>
                </DialogTrigger>
                <DialogContent className="max-w-3xl">
                  <div className="aspect-square relative overflow-hidden rounded-lg">
                    <img
                      src={typeof selectedImage === 'string' ? selectedImage : selectedImage?.url}
                      alt="Full size image"
                      className="object-contain w-full h-full"
                      loading="lazy"
                      width={768}
                      height={768}
                    />
                  </div>
                </DialogContent>
              </Dialog>
            ))}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
