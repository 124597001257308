
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Environmental = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Environmental & Sustainability Policy</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">9.1 Our Commitment</h2>
          <p>We are committed to environmental protection and sustainable business practices.</p>

          <h2 className="text-xl font-semibold mt-6">9.2 Environmental Goals</h2>
          <p>Our key objectives:</p>
          <ul>
            <li>Reduce carbon emissions</li>
            <li>Minimize waste</li>
            <li>Promote recycling</li>
            <li>Conserve energy</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">9.3 Implementation</h2>
          <p>We achieve our goals through:</p>
          <ul>
            <li>Energy-efficient operations</li>
            <li>Sustainable procurement</li>
            <li>Waste management programs</li>
            <li>Staff training</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">9.4 Monitoring & Reporting</h2>
          <p>We track our environmental impact:</p>
          <ul>
            <li>Regular environmental audits</li>
            <li>Carbon footprint assessment</li>
            <li>Waste reduction metrics</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">9.5 Contact Information</h2>
          <p>For environmental and sustainability inquiries:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default Environmental;
