
import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Battery, Signal, FileDown, Loader2, LayoutGrid, List, History, Calendar, X, Download, Video } from "lucide-react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import { format, startOfDay, endOfDay } from "date-fns";
import { Button } from "@/components/ui/button";
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import JSZip from "jszip";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { generateTimelapse } from "@/utils/timelapseGenerator";

interface DeviceHistoryItem {
  imageKit: {
    url: string;
    thumbnailUrl: string;
  };
  notification: {
    result: string;
    reason: string;
    subject: string;
  };
  battery: {
    batterySoC: string;
  };
  cellular: {
    cellularStrength: string;
    cellularRat: string;
  };
  deviceLastUpdated: string;
}

interface GridLayoutProps {
  deviceId: string;
  deviceName?: string;
  deviceDescription?: string;
}

type LayoutType = "grid" | "list" | "timeline";

const getCellularNetworkType = (rat: string) => {
  const types: { [key: string]: string } = {
    "GSM": "2G",
    "GPRS": "2G",
    "EDGE": "2.5G",
    "LTE": "4G",
    "LTE-M": "4G",
    "NB-IOT": "3G",
  };
  return types[rat?.toUpperCase()] || rat;
};

export const GridLayout = ({ deviceId, deviceName, deviceDescription }: GridLayoutProps) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [itemLimit, setItemLimit] = useState("25");
  const [layoutType, setLayoutType] = useState<LayoutType>("grid");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [pendingStartDate, setPendingStartDate] = useState<Date>();
  const [pendingEndDate, setPendingEndDate] = useState<Date>();
  const [isGeneratingTimelapse, setIsGeneratingTimelapse] = useState(false);
  const { toast } = useToast();

  const clearDateRange = () => {
    setStartDate(undefined);
    setEndDate(undefined);
    setPendingStartDate(undefined);
    setPendingEndDate(undefined);
  };

  const handleStartDateChange = (date: Date | undefined) => {
    setPendingStartDate(date);
    if (date && pendingEndDate) {
      setStartDate(date);
      setEndDate(pendingEndDate);
    }
  };

  const handleEndDateChange = (date: Date | undefined) => {
    setPendingEndDate(date);
    if (date && pendingStartDate) {
      setStartDate(pendingStartDate);
      setEndDate(date);
    }
  };

  const { data: historyData, refetch } = useQuery({
    queryKey: ["device-history", deviceId, startDate, endDate],
    queryFn: async () => {
      const params = new URLSearchParams();
      params.append("deviceID", deviceId);
      
      if (startDate) {
        params.append("startDate", startOfDay(startDate).toISOString());
      }
      if (endDate) {
        params.append("endDate", endOfDay(endDate).toISOString());
      }

      const response = await fetch("https://i56o7w.buildship.run/deviceHistory", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch device history");
      }

      const data = await response.json();
      return data as DeviceHistoryItem[];
    },
  });

  const { minDate, maxDate } = useMemo(() => {
    if (!historyData || historyData.length === 0) {
      return { minDate: undefined, maxDate: undefined };
    }

    const dates = historyData.map(item => new Date(item.deviceLastUpdated));
    return {
      minDate: new Date(Math.min(...dates.map(d => d.getTime()))),
      maxDate: new Date(Math.max(...dates.map(d => d.getTime()))),
    };
  }, [historyData]);

  const handleCheckboxChange = (itemId: string, checked: boolean) => {
    const newSelected = new Set(selectedItems);
    if (checked) {
      newSelected.add(itemId);
    } else {
      newSelected.delete(itemId);
    }
    setSelectedItems(newSelected);
  };

  const handleSelectAll = () => {
    const allItemIds = filteredAndSortedHistory.map(item => `${item.deviceLastUpdated}`);
    
    if (allItemIds.every(id => selectedItems.has(id))) {
      setSelectedItems(new Set());
      toast({
        description: "All items deselected",
      });
    } else {
      setSelectedItems(new Set(allItemIds));
      toast({
        description: `Selected ${allItemIds.length} items`,
      });
    }
  };

  const downloadSelectedImages = async () => {
    if (selectedItems.size === 0) {
      toast({
        description: "Please select at least one image to download.",
        variant: "destructive",
      });
      return;
    }

    setIsDownloading(true);
    try {
      const zip = new JSZip();
      const selectedImages = filteredAndSortedHistory.filter(item => 
        selectedItems.has(`${item.deviceLastUpdated}`)
      );

      for (const item of selectedImages) {
        try {
          const response = await fetch(item.imageKit.url);
          const blob = await response.blob();
          const fileName = `image_${format(new Date(item.deviceLastUpdated), "yyyyMMdd_HHmmss")}.jpg`;
          zip.file(fileName, blob);
        } catch (error) {
          console.error("Error downloading image:", error);
        }
      }

      const content = await zip.generateAsync({ type: "blob" });
      const downloadUrl = URL.createObjectURL(content);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${deviceName || deviceId}_selected_images.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);

      toast({
        description: `Successfully downloaded ${selectedItems.size} images`,
      });
    } catch (error) {
      console.error("Error creating zip file:", error);
      toast({
        description: "Error downloading images. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsDownloading(false);
    }
  };

  const generateTimelapseVideo = async () => {
    if (selectedItems.size === 0) {
      toast({
        description: "Please select at least one image to create timelapse",
        variant: "destructive",
      });
      return;
    }

    if (selectedItems.size > 100) {
      toast({
        description: "Maximum of 100 images allowed for timelapse generation. Please select fewer images.",
        variant: "destructive",
      });
      return;
    }

    setIsGeneratingTimelapse(true);
    try {
      console.log('🎬 Starting timelapse generation process');
      const selectedImages = filteredAndSortedHistory
        .filter(item => selectedItems.has(`${item.deviceLastUpdated}`))
        .sort((a, b) => new Date(a.deviceLastUpdated).getTime() - new Date(b.deviceLastUpdated).getTime())
        .map(item => item.imageKit.url);

      console.log('📸 Selected images:', { count: selectedImages.length, urls: selectedImages });

      toast({
        description: `Starting timelapse generation with ${selectedImages.length} images. This may take a few minutes...`,
      });

      const videoBlob = await generateTimelapse(selectedImages);
      console.log('✅ Video blob received:', { size: videoBlob.size, type: videoBlob.type });
      
      const url = URL.createObjectURL(videoBlob);
      console.log('🔗 Created URL for video blob:', url);

      const link = document.createElement('a');
      link.href = url;
      link.download = `${deviceName || deviceId}_timelapse.webm`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      console.log('✅ Download initiated');

      toast({
        description: `Successfully generated timelapse from ${selectedItems.size} images`,
      });
    } catch (error) {
      console.error('❌ Error generating timelapse:', error);
      toast({
        description: error instanceof Error ? error.message : "Error generating timelapse. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsGeneratingTimelapse(false);
    }
  };

  const generatePDF = async () => {
    if (!historyData) return;
    setIsGenerating(true);

    try {
      const pdf = new jsPDF("p", "mm", "a4");
      const pageWidth = pdf.internal.pageSize.getWidth();
      const margin = 15;
      const contentWidth = pageWidth - (2 * margin);
      let yOffset = margin;

      try {
        const logoResponse = await fetch("https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg");
        const logoBlob = await logoResponse.blob();
        const logoBase64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(logoBlob);
        });

        const logoWidth = 40;
        const logoHeight = 15;
        pdf.addImage(
          logoBase64 as string,
          "JPEG",
          pageWidth - margin - logoWidth,
          yOffset,
          logoWidth,
          logoHeight
        );
      } catch (error) {
        console.error("Error loading logo:", error);
      }

      const statusColumnWidth = 45;
      const mainContentWidth = contentWidth * 0.75;
      const logoSpace = 60;
      const maxTextWidth = mainContentWidth - logoSpace - 10;

      pdf.setFontSize(24);
      pdf.setTextColor(26, 31, 44);
      const titleText = deviceName || deviceId;
      pdf.text(titleText, margin, yOffset + 10);

      if (deviceDescription) {
        pdf.setFontSize(12);
        pdf.setTextColor(142, 145, 150);
        pdf.text(deviceDescription, margin, yOffset + 20);
        yOffset += 30;
      } else {
        yOffset += 20;
      }

      pdf.setFontSize(10);
      pdf.setTextColor(107, 114, 128);
      if (startDate && endDate) {
        const dateRangeText = `Date Range: ${format(startDate, "dd/MM/yy")} - ${format(endDate, "dd/MM/yy")}`;
        pdf.text(dateRangeText, margin, yOffset);
      } else {
        const itemLimitText = `Showing last ${itemLimit} items`;
        pdf.text(itemLimitText, margin, yOffset);
      }

      pdf.setDrawColor(241, 240, 251);
      pdf.setLineWidth(0.5);
      pdf.line(margin, yOffset + 5, pageWidth - margin, yOffset + 5);
      yOffset += 15;

      for (const item of filteredAndSortedHistory) {
        if (yOffset > 250) {
          pdf.addPage();
          yOffset = margin;
        }

        const cardWidth = contentWidth;
        const cardHeight = 40;
        
        if (item.notification.result === "true") {
          pdf.setFillColor(254, 242, 242);
          pdf.rect(margin, yOffset, cardWidth, cardHeight, "F");
        } else {
          pdf.setFillColor(249, 250, 251);
          pdf.rect(margin, yOffset, cardWidth, cardHeight, "F");
        }

        try {
          const thumbWidth = 40;
          const thumbHeight = 30;
          const imgData = await fetch(item.imageKit.thumbnailUrl);
          const imgBlob = await imgData.blob();
          const base64Image = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(imgBlob);
          });

          pdf.addImage(
            base64Image as string,
            "JPEG",
            margin + 5,
            yOffset + 5,
            thumbWidth,
            thumbHeight
          );

          const textX = margin + thumbWidth + 15;
          const statusX = pageWidth - margin - statusColumnWidth;
          const textWidth = (contentWidth * 0.75) - thumbWidth - 25;

          pdf.setFontSize(12);
          pdf.setTextColor(26, 31, 44);
          const splitSubject = pdf.splitTextToSize(item.notification.subject, textWidth);
          pdf.text(splitSubject, textX, yOffset + 12);

          // Changed font size from 10 to 8 for reason text
          pdf.setFontSize(8);
          pdf.setTextColor(107, 114, 128);
          const reason = item.notification.reason;
          const truncatedReason = reason.length > 200 ? reason.substring(0, 197) + '...' : reason;
          const splitReason = pdf.splitTextToSize(truncatedReason, textWidth);
          // Increased line limit from 5 to 7
          if (splitReason.length > 7) {
            const finalReason = splitReason.slice(0, 7);
            finalReason[6] = finalReason[6].replace(/(.{3})$/, '...');
            pdf.text(finalReason, textX, yOffset + 20);
          } else {
            pdf.text(splitReason, textX, yOffset + 20);
          }

          pdf.setFontSize(9);
          pdf.text(`Battery: ${item.battery.batterySoC}%`, statusX, yOffset + 12);
          
          const networkType = getCellularNetworkType(item.cellular.cellularRat);
          pdf.text(`Signal: ${item.cellular.cellularStrength}%`, statusX, yOffset + 20);
          
          pdf.text(
            format(new Date(item.deviceLastUpdated), "MMM d, yyyy HH:mm"),
            statusX,
            yOffset + 28
          );

          yOffset += cardHeight + 10;
        } catch (error) {
          console.error("Error processing image:", error);
          yOffset += cardHeight + 10;
        }
      }

      pdf.save(`${deviceName || deviceId}-report.pdf`);
    } finally {
      setIsGenerating(false);
    }
  };

  const filteredAndSortedHistory = useMemo(() => {
    if (!historyData) return [];

    const sorted = [...historyData].sort((a, b) => 
      new Date(b.deviceLastUpdated).getTime() - new Date(a.deviceLastUpdated).getTime()
    );

    if (startDate || endDate) {
      return sorted.filter(item => {
        const itemDate = new Date(item.deviceLastUpdated);
        if (startDate && endDate) {
          return itemDate >= startOfDay(startDate) && itemDate <= endOfDay(endDate);
        }
        if (startDate) {
          return itemDate >= startOfDay(startDate);
        }
        if (endDate) {
          return itemDate <= endOfDay(endDate);
        }
        return true;
      });
    }

    return sorted.slice(0, parseInt(itemLimit));
  }, [historyData, startDate, endDate, itemLimit]);

  if (!historyData) {
    return <div>Loading history...</div>;
  }

  const renderGridLayout = () => (
    <div className="grid grid-cols-5 gap-4">
      {filteredAndSortedHistory.map((item, index) => (
        <div
          key={`${item.deviceLastUpdated}-${index}`}
          className={cn(
            "relative p-3 rounded-lg border transition-all hover:shadow-lg group",
            "hover:scale-105 hover:z-10",
            item.notification.result === "true" ? "border-red-500 bg-red-50" : "border-border bg-card"
          )}
        >
          <div className={cn(
            "absolute top-2 right-2 z-10 transition-opacity",
            selectedItems.has(`${item.deviceLastUpdated}`) ? "opacity-100" : "opacity-0 group-hover:opacity-100"
          )}>
            <Checkbox
              checked={selectedItems.has(`${item.deviceLastUpdated}`)}
              onCheckedChange={(checked) => 
                handleCheckboxChange(`${item.deviceLastUpdated}`, checked === true)
              }
            />
          </div>

          <div 
            className="cursor-pointer"
            onClick={() => setSelectedImage(item.imageKit.url)}
          >
            <div className="relative aspect-video rounded-md overflow-hidden">
              <img
                src={item.imageKit.thumbnailUrl}
                alt={item.notification.subject}
                className="object-cover w-full h-full"
              />
            </div>
          </div>

          <div className="mt-2 space-y-1">
            <h3 className="text-sm font-medium line-clamp-1">
              {item.notification.subject}
            </h3>
            <p className="text-xs text-muted-foreground line-clamp-2">
              {item.notification.reason}
            </p>
            
            <div className="flex items-center justify-between text-xs text-muted-foreground mt-2">
              <div className="flex items-center gap-1">
                <Battery className="h-3 w-3" />
                {item.battery.batterySoC}%
              </div>
              <div className="flex items-center gap-1">
                <Signal className="h-3 w-3" />
                {item.cellular.cellularStrength}%
                <span className="text-[10px] font-medium">
                  {getCellularNetworkType(item.cellular.cellularRat)}
                </span>
              </div>
            </div>

            <div className="text-[10px] text-muted-foreground mt-1">
              {format(new Date(item.deviceLastUpdated), "MMM d, yyyy HH:mm")}
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderListLayout = () => (
    <div className="space-y-4">
      {filteredAndSortedHistory.map((item, index) => (
        <div
          key={`${item.deviceLastUpdated}-${index}`}
          className={cn(
            "relative p-4 rounded-lg border transition-all hover:shadow-lg group",
            "hover:scale-[1.01] hover:z-10 flex gap-4",
            item.notification.result === "true" ? "border-red-500 bg-red-50" : "border-border bg-card"
          )}
        >
          <div className={cn(
            "absolute top-4 right-4 z-10 transition-opacity",
            selectedItems.has(`${item.deviceLastUpdated}`) ? "opacity-100" : "opacity-0 group-hover:opacity-100"
          )}>
            <Checkbox
              checked={selectedItems.has(`${item.deviceLastUpdated}`)}
              onCheckedChange={(checked) => 
                handleCheckboxChange(`${item.deviceLastUpdated}`, checked === true)
              }
            />
          </div>

          <div 
            className="cursor-pointer w-48 flex-shrink-0"
            onClick={() => setSelectedImage(item.imageKit.url)}
          >
            <div className="relative aspect-video rounded-md overflow-hidden">
              <img
                src={item.imageKit.thumbnailUrl}
                alt={item.notification.subject}
                className="object-cover w-full h-full"
              />
            </div>
          </div>

          <div className="flex-1 min-w-0">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-base font-medium">
                  {item.notification.subject}
                </h3>
                <p className="text-sm text-muted-foreground mt-1">
                  {item.notification.reason}
                </p>
              </div>
              <div className="text-sm text-muted-foreground">
                {format(new Date(item.deviceLastUpdated), "MMM d, yyyy HH:mm")}
              </div>
            </div>
            
            <div className="flex items-center gap-4 mt-4 text-sm text-muted-foreground">
              <div className="flex items-center gap-1">
                <Battery className="h-4 w-4" />
                {item.battery.batterySoC}%
              </div>
              <div className="flex items-center gap-1">
                <Signal className="h-4 w-4" />
                {item.cellular.cellularStrength}%
                <span className="text-xs font-medium">
                  {getCellularNetworkType(item.cellular.cellularRat)}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderTimelineLayout = () => (
    <div className="relative w-full">
      <div className="max-w-[calc(100vw-16rem)] overflow-x-auto">
        <div className="flex gap-4 min-h-[12rem] py-4 items-end" style={{ minWidth: 'max-content' }}>
          {filteredAndSortedHistory.map((item, index) => {
            const isAlert = item.notification.result === "true";
            return (
              <div
                key={`${item.deviceLastUpdated}-${index}`}
                className={cn(
                  "relative transition-all group",
                  isAlert ? "w-64" : "w-48",
                )}
              >
                <div
                  className={cn(
                    "p-3 rounded-lg border transition-all hover:shadow-lg mb-8",
                    "hover:scale-105 hover:z-10",
                    isAlert ? "border-red-500 bg-red-50" : "border-border bg-card"
                  )}
                >
                  <div className={cn(
                    "absolute top-2 right-2 z-10 transition-opacity",
                    selectedItems.has(`${item.deviceLastUpdated}`) ? "opacity-100" : "opacity-0 group-hover:opacity-100"
                  )}>
                    <Checkbox
                      checked={selectedItems.has(`${item.deviceLastUpdated}`)}
                      onCheckedChange={(checked) => 
                        handleCheckboxChange(`${item.deviceLastUpdated}`, checked === true)
                      }
                    />
                  </div>

                  <div 
                    className="cursor-pointer"
                    onClick={() => setSelectedImage(item.imageKit.url)}
                  >
                    <div className="relative aspect-video rounded-md overflow-hidden">
                      <img
                        src={item.imageKit.thumbnailUrl}
                        alt={item.notification.subject}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </div>

                  <div className="mt-2 space-y-1">
                    <h3 className="text-sm font-medium line-clamp-1">
                      {item.notification.subject}
                    </h3>
                    <p className="text-xs text-muted-foreground line-clamp-2">
                      {item.notification.reason}
                    </p>
                    
                    <div className="flex items-center justify-between text-xs text-muted-foreground mt-2">
                      <div className="flex items-center gap-1">
                        <Battery className="h-3 w-3" />
                        {item.battery.batterySoC}%
                      </div>
                      <div className="flex items-center gap-1">
                        <Signal className="h-3 w-3" />
                        {item.cellular.cellularStrength}%
                        <span className="text-[10px] font-medium">
                          {getCellularNetworkType(item.cellular.cellularRat)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="h-4 border-l border-border mx-auto"></div>
                  <div className="text-[10px] text-muted-foreground mt-1">
                    {format(new Date(item.deviceLastUpdated), "MMM d, HH:mm")}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full space-y-8 animate-fade-in">
      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between">
          <div className="border rounded-md p-1">
            <Button
              variant={layoutType === "grid" ? "default" : "ghost"}
              size="sm"
              onClick={() => setLayoutType("grid")}
              className="gap-2"
            >
              <LayoutGrid className="h-4 w-4" />
              Grid
            </Button>
            <Button
              variant={layoutType === "list" ? "default" : "ghost"}
              size="sm"
              onClick={() => setLayoutType("list")}
              className="gap-2"
            >
              <List className="h-4 w-4" />
              List
            </Button>
            <Button
              variant={layoutType === "timeline" ? "default" : "ghost"}
              size="sm"
              onClick={() => setLayoutType("timeline")}
              className="gap-2"
            >
              <History className="h-4 w-4" />
              Timeline
            </Button>
          </div>

          <div className="border rounded-lg p-4 bg-muted/20">
            <div className="flex flex-col gap-2">
              <Button onClick={generatePDF} variant="outline" className="gap-2" disabled={isGenerating}>
                {isGenerating ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <FileDown className="h-4 w-4" />
                )}
                {isGenerating ? "Generating..." : "Download PDF"}
              </Button>
              <div className="flex gap-2">
                <Button 
                  onClick={handleSelectAll} 
                  variant="outline" 
                  className="gap-2"
                  disabled={filteredAndSortedHistory.length === 0}
                >
                  <Checkbox 
                    checked={
                      filteredAndSortedHistory.length > 0 &&
                      filteredAndSortedHistory.every(item => 
                        selectedItems.has(`${item.deviceLastUpdated}`)
                      )
                    }
                  />
                  Select All
                </Button>
                <Button 
                  onClick={downloadSelectedImages} 
                  variant="outline" 
                  className="gap-2" 
                  disabled={isDownloading || selectedItems.size === 0}
                >
                  {isDownloading ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Download className="h-4 w-4" />
                  )}
                  {isDownloading ? "Downloading..." : `Download Selected (${selectedItems.size})`}
                </Button>
                <Button 
                  onClick={generateTimelapseVideo} 
                  variant="outline" 
                  className="gap-2" 
                  disabled={isGeneratingTimelapse || selectedItems.size === 0}
                >
                  {isGeneratingTimelapse ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <Video className="h-4 w-4" />
                  )}
                  {isGeneratingTimelapse ? "Generating..." : "Create Timelapse"}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-[120px] justify-start text-left font-normal",
                  !pendingStartDate && "text-muted-foreground"
                )}
              >
                <Calendar className="mr-2 h-4 w-4" />
                {pendingStartDate ? format(pendingStartDate, "dd/MM/yy") : <span>Start date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <CalendarComponent
                mode="single"
                selected={pendingStartDate}
                onSelect={handleStartDateChange}
                disabled={(date) =>
                  !minDate ||
                  !maxDate ||
                  date < minDate ||
                  date > maxDate
                }
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-[120px] justify-start text-left font-normal",
                  !pendingEndDate && "text-muted-foreground"
                )}
              >
                <Calendar className="mr-2 h-4 w-4" />
                {pendingEndDate ? format(pendingEndDate, "dd/MM/yy") : <span>End date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <CalendarComponent
                mode="single"
                selected={pendingEndDate}
                onSelect={handleEndDateChange}
                disabled={(date) =>
                  !minDate ||
                  !maxDate ||
                  date < minDate ||
                  date > maxDate ||
                  (pendingStartDate && date < pendingStartDate)
                }
                initialFocus
              />
            </PopoverContent>
          </Popover>
          {(pendingStartDate || pendingEndDate) && (
            <Button
              variant="ghost"
              size="icon"
              onClick={clearDateRange}
              className="h-9 w-9"
            >
              <X className="h-4 w-4" />
              <span className="sr-only">Clear date range</span>
            </Button>
          )}
          <Select 
            value={itemLimit} 
            onValueChange={setItemLimit}
            disabled={!!(startDate && endDate)}
          >
            <SelectTrigger className={cn(
              "w-[120px]",
              (startDate && endDate) && "opacity-50 cursor-not-allowed"
            )}>
              <SelectValue placeholder="Show items" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="10">10 items</SelectItem>
              <SelectItem value="25">25 items</SelectItem>
              <SelectItem value="50">50 items</SelectItem>
              <SelectItem value="100">100 items</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      {layoutType === "grid" && renderGridLayout()}
      {layoutType === "list" && renderListLayout()}
      {layoutType === "timeline" && renderTimelineLayout()}

      <Dialog open={!!selectedImage} onOpenChange={() => setSelectedImage(null)}>
        <DialogContent className="max-w-4xl">
          {selectedImage && (
            <div className="relative aspect-video rounded-lg overflow-hidden">
              <img
                src={selectedImage}
                alt="Full size"
                className="object-contain w-full h-full"
              />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
