import { useEffect, useState } from "react";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Button } from "@/components/ui/button";
import { Maximize2, RefreshCw, Camera, Minimize2 } from "lucide-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppSidebar } from "@/components/AppSidebar";
import { SidebarProvider } from "@/components/ui/sidebar";

interface ImageKit {
  url: string;
  fileId: string;
  name: string;
}

interface DeviceImage {
  device_id: string;
  url?: string;
  device_name?: string;
  description?: string;
}

const isImageKit = (value: unknown): value is ImageKit => {
  if (!value || typeof value !== 'object') return false;
  const obj = value as Record<string, unknown>;
  return (
    typeof obj.url === 'string' &&
    typeof obj.fileId === 'string' &&
    typeof obj.name === 'string'
  );
};

const GalleryContent = ({ standalone = false }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: deviceImages = [], isLoading } = useQuery({
    queryKey: ["device-images"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        window.location.href = '/';
        throw new Error("Not authenticated");
      }

      const { data: assignments, error: assignmentsError } = await supabase
        .from('device_assignments')
        .select(`
          device_id,
          device_details:device_details!device_assignments_device_id_fkey(
            device_name,
            device_description,
            imagekit
          )
        `)
        .eq('user_id', session.user.id);

      if (assignmentsError) throw assignmentsError;

      const images: DeviceImage[] = assignments
        .filter(assignment => assignment.device_details?.imagekit)
        .map(assignment => {
          const imagekitData = assignment.device_details.imagekit;
          const imagekit = isImageKit(imagekitData) ? imagekitData : null;
          return {
            device_id: assignment.device_id,
            url: imagekit?.url,
            device_name: assignment.device_details.device_name,
            description: assignment.device_details.device_description
          };
        });

      return images;
    },
    refetchInterval: 5 * 60 * 1000,
  });

  useEffect(() => {
    if (!deviceImages.length) return;

    const interval = setInterval(() => {
      setCurrentImageIndex((current) => 
        current === deviceImages.length - 1 ? 0 : current + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [deviceImages]);

  useEffect(() => {
    if (standalone) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        if (!session) {
          window.location.href = '/';
        }
      });
    }
  }, [standalone]);

  const handleRefresh = () => {
    toast.promise(
      queryClient.invalidateQueries({ queryKey: ["device-images"] }),
      {
        loading: "Refreshing gallery...",
        success: "Gallery refreshed",
        error: "Failed to refresh gallery"
      }
    );
  };

  const toggleFullscreen = async () => {
    try {
      if (!isFullscreen) {
        const element = document.documentElement;
        if (element.requestFullscreen) {
          await element.requestFullscreen();
        } else if ((element as any).webkitRequestFullscreen) {
          await (element as any).webkitRequestFullscreen();
        } else if ((element as any).msRequestFullscreen) {
          await (element as any).msRequestFullscreen();
        }
        setIsFullscreen(true);
      } else {
        if (document.exitFullscreen) {
          await document.exitFullscreen();
        } else if ((document as any).webkitExitFullscreen) {
          await (document as any).webkitExitFullscreen();
        } else if ((document as any).msExitFullscreen) {
          await (document as any).msExitFullscreen();
        }
        setIsFullscreen(false);
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
      toast.error('Failed to toggle fullscreen mode');
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  return (
    <div className="flex-1">
      <div className="flex items-center justify-between p-4 border-b">
        <h1 className="text-2xl font-bold">Gallery</h1>
        <div className="flex flex-col items-end gap-2">
          <img
            src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg"
            alt="Snapshot Live Logo"
            className="h-12 object-contain"
          />
          <div className="flex gap-2">
            <Button
              onClick={handleRefresh}
              variant="outline"
              size="sm"
              className="gap-2 text-xs"
            >
              <RefreshCw className="h-3 w-3" />
              Refresh
            </Button>
            {!standalone && (
              <Button
                variant="ghost"
                size="icon"
                onClick={toggleFullscreen}
                className="hover:bg-accent"
              >
                {isFullscreen ? (
                  <Minimize2 className="h-4 w-4" />
                ) : (
                  <Maximize2 className="h-4 w-4" />
                )}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="p-4">
        {isLoading && (
          <div className="text-sm text-muted-foreground">Loading images...</div>
        )}

        {!isLoading && !deviceImages.length && (
          <Card className="p-6">
            <div className="flex flex-col items-center gap-2 text-center">
              <Camera className="h-8 w-8 text-muted-foreground" />
              <p className="text-muted-foreground">No images available</p>
            </div>
          </Card>
        )}

        {!isLoading && deviceImages.length > 0 && (
          <div className="space-y-6">
            <Card className="overflow-hidden">
              <AspectRatio ratio={16/9}>
                {deviceImages[currentImageIndex]?.url ? (
                  <img
                    src={deviceImages[currentImageIndex].url}
                    alt={`Image from ${deviceImages[currentImageIndex].device_name || deviceImages[currentImageIndex].device_id}`}
                    className="w-full h-full object-contain bg-background"
                  />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-background">
                    <p className="text-muted-foreground">No image available</p>
                  </div>
                )}
              </AspectRatio>
              <div className="p-4">
                <p className="font-medium">
                  {deviceImages[currentImageIndex]?.device_name || deviceImages[currentImageIndex]?.device_id}
                </p>
                {deviceImages[currentImageIndex]?.description && (
                  <p className="text-sm text-muted-foreground">
                    {deviceImages[currentImageIndex].description}
                  </p>
                )}
              </div>
            </Card>

            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {deviceImages.map((image, index) => (
                <button
                  key={`${image.device_id}-${index}`}
                  onClick={() => setCurrentImageIndex(index)}
                  className={`relative rounded-lg overflow-hidden border-2 transition-colors ${
                    index === currentImageIndex ? 'border-primary' : 'border-transparent'
                  }`}
                >
                  <AspectRatio ratio={1}>
                    {image.url ? (
                      <img
                        src={image.url}
                        alt={`Thumbnail ${image.device_name || image.device_id}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center bg-muted">
                        <Camera className="h-4 w-4 text-muted-foreground" />
                      </div>
                    )}
                  </AspectRatio>
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Gallery = () => {
  const [searchParams] = useSearchParams();
  const standalone = searchParams.get('standalone') === 'true';
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  if (standalone) {
    return <GalleryContent standalone={true} />;
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full">
        {!isFullscreen && <AppSidebar />}
        <main className="flex-1">
          <GalleryContent standalone={false} />
        </main>
      </div>
    </SidebarProvider>
  );
};

export default Gallery;
