
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Disclaimer = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Disclaimers</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">5.1 General Disclaimer</h2>
          <p>The information provided on this website is for general information purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind about the completeness, accuracy, reliability, or availability of the information.</p>

          <h2 className="text-xl font-semibold mt-6">5.2 Service Reliability</h2>
          <p>5.2.1 Service Availability:</p>
          <ul>
            <li>We aim to provide continuous service availability</li>
            <li>Service interruptions may occur for maintenance</li>
            <li>We are not liable for any service downtime</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">5.3 Third-Party Content</h2>
          <p>We may link to third-party websites or content:</p>
          <ul>
            <li>We do not endorse third-party content</li>
            <li>We are not responsible for third-party content accuracy</li>
            <li>Users access third-party content at their own risk</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">5.4 Professional Advice</h2>
          <p>Our services do not constitute professional advice. Users should:</p>
          <ul>
            <li>Seek professional advice when needed</li>
            <li>Not rely solely on our information for decisions</li>
            <li>Verify information independently</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">5.5 Contact Information</h2>
          <p>For questions about these disclaimers, contact us at:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
