
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Pencil, Trash2, Search, Activity, Code, Clock } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { useDeviceEdit } from "@/hooks/use-device-edit";

type DeviceWithContract = {
  device_id: string;
  device_name: string | null;
  device_description: string | null;
  device_firmware: string | null;
  device_status: string | null;
  device_last_updated: string | null;
  images_allowed: number;
  images_current: number;
};

export const DeviceDetailsTable = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [editingDevice, setEditingDevice] = useState<DeviceWithContract | null>(null);
  const [resetImageCount, setResetImageCount] = useState(false);
  const { isEditing, updateDeviceDetails } = useDeviceEdit();

  const { data: devices, refetch } = useQuery<DeviceWithContract[]>({
    queryKey: ["device-details"],
    queryFn: async () => {
      console.log("Fetching device details");
      const { data, error } = await supabase
        .from("device_details")
        .select(`
          device_id,
          device_name,
          device_description,
          device_firmware,
          device_status,
          device_last_updated,
          images_allowed,
          images_current
        `);
      
      if (error) {
        console.error("Error fetching device details:", error);
        toast.error("Failed to fetch device details");
        throw error;
      }

      if (!data) {
        return [];
      }

      const typedData = data.map((device: any) => ({
        device_id: device.device_id,
        device_name: device.device_name,
        device_description: device.device_description,
        device_firmware: device.device_firmware,
        device_status: device.device_status,
        device_last_updated: device.device_last_updated,
        images_allowed: Number(device.images_allowed ?? 0),
        images_current: Number(device.images_current ?? 0)
      }));

      console.log("Processed device data:", typedData);
      return typedData;
    },
  });

  const filteredDevices = devices?.filter((device) => {
    const searchLower = searchQuery.toLowerCase();
    return (
      device.device_id.toLowerCase().includes(searchLower) ||
      (device.device_name?.toLowerCase() || "").includes(searchLower) ||
      (device.device_description?.toLowerCase() || "").includes(searchLower) ||
      (device.device_firmware?.toLowerCase() || "").includes(searchLower) ||
      (device.device_status?.toLowerCase() || "").includes(searchLower)
    );
  }) ?? [];

  const handleEdit = async () => {
    if (!editingDevice) return;

    const success = await updateDeviceDetails({
      device_id: editingDevice.device_id,
      device_name: editingDevice.device_name,
      device_description: editingDevice.device_description,
      images_allowed: editingDevice.images_allowed,
      images_current: resetImageCount ? 0 : editingDevice.images_current,
    });

    if (success) {
      setEditingDevice(null);
      setResetImageCount(false);
      refetch();
    }
  };

  const handleDelete = async (deviceId: string) => {
    try {
      const { error } = await supabase
        .from("device_details")
        .delete()
        .eq("device_id", deviceId);

      if (error) throw error;

      toast.success("Device deleted successfully");
      refetch();
    } catch (error) {
      toast.error("Failed to delete device");
      console.error(error);
    } finally {
      setDeleteId(null);
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        <Input
          placeholder="Search devices..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="pl-8"
        />
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Device ID</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Description</TableHead>
              <TableHead className="whitespace-nowrap text-center">
                <div className="flex items-center justify-center gap-2">
                  <Code className="h-4 w-4" />
                  Firmware
                </div>
              </TableHead>
              <TableHead className="text-center">
                <div className="flex items-center justify-center gap-2">
                  <Activity className="h-4 w-4" />
                  Status
                </div>
              </TableHead>
              <TableHead className="text-center">
                <div className="flex items-center justify-center gap-2">
                  <Clock className="h-4 w-4" />
                  Last Updated
                </div>
              </TableHead>
              <TableHead className="text-center">Images Allowed</TableHead>
              <TableHead className="text-center">Images Used</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredDevices?.map((device) => (
              <TableRow key={device.device_id}>
                <TableCell className="font-mono">{device.device_id}</TableCell>
                <TableCell>{device.device_name || "-"}</TableCell>
                <TableCell>{device.device_description || "-"}</TableCell>
                <TableCell className="text-center">{device.device_firmware || "-"}</TableCell>
                <TableCell className="text-center">{device.device_status || "-"}</TableCell>
                <TableCell className="text-center">
                  {device.device_last_updated
                    ? formatDistanceToNow(new Date(device.device_last_updated), {
                        addSuffix: true,
                      })
                    : "-"}
                </TableCell>
                <TableCell className="text-center">{device.images_allowed || 0}</TableCell>
                <TableCell className="text-center">{device.images_current || 0}</TableCell>
                <TableCell className="flex gap-2">
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => setEditingDevice(device)}
                    disabled={isEditing}
                  >
                    <Pencil className="h-4 w-4" />
                  </Button>
                  <AlertDialog open={deleteId === device.device_id} onOpenChange={(open) => !open && setDeleteId(null)}>
                    <Button
                      variant="destructive"
                      size="icon"
                      onClick={() => setDeleteId(device.device_id)}
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        <AlertDialogDescription>
                          This will permanently delete this device. This action cannot be undone.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={() => handleDelete(device.device_id)}>
                          Delete
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog open={!!editingDevice} onOpenChange={(isOpen) => !isOpen && setEditingDevice(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Device Details</DialogTitle>
            <DialogDescription>
              Update the device name, description, image limits and reset image count.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="space-y-2">
              <label htmlFor="name">Name</label>
              <Input
                id="name"
                value={editingDevice?.device_name || ""}
                onChange={(e) =>
                  setEditingDevice(prev =>
                    prev ? { ...prev, device_name: e.target.value } : null
                  )
                }
                disabled={isEditing}
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="description">Description</label>
              <Input
                id="description"
                value={editingDevice?.device_description || ""}
                onChange={(e) =>
                  setEditingDevice(prev =>
                    prev ? { ...prev, device_description: e.target.value } : null
                  )
                }
                disabled={isEditing}
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="images_allowed">Images Allowed</label>
              <Select
                value={editingDevice?.images_allowed?.toString()}
                onValueChange={(value) =>
                  setEditingDevice(prev =>
                    prev ? { ...prev, images_allowed: Number(value) } : null
                  )
                }
                disabled={isEditing}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select image limit" />
                </SelectTrigger>
                <SelectContent>
                  {[30, 90, 180, 360].map((value) => (
                    <SelectItem key={value} value={value.toString()}>
                      {value} images
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <label htmlFor="images_current">Current Images</label>
              <div className="flex items-center justify-between">
                <div className="text-sm text-muted-foreground">
                  Current value: {editingDevice?.images_current || 0}
                </div>
                <div className="flex items-center space-x-2">
                  <Switch
                    id="reset-images"
                    checked={resetImageCount}
                    onCheckedChange={setResetImageCount}
                  />
                  <label htmlFor="reset-images" className="text-sm text-muted-foreground">
                    Reset to zero
                  </label>
                </div>
              </div>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setEditingDevice(null)} disabled={isEditing}>
              Cancel
            </Button>
            <Button onClick={handleEdit} disabled={isEditing}>
              {isEditing ? "Saving..." : "Save changes"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

