import { useState } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Plus, Camera } from "lucide-react";
import { QRScanner } from "./QRScanner";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from "@/components/ui/select";

interface AddDeviceSectionProps {
  onDeviceAdded: () => void;
}

export const AddDeviceSection = ({ onDeviceAdded }: AddDeviceSectionProps) => {
  const [showForm, setShowForm] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [deviceName, setDeviceName] = useState("");
  const [deviceDescription, setDeviceDescription] = useState("");
  const [imagesAllowed, setImagesAllowed] = useState("30");
  const [showScanner, setShowScanner] = useState(false);

  const handleAddDevice = async () => {
    if (!deviceId.trim()) {
      toast.error("Please enter a device ID");
      return;
    }

    try {
      // Check if device already exists
      const { data: existingDevice } = await supabase
        .from('device_details')
        .select('device_id')
        .eq('device_id', deviceId)
        .single();

      if (existingDevice) {
        toast.error("Device ID already exists");
        return;
      }

      // Get the session for API calls
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) {
        throw new Error("No session found");
      }

      // Add new device to Supabase
      const { error } = await supabase
        .from('device_details')
        .insert([{
          device_id: deviceId,
          device_name: deviceName.trim() || null,
          device_description: deviceDescription.trim() || null,
          images_allowed: Number(imagesAllowed),
          images_current: 0,
          last_synced_at: new Date().toISOString()
        }]);

      if (error) throw error;

      // Call updateDevice API with correct field names
      const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL || "https://ndqudoynysmynztrepwj.supabase.co";

      // Update device name if provided
      if (deviceName.trim()) {
        const nameParams = new URLSearchParams();
        nameParams.append('deviceID', deviceId);
        nameParams.append('field', 'deviceName');
        nameParams.append('value', deviceName);

        const nameResponse = await fetch(`${SUPABASE_URL}/functions/v1/updateDevice`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: nameParams,
        });

        if (!nameResponse.ok) {
          throw new Error("Failed to update device name");
        }
      }

      // Update device description if provided
      if (deviceDescription.trim()) {
        const descParams = new URLSearchParams();
        descParams.append('deviceID', deviceId);
        descParams.append('field', 'deviceDescription');
        descParams.append('value', deviceDescription);

        const descResponse = await fetch(`${SUPABASE_URL}/functions/v1/updateDevice`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: descParams,
        });

        if (!descResponse.ok) {
          throw new Error("Failed to update device description");
        }
      }

      toast.success("Device added successfully");
      setDeviceId("");
      setDeviceName("");
      setDeviceDescription("");
      setImagesAllowed("30");
      onDeviceAdded();
      setShowForm(false);
    } catch (error) {
      console.error("Error adding device:", error);
      toast.error("Failed to add device");
    }
  };

  const handleQRScan = (result: string) => {
    setDeviceId(result);
    setShowScanner(false);
    toast.success("QR code scanned successfully");
  };

  return (
    <div>
      {!showForm ? (
        <Button 
          onClick={() => setShowForm(true)}
          className="w-full md:w-auto"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add New Device
        </Button>
      ) : (
        <div className="bg-white rounded-lg shadow p-4 space-y-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Add New Device</h3>
            <Button 
              variant="ghost" 
              onClick={() => setShowForm(false)}
            >
              Cancel
            </Button>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Device ID *</label>
            <div className="flex gap-2">
              <Input
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
                placeholder="Enter device ID"
              />
              <Button onClick={() => setShowScanner(true)}>
                <Camera className="h-4 w-4 mr-2" />
                Scan QR
              </Button>
            </div>
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Device Name</label>
            <Input
              value={deviceName}
              onChange={(e) => setDeviceName(e.target.value)}
              placeholder="Enter device name"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Description</label>
            <Input
              value={deviceDescription}
              onChange={(e) => setDeviceDescription(e.target.value)}
              placeholder="Enter device description"
            />
          </div>

          <div className="space-y-2">
            <label className="text-sm font-medium">Images Allowed</label>
            <Select
              value={imagesAllowed}
              onValueChange={setImagesAllowed}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select image limit" />
              </SelectTrigger>
              <SelectContent>
                {[30, 90, 180, 360].map((value) => (
                  <SelectItem key={value} value={value.toString()}>
                    {value} images
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <p className="text-sm text-muted-foreground">
              Images used will start at 0
            </p>
          </div>

          <Button onClick={handleAddDevice}>Add Device</Button>
          
          {showScanner && (
            <div className="mt-4">
              <QRScanner
                onScan={handleQRScan}
                onClose={() => setShowScanner(false)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
