
import { supabase } from "@/integrations/supabase/client";

// Convert NMEA format (ddmm.mmmm) to decimal degrees
const convertNMEAToDecimal = (nmeaValue: number | null): number | null => {
  if (nmeaValue === null) return null;
  
  // Convert to string to handle decimal places properly
  const str = nmeaValue.toString();
  // Extract degrees and minutes
  const degrees = parseInt(str.slice(0, 2));
  const minutes = parseFloat(str.slice(2));
  
  // Convert to decimal degrees
  const decimalDegrees = degrees + (minutes / 60);
  
  // Validate the result
  if (decimalDegrees < -90 || decimalDegrees > 90) {
    console.warn(`Invalid latitude value after conversion: ${decimalDegrees}`);
    return null;
  }
  
  return decimalDegrees;
};

// Convert NMEA longitude format (dddmm.mmmm) to decimal degrees
const convertNMEALongitudeToDecimal = (nmeaValue: number | null, direction: string | null): number | null => {
  if (nmeaValue === null) return null;
  
  // Convert to string to handle decimal places properly
  const str = nmeaValue.toString();
  // For longitude, first 3 digits are degrees
  const degrees = parseInt(str.slice(0, 3));
  const minutes = parseFloat(str.slice(3));
  
  // Convert to decimal degrees
  let decimalDegrees = degrees + (minutes / 60);
  
  // Apply negative value for West coordinates
  if (direction === 'W') {
    decimalDegrees = -decimalDegrees;
  }
  
  // Validate the result
  if (decimalDegrees < -180 || decimalDegrees > 180) {
    console.warn(`Invalid longitude value after conversion: ${decimalDegrees}`);
    return null;
  }
  
  return decimalDegrees;
};

export const syncDeviceDetails = async (deviceId: string, apiResponse: any) => {
  try {
    console.log("Syncing device details for:", deviceId, "API response:", apiResponse);
    
    // Extract location data from GNSS if available
    const rawLat = apiResponse.gnss?.latitude || null;
    const rawLong = apiResponse.gnss?.longitude || null;
    const eastWest = apiResponse.gnss?.eastWest || null;
    
    // Convert coordinates from NMEA format to decimal degrees
    const lat = convertNMEAToDecimal(rawLat);
    const long = convertNMEALongitudeToDecimal(rawLong, eastWest);
    
    console.log("Raw coordinates:", { rawLat, rawLong, eastWest });
    console.log("Converted coordinates:", { lat, long });

    // Extract contract image data
    const imagesAllowed = apiResponse.contract?.imagesAllowed ?? null;
    const imagesCurrent = apiResponse.contract?.imagesCurrent ?? null;
    const imagesOverflow = apiResponse.contract?.imagesOverflow ?? null;

    console.log("Contract image data:", { imagesAllowed, imagesCurrent, imagesOverflow });

    const {
      deviceName,
      deviceDescription,
      deviceLastUpdated,
      deviceStatus,
      deviceFirmware,
      notification,
      imageKit,
      cellular,
      battery,
      gnss,
      location,
      currentHdop,
    } = apiResponse;

    const { error } = await supabase
      .from('device_details')
      .upsert({
        device_id: deviceId,
        device_name: deviceName,
        device_description: deviceDescription,
        device_last_updated: deviceLastUpdated,
        device_status: deviceStatus,
        device_firmware: deviceFirmware,
        notification,
        imagekit: imageKit,
        cellular,
        battery,
        gnss,
        location,
        current_hdop: currentHdop,
        lat_wgs84: lat,
        long_wgs84: long,
        images_allowed: imagesAllowed,
        images_current: imagesCurrent,
        images_overflow: imagesOverflow,
        last_synced_at: new Date().toISOString()
      }, {
        onConflict: 'device_id'
      });

    if (error) {
      console.error('Error syncing device details:', error);
      throw error;
    }

    console.log('Successfully synced device details for:', deviceId, 'with contract data:', { 
      imagesAllowed, 
      imagesCurrent, 
      imagesOverflow 
    });
  } catch (error) {
    console.error('Error in syncDeviceDetails:', error);
    throw error;
  }
};
