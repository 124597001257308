
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

export const DeviceAssignmentHandler = () => {
  const queryClient = useQueryClient();
  const [hasRun, setHasRun] = useState(false);

  useEffect(() => {
    console.log("🔍 DeviceAssignmentHandler useEffect triggered, hasRun:", hasRun);
    
    // Only run this logic once per component lifecycle
    if (hasRun) {
      console.log("⏭️ Handler has already run, skipping execution");
      return;
    }

    const handleDeviceAssignments = async () => {
      try {
        // 1. Get current user from Supabase auth
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        console.log("👤 Current user:", user?.email || "No user found");
        
        if (userError) {
          console.error("❌ Error getting user:", userError);
          return;
        }
        
        if (!user?.email) {
          console.log("❌ No user email found, exiting early");
          return;
        }

        // Mark as run early to prevent duplicate executions from other renders
        setHasRun(true);

        // 2. Check for pending invitations using user's email
        console.log("🔎 Checking pending invitations for:", user.email);
        
        // Debug: First check all invitations regardless of status
        const { data: allInvitations, error: allInvError } = await supabase
          .from('user_invitations')
          .select('*')
          .eq('email', user.email);
          
        console.log("📧 All invitations found:", allInvitations);
        
        if (allInvError) {
          console.error("❌ Error fetching all invitations:", allInvError);
        }

        // Now check specifically pending invitations
        const { data: pendingInvitations, error: invitationError } = await supabase
          .from('user_invitations')
          .select('*')
          .eq('email', user.email)
          .eq('status', 'pending');

        if (invitationError) {
          console.error("❌ Error fetching pending invitations:", invitationError);
          throw invitationError;
        }
        
        console.log("📨 Pending invitations found:", pendingInvitations);
        
        if (!pendingInvitations || pendingInvitations.length === 0) {
          console.log("ℹ️ No pending invitations to process");
          
          // Check if user already has any organization role
          const { data: existingRoles, error: rolesError } = await supabase
            .from('user_roles')
            .select('*')
            .eq('user_id', user.id);
            
          if (rolesError) {
            console.error("❌ Error checking existing roles:", rolesError);
            throw rolesError;
          }
          
          console.log("👥 Existing user roles:", existingRoles);
          
          // If user has no roles, create a new organization and make them an admin
          if (!existingRoles || existingRoles.length === 0) {
            console.log("🏢 Creating new organization for user");
            
            // Create organization name from user email (e.g., "john's organization" from john@example.com)
            const organizationName = `${user.email.split('@')[0]}'s organization`;
            
            // Create new organization
            const { data: newOrg, error: orgError } = await supabase
              .from('organizations')
              .insert([{ name: organizationName }])
              .select()
              .single();
              
            if (orgError) {
              console.error("❌ Error creating organization:", orgError);
              throw orgError;
            }
            
            console.log("✅ New organization created:", newOrg);
            
            // Create admin role for user in the new organization
            const { error: roleError } = await supabase
              .from('user_roles')
              .insert([{
                user_id: user.id,
                organization_id: newOrg.id,
                role: 'admin'
              }]);
              
            if (roleError) {
              console.error("❌ Error creating admin role:", roleError);
              throw roleError;
            }
            
            console.log("👑 User set as admin of the new organization");
            
            // Invalidate the user-role query to force a refresh of the data
            console.log("🔄 Invalidating user-role query to refresh data");
            queryClient.invalidateQueries({ queryKey: ["user-role"] });
            
            toast.success("Welcome! A new organization has been created for you");
          } else {
            console.log("ℹ️ User already has organization roles, no need to create a new one");
          }
          
          return;
        }

        // Process each invitation
        for (const invitation of pendingInvitations) {
          console.log("📝 Processing invitation:", invitation);
          
          if (!invitation.device_ids || invitation.device_ids.length === 0) {
            console.log("⚠️ No device IDs in invitation, skipping");
            continue;
          }

          try {
            console.log("🗑️ Removing existing assignments for devices:", invitation.device_ids);
            // 3. First, remove any existing assignments for these devices for this user
            const { error: deleteError } = await supabase
              .from('device_assignments')
              .delete()
              .eq('user_id', user.id)
              .in('device_id', invitation.device_ids);

            if (deleteError) {
              console.error("❌ Error deleting existing assignments:", deleteError);
              throw deleteError;
            }

            // Create new device assignments
            console.log("➕ Creating new device assignments");
            const deviceAssignments = invitation.device_ids.map((deviceId: string) => ({
              user_id: user.id,
              device_id: deviceId
            }));

            const { error: assignmentError } = await supabase
              .from('device_assignments')
              .insert(deviceAssignments);

            if (assignmentError) {
              console.error("❌ Error creating assignments:", assignmentError);
              throw assignmentError;
            }

            // Check if user already has a role in this organization
            console.log("🔍 Checking existing role for organization:", invitation.organization_id);
            const { data: existingRole } = await supabase
              .from('user_roles')
              .select()
              .eq('user_id', user.id)
              .eq('organization_id', invitation.organization_id)
              .maybeSingle();

            // Only create user role if one doesn't exist
            if (!existingRole) {
              console.log("👥 Creating user role for organization:", invitation.organization_id);
              const { error: roleError } = await supabase
                .from('user_roles')
                .insert([{
                  user_id: user.id,
                  organization_id: invitation.organization_id,
                  role: 'standard'
                }]);

              if (roleError) {
                console.error("❌ Error creating user role:", roleError);
                throw roleError;
              }
            } else {
              console.log("ℹ️ User already has a role in organization:", invitation.organization_id);
            }

            // 4. Update the invitation status to processed
            console.log("📋 Updating invitation status to processed");
            const { error: updateStatusError } = await supabase
              .from('user_invitations')
              .update({ status: 'processed' })
              .eq('id', invitation.id)
              .eq('email', user.email);

            if (updateStatusError) {
              console.error("❌ Error updating invitation status:", updateStatusError);
              throw updateStatusError;
            }

            console.log("✅ Successfully processed invitation:", invitation.id);
            toast.success("Devices assigned successfully");
          } catch (error) {
            console.error("❌ Error processing invitation:", error);
            toast.error(`Failed to process invitation: ${error.message}`);
          }
        }

      } catch (error) {
        console.error("❌ Error in device assignment handler:", error);
        toast.error("Failed to check for device assignments");
      }
    };

    handleDeviceAssignments();
  }, [queryClient, hasRun]);

  console.log("🎯 DeviceAssignmentHandler rendered");
  return null;
};
