
import { format } from "date-fns";
import { formatInTimeZone, toZonedTime } from "date-fns-tz";

/**
 * Converts local time (in selected timezone) to UTC time
 * @param hour Hour in local timezone (0-23)
 * @param timezone The selected timezone (e.g. 'America/New_York')
 * @returns Hour in UTC (0-23)
 */
export const localToUTC = (hour: number, timezone: string): number => {
  if (hour < 0 || hour > 23) {
    console.error("Invalid hour value:", hour);
    return hour; // Return as is if invalid
  }
  
  try {
    // Create a date object for the current day
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // getMonth() returns 0-11
    const day = now.getDate();
    
    // Create a date string without timezone info
    const dateStr = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}T${String(hour).padStart(2, '0')}:00:00`;
    
    // Parse this date string as being in the specified timezone
    const dateInTimezone = toZonedTime(dateStr, timezone);
    
    // Get UTC hours directly from the zoned date
    const utcHour = dateInTimezone.getUTCHours();
    
    console.log(`localToUTC: Local ${hour}:00 in ${timezone} → UTC ${utcHour}:00`);
    
    return utcHour;
  } catch (error) {
    console.error("Error converting local to UTC:", error);
    return hour; // Return original hour on error
  }
};

/**
 * Converts UTC time to local time (in selected timezone)
 * @param hour Hour in UTC (0-23)
 * @param timezone The selected timezone (e.g. 'America/New_York')
 * @returns Hour in local timezone (0-23)
 */
export const utcToLocal = (hour: number, timezone: string): number => {
  if (hour < 0 || hour > 23) {
    console.error("Invalid hour value:", hour);
    return hour; // Return as is if invalid
  }
  
  try {
    // Create a date in UTC with the specified hour
    const now = new Date();
    const utcDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), hour, 0, 0));
    
    // Convert UTC date to the target timezone
    const localDate = toZonedTime(utcDate, timezone);
    
    // Get the hour in the local timezone
    const localHour = localDate.getHours();
    
    console.log(`utcToLocal: UTC ${hour}:00 → Local ${localHour}:00 in ${timezone}`);
    
    return localHour;
  } catch (error) {
    console.error("Error converting UTC to local:", error);
    return hour; // Return original hour on error
  }
};
