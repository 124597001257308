import { useState } from 'react';
import { useQuery } from "@tanstack/react-query";
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";

interface AddAssignmentSectionProps {
  onAssignmentAdded: () => void;
}

export const AddAssignmentSection = ({ onAssignmentAdded }: AddAssignmentSectionProps) => {
  const [showForm, setShowForm] = useState(false);
  const [newAssignment, setNewAssignment] = useState({
    deviceId: "",
    userId: "",
  });
  const [filters, setFilters] = useState({
    organizationId: "all",
    adminOnly: false,
  });

  const { data: devices } = useQuery({
    queryKey: ["devices"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("device_details")
        .select("device_id, device_name");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("profiles")
        .select("id, username");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: userRoles } = useQuery({
    queryKey: ["userRoles"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("user_roles")
        .select("user_id, role, organization_id");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("organizations")
        .select("id, name");
      
      if (error) throw error;
      return data;
    },
  });

  const filteredUsers = users?.filter(user => {
    const userRole = userRoles?.find(role => role.user_id === user.id);
    
    if (filters.organizationId !== "all") {
      if (!userRole || userRole.organization_id !== filters.organizationId) {
        return false;
      }
    }

    if (filters.adminOnly) {
      if (!userRole || userRole.role !== 'admin') {
        return false;
      }
    }

    return true;
  });

  const handleAddAssignment = async () => {
    if (!newAssignment.deviceId || !newAssignment.userId) {
      toast.error("Please select both a device and a user");
      return;
    }

    try {
      const { error } = await supabase.from("device_assignments").insert([
        {
          device_id: newAssignment.deviceId,
          user_id: newAssignment.userId,
          assigned_at: new Date().toISOString(),
        },
      ]);

      if (error) throw error;

      toast.success("Assignment added successfully");
      setNewAssignment({ deviceId: "", userId: "" });
      setShowForm(false);
      onAssignmentAdded();
    } catch (error) {
      console.error("Error adding assignment:", error);
      toast.error("Failed to add assignment");
    }
  };

  return (
    <div>
      {!showForm ? (
        <Button 
          onClick={() => setShowForm(true)}
          className="w-full md:w-auto"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Assignment
        </Button>
      ) : (
        <div className="bg-card rounded-lg border shadow-sm p-4 space-y-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Add New Assignment</h3>
            <Button 
              variant="ghost" 
              onClick={() => {
                setShowForm(false);
                setFilters({ organizationId: "all", adminOnly: false });
                setNewAssignment({ deviceId: "", userId: "" });
              }}
            >
              Cancel
            </Button>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-end">
              <div className="grid gap-2 w-full max-w-xs">
                <label className="text-sm font-medium">Organization</label>
                <Select
                  value={filters.organizationId}
                  onValueChange={(value) =>
                    setFilters((prev) => ({ ...prev, organizationId: value }))
                  }
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="All organizations" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="all">All organizations</SelectItem>
                    {organizations?.map((org) => (
                      <SelectItem key={org.id} value={org.id}>
                        {org.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <Button
                variant="outline"
                onClick={() => setFilters({ organizationId: "all", adminOnly: false })}
              >
                Clear Filters
              </Button>
            </div>

            <div className="flex items-center space-x-2">
              <Checkbox
                id="addAdminOnly"
                checked={filters.adminOnly}
                onCheckedChange={(checked) =>
                  setFilters((prev) => ({ ...prev, adminOnly: checked === true }))
                }
              />
              <label
                htmlFor="addAdminOnly"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Admins
              </label>
            </div>

            <div className="grid gap-2">
              <label className="text-sm font-medium">Device</label>
              <Select
                value={newAssignment.deviceId}
                onValueChange={(value) =>
                  setNewAssignment((prev) => ({ ...prev, deviceId: value }))
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select device" />
                </SelectTrigger>
                <SelectContent>
                  {devices?.map((device) => (
                    <SelectItem key={device.device_id} value={device.device_id}>
                      {device.device_name || device.device_id}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="grid gap-2">
              <label className="text-sm font-medium">User</label>
              <Select
                value={newAssignment.userId}
                onValueChange={(value) =>
                  setNewAssignment((prev) => ({ ...prev, userId: value }))
                }
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select user" />
                </SelectTrigger>
                <SelectContent>
                  {filteredUsers?.map((user) => (
                    <SelectItem key={user.id} value={user.id}>
                      {user.username || user.id}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <Button onClick={handleAddAssignment} className="w-full">
              Add Assignment
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
