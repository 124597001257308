import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { DeviceOverview } from "./tabs/DeviceOverview";
import { DeviceConfig } from "./tabs/DeviceConfig";
import { DeviceNotifications } from "./tabs/DeviceNotifications";
import { DeviceLocation } from "./tabs/DeviceLocation";
import { DevicePastImages } from "./tabs/DevicePastImages";
import { useIsMobile } from "@/hooks/use-mobile";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { useDeviceDetails } from "@/hooks/use-device-details";

interface DeviceDetailsProps {
  deviceId: string;
}

export const DeviceDetails = ({ deviceId }: DeviceDetailsProps) => {
  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState("overview");
  const { device, isLoading, refreshDevice } = useDeviceDetails(deviceId);

  if (isLoading || !device) {
    return <div>Loading device details...</div>;
  }

  const tabs = [
    { value: "overview", label: "Overview" },
    { value: "config", label: "Config" },
    { value: "notifications", label: "Notifications" },
    { value: "location", label: "Location" },
    { value: "past-images", label: "Past Images" },
  ];

  if (isMobile) {
    return (
      <div className="w-full space-y-4">
        <div className="grid grid-cols-2 gap-2">
          {tabs.map((tab) => (
            <Button
              key={tab.value}
              variant={activeTab === tab.value ? "default" : "outline"}
              className="w-full"
              onClick={() => setActiveTab(tab.value)}
            >
              {tab.label}
            </Button>
          ))}
        </div>

        <div className="mt-4">
          {activeTab === "overview" && <DeviceOverview device={device} />}
          {activeTab === "config" && (
            <DeviceConfig 
              device={device} 
              deviceId={deviceId} 
              onUpdate={refreshDevice}
            />
          )}
          {activeTab === "notifications" && (
            <DeviceNotifications 
              device={device} 
              deviceId={deviceId} 
              onUpdate={refreshDevice}
            />
          )}
          {activeTab === "location" && <DeviceLocation device={device} />}
          {activeTab === "past-images" && <DevicePastImages device={device} />}
        </div>
      </div>
    );
  }

  return (
    <Tabs defaultValue="overview" className="w-full">
      <TabsList className="grid w-full grid-cols-5">
        {tabs.map((tab) => (
          <TabsTrigger key={tab.value} value={tab.value}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      <TabsContent value="overview">
        <DeviceOverview device={device} />
      </TabsContent>
      <TabsContent value="config">
        <DeviceConfig 
          device={device} 
          deviceId={deviceId} 
          onUpdate={refreshDevice}
        />
      </TabsContent>
      <TabsContent value="notifications">
        <DeviceNotifications 
          device={device} 
          deviceId={deviceId} 
          onUpdate={refreshDevice}
        />
      </TabsContent>
      <TabsContent value="location">
        <DeviceLocation device={device} />
      </TabsContent>
      <TabsContent value="past-images">
        <DevicePastImages device={device} />
      </TabsContent>
    </Tabs>
  );
};