
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Website Terms of Use</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">1.1 Introduction</h2>
          <p>Welcome to Snapshot Live Ltd's website (the "Website"). By using our Website, you agree to be bound by these Terms of Use ("Terms"). If you do not accept these Terms, please refrain from using our Website. We reserve the right to modify, alter, or update these Terms at any time without prior notice. Any changes will take effect immediately upon posting on the Website. Your continued use of the Website after any such changes shall be deemed acceptance of those changes.</p>

          <h2 className="text-xl font-semibold mt-6">1.2 Eligibility</h2>
          <p>You must be at least 18 years of age to use this Website. By accessing or using the Website, you confirm that you are at least 18 and capable of entering into legally binding agreements.</p>

          <h2 className="text-xl font-semibold mt-6">1.3 Website Access and Availability</h2>
          <p>1.3.1 We endeavour to keep the Website available at all times, but we do not guarantee uninterrupted access. We reserve the right to suspend, discontinue, or modify the Website or any part of it at our discretion, without notice.</p>
          <p>1.3.2 We shall not be liable for any downtime or interruption in Website availability due to technical or maintenance-related issues.</p>

          <h2 className="text-xl font-semibold mt-6">1.4 Intellectual Property</h2>
          <p>1.4.1 All text, graphics, logos, images, and software on this Website (the "Content") are the property of Snapshot Live Ltd or its licensors and are protected by copyright, trademarks, or other intellectual property laws.</p>
          <p>1.4.2 You may view, download, and print Content from the Website solely for your personal, non-commercial use. You must not otherwise reproduce, distribute, modify, or exploit any Content without our express written permission.</p>

          <h2 className="text-xl font-semibold mt-6">1.5 Prohibited Use</h2>
          <p>1.5.1 You agree not to use the Website:</p>
          <ul>
            <li>For any unlawful purpose, including but not limited to fraud or data misuse.</li>
            <li>To transmit viruses, malicious software, or any harmful code.</li>
            <li>In any way that may compromise the Website's security or interfere with other users' access.</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">1.6 Limitation of Liability</h2>
          <p>1.6.1 While we use reasonable endeavours to ensure that the information on our Website is accurate, we make no warranties or representations about the completeness, reliability, or suitability of the Content.</p>
          <p>1.6.2 To the extent permitted by law, Snapshot Live Ltd and its officers, directors, employees, or agents shall not be liable for any indirect or consequential loss or damage arising out of or in connection with the use of this Website.</p>

          <h2 className="text-xl font-semibold mt-6">1.7 Governing Law and Jurisdiction</h2>
          <p>These Terms are governed by and construed in accordance with the laws of England and Wales. Any disputes arising out of these Terms or your use of the Website shall be subject to the exclusive jurisdiction of the courts of England and Wales.</p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
