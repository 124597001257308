import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { Clock, Globe, Save, Sliders } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Slider } from "@/components/ui/slider";
import { ImageCountDisplay } from "@/components/devices/ImageCountDisplay";
import { localToUTC, utcToLocal } from "@/utils/timezoneUtils";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";

interface DeviceConfigProps {
  device: any;
  deviceId: string;
  onUpdate: () => Promise<void>;
}

const TIMEZONES = [
  { value: "Europe/London", label: "Europe/London - UTC+0 / +1" },
  { value: "Europe/Paris", label: "Europe/Paris - UTC+1 / +2" },
  { value: "Asia/Dubai", label: "Asia/Dubai - UTC+4" },
  { value: "Australia/Perth", label: "Australia/Perth - UTC+8" },
  { value: "Australia/Sydney", label: "Australia/Sydney - UTC+10 / +11" },
  { value: "America/New_York", label: "America/New_York - UTC−5 / −4" },
  { value: "America/Chicago", label: "America/Chicago - UTC−6 / −5" },
  { value: "America/Phoenix", label: "America/Phoenix - UTC−7" },
  { value: "America/Los_Angeles", label: "America/Los_Angeles - UTC−8 / −7" }
];

const mapSliderToLevel = (value: number): number => {
  const mapping: { [key: number]: number } = {
    1: -3,
    2: -2,
    3: -1,
    4: 1,
    5: 2,
    6: 3
  };
  return mapping[value] || 0;
};

const mapLevelToSlider = (level: number): number => {
  const mapping: { [key: number]: number } = {
    [-3]: 1,
    [-2]: 2,
    [-1]: 3,
    [1]: 4,
    [2]: 5,
    [3]: 6
  };
  return mapping[level] || 1;
};

const msToDisplayValue = (ms: number): string => {
  const minutes = ms / 60000;
  if (minutes < 60) {
    return `${minutes} min`;
  }
  return `${minutes / 60} hours`;
};

const sliderToMs = (value: number): number => {
  const mapping: { [key: number]: number } = {
    1: 900000,
    2: 1800000,
    3: 3600000,
    4: 7200000,
    5: 14400000
  };
  return mapping[value] || 3600000;
};

const msToSlider = (ms: number): number => {
  const mapping: { [key: number]: number } = {
    900000: 1,
    1800000: 2,
    3600000: 3,
    7200000: 4,
    14400000: 5
  };
  return mapping[ms] || 3;
};

export const DeviceConfig = ({ device, deviceId, onUpdate }: DeviceConfigProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [pendingUpdate, setPendingUpdate] = useState<{ field: string; value: string | number } | null>(null);
  const [timezone, setTimezone] = useState(device.timezone || "Europe/London");
  
  const [localScheduleStart, setLocalScheduleStart] = useState(0);
  const [localScheduleEnd, setLocalScheduleEnd] = useState(0);
  const [utcScheduleStart, setUtcScheduleStart] = useState(Number(device.cameraConfig.scheduleStart));
  const [utcScheduleEnd, setUtcScheduleEnd] = useState(Number(device.cameraConfig.scheduleEnd));
  
  const [cameraSaturation, setCameraSaturation] = useState(mapLevelToSlider(device.cameraConfig.cameraSaturation));
  const [cameraEV, setCameraEV] = useState(mapLevelToSlider(device.cameraConfig.cameraEV));
  const [cameraContrast, setCameraContrast] = useState(mapLevelToSlider(device.cameraConfig.cameraContrast));
  const [cameraBrightness, setCameraBrightness] = useState(mapLevelToSlider(device.cameraConfig.cameraBrightness));
  const [absoluteExposure, setAbsoluteExposure] = useState(device.cameraConfig.cameraAbsoluteExposure);
  const [isoSensitivity, setIsoSensitivity] = useState(device.cameraConfig.cameraISOSensitivity);
  const [intervalTimer, setIntervalTimer] = useState(msToSlider(device.cameraConfig.withinScheduleSleepTime));

  useEffect(() => {
    console.log("Device config updated or timezone changed:", device.cameraConfig);
    setTimezone(device.timezone || "Europe/London");
    
    const utcStart = Number(device.cameraConfig.scheduleStart);
    const utcEnd = Number(device.cameraConfig.scheduleEnd);
    
    setUtcScheduleStart(utcStart);
    setUtcScheduleEnd(utcEnd);
    
    const localStart = utcToLocal(utcStart, device.timezone || "Europe/London");
    const localEnd = utcToLocal(utcEnd, device.timezone || "Europe/London");
    
    console.log(`Converting UTC to local on initial load: 
      UTC Start: ${utcStart} → Local: ${localStart} in ${device.timezone || "Europe/London"}
      UTC End: ${utcEnd} → Local: ${localEnd} in ${device.timezone || "Europe/London"}`);
    
    setLocalScheduleStart(localStart);
    setLocalScheduleEnd(localEnd);
    
    setCameraSaturation(mapLevelToSlider(device.cameraConfig.cameraSaturation));
    setCameraEV(mapLevelToSlider(device.cameraConfig.cameraEV));
    setCameraContrast(mapLevelToSlider(device.cameraConfig.cameraContrast));
    setCameraBrightness(mapLevelToSlider(device.cameraConfig.cameraBrightness));
    setAbsoluteExposure(device.cameraConfig.cameraAbsoluteExposure);
    setIsoSensitivity(device.cameraConfig.cameraISOSensitivity);
    setIntervalTimer(msToSlider(device.cameraConfig.withinScheduleSleepTime));
  }, [device]);

  const calculateMonthlyImages = () => {
    const start = utcScheduleStart;
    const end = utcScheduleEnd;
    const hoursPerDay = end > start ? end - start : (24 - start) + end;
    const msPerDay = hoursPerDay * 60 * 60 * 1000;
    const imagesPerDay = Math.floor(msPerDay / sliderToMs(intervalTimer));
    return imagesPerDay * 30;
  };

  const handleUpdateField = (field: string, value: string | number) => {
    const monthlyImages = calculateMonthlyImages();
    const imagesAllowed = device.contract?.imagesAllowed || 0;
    
    if (monthlyImages > imagesAllowed) {
      setPendingUpdate({ field, value });
      setShowWarningDialog(true);
    } else {
      updateField(field, value);
    }
  };

  const handleConfirmUpdate = () => {
    if (pendingUpdate) {
      updateField(pendingUpdate.field, pendingUpdate.value);
      setPendingUpdate(null);
    }
    setShowWarningDialog(false);
  };

  const toBool = (value: number | string | undefined | null): boolean => {
    if (typeof value === 'string') {
      return value === '1';
    }
    return value === 1;
  };

  const updateField = async (field: string, value: string | number | boolean) => {
    console.log("Updating field:", field, "with value:", value);
    setIsUpdating(true);
    try {
      const params = new URLSearchParams();
      params.append("deviceID", deviceId);
      params.append("field", field);
      const apiValue = typeof value === 'boolean' ? (value ? 1 : 0) : value;
      params.append("value", apiValue.toString());

      const response = await fetch("https://i56o7w.buildship.run/updateDevice", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      });

      if (!response.ok) {
        throw new Error("Failed to update device");
      }

      await onUpdate();
      toast.success("Device updated successfully");
    } catch (error) {
      console.error("Error updating device:", error);
      toast.error("Failed to update device");
    } finally {
      setIsUpdating(false);
    }
  };

  const handleLocalScheduleStartChange = (value: number) => {
    setLocalScheduleStart(value);
    const newUtcValue = localToUTC(value, timezone);
    setUtcScheduleStart(newUtcValue);
    console.log(`Local start changed: ${value} local → ${newUtcValue} UTC`);
  };

  const handleLocalScheduleEndChange = (value: number) => {
    setLocalScheduleEnd(value);
    const newUtcValue = localToUTC(value, timezone);
    setUtcScheduleEnd(newUtcValue);
    console.log(`Local end changed: ${value} local → ${newUtcValue} UTC`);
  };

  const handleSaveScheduleStart = () => {
    console.log(`Saving schedule start: ${localScheduleStart} local (${timezone}) = ${utcScheduleStart} UTC`);
    updateField("cameraConfig.scheduleStart", utcScheduleStart);
  };

  const handleSaveScheduleEnd = () => {
    console.log(`Saving schedule end: ${localScheduleEnd} local (${timezone}) = ${utcScheduleEnd} UTC`);
    updateField("cameraConfig.scheduleEnd", utcScheduleEnd);
  };

  const handleTimezoneChange = (value: string) => {
    const oldTimezone = timezone;
    setTimezone(value);
    
    const newLocalStart = utcToLocal(utcScheduleStart, value);
    const newLocalEnd = utcToLocal(utcScheduleEnd, value);
    
    console.log(`Timezone change from ${oldTimezone} to ${value}:`);
    console.log(`- Start: ${localScheduleStart} (${oldTimezone}) → ${newLocalStart} (${value})`);
    console.log(`- End: ${localScheduleEnd} (${oldTimezone}) → ${newLocalEnd} (${value})`);
    
    setLocalScheduleStart(newLocalStart);
    setLocalScheduleEnd(newLocalEnd);
    
    updateField("timezone", value);
  };

  return (
    <div className="space-y-6">
      <Card className="border border-border">
        <CardHeader className="pb-3">
          <div className="flex items-center gap-2">
            <Globe className="h-5 w-5 text-primary" />
            <CardTitle className="text-base sm:text-lg">Timezone</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-2">
            <Label htmlFor="timezone" className="text-sm">Device Timezone</Label>
            <div className="flex gap-2">
              <Select
                value={timezone}
                onValueChange={handleTimezoneChange}
                disabled={isUpdating}
              >
                <SelectTrigger id="timezone" className="w-full">
                  <SelectValue placeholder="Select timezone" />
                </SelectTrigger>
                <SelectContent className="bg-popover">
                  {TIMEZONES.map((tz) => (
                    <SelectItem key={tz.value} value={tz.value}>
                      {tz.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="pb-3">
          <div className="flex items-center gap-2">
            <Clock className="h-5 w-5 text-brand-500" />
            <CardTitle className="text-base sm:text-lg">Operating Hours</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="space-y-2">
              <Label htmlFor="scheduleStart" className="text-sm">Start Time (24h, {timezone})</Label>
              <div className="flex gap-2">
                <Input
                  id="scheduleStart"
                  type="number"
                  min="0"
                  max="23"
                  value={localScheduleStart}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    handleLocalScheduleStartChange(val);
                  }}
                  disabled={isUpdating}
                  className="h-9"
                />
                <Button
                  size="sm"
                  onClick={handleSaveScheduleStart}
                  disabled={isUpdating}
                >
                  <Save className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-xs text-muted-foreground">
                UTC: {utcScheduleStart}:00
              </p>
            </div>
            <div className="space-y-2">
              <Label htmlFor="scheduleEnd" className="text-sm">End Time (24h, {timezone})</Label>
              <div className="flex gap-2">
                <Input
                  id="scheduleEnd"
                  type="number"
                  min="0"
                  max="23"
                  value={localScheduleEnd}
                  onChange={(e) => {
                    const val = parseInt(e.target.value) || 0;
                    handleLocalScheduleEndChange(val);
                  }}
                  disabled={isUpdating}
                  className="h-9"
                />
                <Button
                  size="sm"
                  onClick={handleSaveScheduleEnd}
                  disabled={isUpdating}
                >
                  <Save className="h-4 w-4" />
                </Button>
              </div>
              <p className="text-xs text-muted-foreground">
                UTC: {utcScheduleEnd}:00
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="pb-3">
          <div className="flex items-center gap-2">
            <Clock className="h-5 w-5 text-brand-500" />
            <CardTitle className="text-base sm:text-lg">Interval Timer</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="intervalTimer" className="text-sm">
                Capture Interval ({msToDisplayValue(sliderToMs(intervalTimer))})
              </Label>
              <div className="flex gap-4 items-center">
                <Slider
                  id="intervalTimer"
                  min={1}
                  max={5}
                  step={1}
                  value={[intervalTimer]}
                  onValueChange={(value) => setIntervalTimer(value[0])}
                  className="flex-1"
                  disabled={isUpdating}
                />
                <Button
                  size="sm"
                  onClick={() => handleUpdateField("cameraConfig.withinScheduleSleepTime", sliderToMs(intervalTimer))}
                  disabled={isUpdating}
                >
                  <Save className="h-4 w-4" />
                </Button>
              </div>
              <ImageCountDisplay 
                scheduleStart={localToUTC(Number(localScheduleStart), timezone)}
                scheduleEnd={localToUTC(Number(localScheduleEnd), timezone)}
                intervalMs={sliderToMs(intervalTimer)}
                imagesAllowed={device.contract?.imagesAllowed || 0}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="pb-3">
          <div className="flex items-center gap-2">
            <Sliders className="h-5 w-5 text-brand-500" />
            <CardTitle className="text-base sm:text-lg">Camera Settings</CardTitle>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <Label className="text-sm">Auto Exposure</Label>
                <Switch
                  checked={toBool(device.cameraConfig.cameraSetAutoExposure)}
                  onCheckedChange={(checked) =>
                    updateField("cameraConfig.cameraSetAutoExposure", checked)
                  }
                  disabled={isUpdating}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="cameraAbsoluteExposure" className="text-sm">Exposure Time (μs)</Label>
                <div className="flex gap-2">
                  <Input
                    id="cameraAbsoluteExposure"
                    type="number"
                    min="1000"
                    max="30000"
                    value={absoluteExposure}
                    onChange={(e) => setAbsoluteExposure(e.target.value)}
                    disabled={isUpdating || toBool(device.cameraConfig.cameraSetAutoExposure)}
                    className={`h-9 ${toBool(device.cameraConfig.cameraSetAutoExposure) ? 'opacity-50' : ''}`}
                  />
                  <Button
                    size="sm"
                    onClick={() => updateField("cameraConfig.cameraAbsoluteExposure", absoluteExposure)}
                    disabled={isUpdating || toBool(device.cameraConfig.cameraSetAutoExposure)}
                  >
                    <Save className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <Label className="text-sm">Auto ISO</Label>
                <Switch
                  checked={toBool(device.cameraConfig.cameraSetAutoISOSensitive)}
                  onCheckedChange={(checked) =>
                    updateField("cameraConfig.cameraSetAutoISOSensitive", checked)
                  }
                  disabled={isUpdating}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="cameraISOSensitivity" className="text-sm">ISO Sensitivity (100-1200)</Label>
                <div className="flex gap-2">
                  <Input
                    id="cameraISOSensitivity"
                    type="number"
                    min="100"
                    max="1200"
                    value={isoSensitivity}
                    onChange={(e) => setIsoSensitivity(e.target.value)}
                    disabled={isUpdating || toBool(device.cameraConfig.cameraSetAutoISOSensitive)}
                    className={`h-9 ${toBool(device.cameraConfig.cameraSetAutoISOSensitive) ? 'opacity-50' : ''}`}
                  />
                  <Button
                    size="sm"
                    onClick={() => updateField("cameraConfig.cameraISOSensitivity", isoSensitivity)}
                    disabled={isUpdating || toBool(device.cameraConfig.cameraSetAutoISOSensitive)}
                  >
                    <Save className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <Separator />

          <div>
            <h3 className="text-sm font-medium mb-3">Manual Controls</h3>
            <div className="grid grid-cols-1 gap-6">
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="cameraSaturation" className="text-sm">Color Saturation (Level {mapSliderToLevel(cameraSaturation)})</Label>
                  <div className="flex gap-4 items-center">
                    <Slider
                      id="cameraSaturation"
                      min={1}
                      max={6}
                      step={1}
                      value={[cameraSaturation]}
                      onValueChange={(value) => setCameraSaturation(value[0])}
                      className="flex-1"
                      disabled={isUpdating}
                    />
                    <Button
                      size="sm"
                      onClick={() => updateField("cameraConfig.cameraSaturation", mapSliderToLevel(cameraSaturation))}
                      disabled={isUpdating}
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="cameraEV" className="text-sm">Exposure Value (Level {mapSliderToLevel(cameraEV)})</Label>
                  <div className="flex gap-4 items-center">
                    <Slider
                      id="cameraEV"
                      min={1}
                      max={6}
                      step={1}
                      value={[cameraEV]}
                      onValueChange={(value) => setCameraEV(value[0])}
                      className="flex-1"
                      disabled={isUpdating}
                    />
                    <Button
                      size="sm"
                      onClick={() => updateField("cameraConfig.cameraEV", mapSliderToLevel(cameraEV))}
                      disabled={isUpdating}
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="cameraContrast" className="text-sm">Contrast (Level {mapSliderToLevel(cameraContrast)})</Label>
                  <div className="flex gap-4 items-center">
                    <Slider
                      id="cameraContrast"
                      min={1}
                      max={6}
                      step={1}
                      value={[cameraContrast]}
                      onValueChange={(value) => setCameraContrast(value[0])}
                      className="flex-1"
                      disabled={isUpdating}
                    />
                    <Button
                      size="sm"
                      onClick={() => updateField("cameraConfig.cameraContrast", mapSliderToLevel(cameraContrast))}
                      disabled={isUpdating}
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="cameraBrightness" className="text-sm">Brightness (Level {mapSliderToLevel(cameraBrightness)})</Label>
                  <div className="flex gap-4 items-center">
                    <Slider
                      id="cameraBrightness"
                      min={1}
                      max={6}
                      step={1}
                      value={[cameraBrightness]}
                      onValueChange={(value) => setCameraBrightness(value[0])}
                      className="flex-1"
                      disabled={isUpdating}
                    />
                    <Button
                      size="sm"
                      onClick={() => updateField("cameraConfig.cameraBrightness", mapSliderToLevel(cameraBrightness))}
                      disabled={isUpdating}
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <AlertDialog open={showWarningDialog} onOpenChange={setShowWarningDialog}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Warning: Exceeds Contract Allowance</AlertDialogTitle>
            <AlertDialogDescription>
              Your current configuration will result in more images than allowed by your contract.
              Are you sure you want to continue?
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setShowWarningDialog(false);
              setPendingUpdate(null);
            }}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmUpdate}>
              Continue Anyway
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
