
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { FileText } from "lucide-react";
import { Link } from "react-router-dom";

interface DeviceMapMarkerProps {
  id: string;
  name: string;
  description?: string;
  thumbnailUrl?: string;
}

export const DeviceMapMarker = ({ id, name, description, thumbnailUrl }: DeviceMapMarkerProps) => {
  return (
    <Card className="w-48 p-2 shadow-lg">
      {thumbnailUrl && (
        <img
          src={thumbnailUrl}
          alt={name}
          className="w-full h-24 object-cover rounded-sm mb-2"
          loading="lazy"
          width={192}
          height={96}
        />
      )}
      <div className="space-y-1">
        <div className="flex items-center justify-between gap-2">
          <h3 className="font-semibold text-sm truncate">{name}</h3>
          <Button
            variant="ghost"
            size="sm"
            className="h-8 w-8 p-0"
            asChild
          >
            <Link to={`/dashboard/device/${id}`}>
              <FileText className="h-4 w-4" />
            </Link>
          </Button>
        </div>
        {description && (
          <p className="text-xs text-muted-foreground line-clamp-2">{description}</p>
        )}
      </div>
    </Card>
  );
};
