
import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface ImageQuotaPieChartProps {
  imagesAllowed: number | null;
  imagesCurrent: number | null;
}

export const ImageQuotaPieChart = ({ imagesAllowed, imagesCurrent }: ImageQuotaPieChartProps) => {
  // Set default values if null
  const allowed = imagesAllowed || 0;
  const current = imagesCurrent || 0;
  
  const used = current;
  const remaining = Math.max(0, allowed - current);
  
  const data = [
    { name: 'Used', value: used },
    { name: 'Remaining', value: remaining || 1 }, // Ensure at least 1 to show empty chart
  ];

  const COLORS = ['#0ea5e9', '#22c55e']; 

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="bg-white rounded-lg p-1 shadow-sm relative">
            <PieChart width={40} height={40}>
              <Pie
                data={data}
                innerRadius={12}
                outerRadius={18}
                paddingAngle={2}
                dataKey="value"
                strokeWidth={0}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <div className="absolute inset-0 flex items-center justify-center">
              <span className="text-[10px] font-medium text-neutral-700">
                {remaining}
              </span>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>Number of images remaining</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
