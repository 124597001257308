
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { supabase } from "@/integrations/supabase/client";
import { Dialog, DialogContent, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useState } from "react";

type ViewType = 'sign_in' | 'sign_up' | 'magic_link' | 'forgotten_password' | 'update_password';

interface AuthDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isResettingPassword: boolean;
  isProcessingReset: boolean;
  email: string;
  setEmail: (email: string) => void;
  newPassword: string;
  setNewPassword: (password: string) => void;
  onPasswordReset: (e: React.FormEvent) => Promise<void>;
}

export function AuthDialog({
  open,
  onOpenChange,
  isResettingPassword,
  isProcessingReset,
  email,
  setEmail,
  newPassword,
  setNewPassword,
  onPasswordReset
}: AuthDialogProps) {
  const getAuthConfig = () => ({
    supabaseClient: supabase,
    appearance: {
      theme: ThemeSupa,
      variables: {
        default: {
          colors: {
            brand: '#2563eb',
            brandAccent: '#1d4ed8'
          }
        }
      },
      className: {
        container: 'w-full',
        button: 'w-full bg-brand-500 hover:bg-brand-600 text-white',
        input: 'rounded-md',
        divider: 'my-4',
        message: 'text-center text-sm'
      }
    },
    providers: [] as any[],
    redirectTo: `${window.location.origin}/#`,
    magicLink: true,
    showLinks: true,
    view: (isResettingPassword ? 'update_password' : 'sign_in') as ViewType,
    onlyThirdPartyProviders: false,
    localization: {
      variables: {
        sign_in: {
          email_label: 'Email address',
          password_label: 'Password',
          button_label: 'Sign in',
          loading_button_label: 'Signing in...',
          link_text: 'Already have an account? Sign in'
        },
        forgotten_password: {
          link_text: 'Forgot your password?',
          button_label: 'Send reset instructions',
          loading_button_label: 'Sending reset instructions...',
          confirmation_text: 'Check your email for the password reset link'
        },
        update_password: {
          button_label: 'Update password',
          loading_button_label: 'Updating password...',
          password_label: 'New password'
        }
      }
    }
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        {isResettingPassword ? (
          <>
            <DialogTitle>Reset Your Password</DialogTitle>
            <DialogDescription>
              Please enter your email and new password to complete the reset process.
            </DialogDescription>
            <form onSubmit={onPasswordReset} className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isProcessingReset}
                  placeholder="Enter your email"
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="newPassword">New Password</Label>
                <Input
                  id="newPassword"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  disabled={isProcessingReset}
                  placeholder="Enter your new password"
                  minLength={6}
                />
              </div>
              <Button type="submit" className="w-full" disabled={isProcessingReset}>
                {isProcessingReset ? 'Resetting Password...' : 'Reset Password'}
              </Button>
            </form>
          </>
        ) : (
          <>
            <DialogTitle>Welcome Back</DialogTitle>
            <DialogDescription>
              Sign in to your account to continue.
            </DialogDescription>
            <div className="grid gap-6">
              <div className="flex flex-col items-center gap-4 mb-4">
                <img 
                  src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" 
                  alt="Snapshot Live Logo" 
                  className="h-12 object-contain" 
                  width={144} 
                  height={48}
                />
              </div>
              <Auth {...getAuthConfig()} />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
