
import React from "react"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { useToast } from "@/hooks/use-toast"

type CookiePreference = "all" | "necessary" | "none"

export function CookieBanner() {
  const [open, setOpen] = React.useState(true)
  const [preference, setPreference] = React.useState<CookiePreference>("necessary")
  const { toast } = useToast()

  const handleSave = () => {
    // Save the cookie preference to localStorage
    localStorage.setItem("cookiePreference", preference)
    
    // Show success message
    toast({
      title: "Cookie preferences saved",
      description: `Your cookie preferences have been set to: ${preference}`,
    })
    
    setOpen(false)
  }

  // Don't show the banner if preferences are already set
  React.useEffect(() => {
    const savedPreference = localStorage.getItem("cookiePreference")
    if (savedPreference) {
      setOpen(false)
    }
  }, [])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Cookie Preferences</DialogTitle>
          <DialogDescription>
            We use cookies to enhance your browsing experience. Please choose your cookie preferences below.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <RadioGroup
            defaultValue={preference}
            onValueChange={(value) => setPreference(value as CookiePreference)}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="all" id="all" />
              <Label htmlFor="all">Accept all cookies</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="necessary" id="necessary" />
              <Label htmlFor="necessary">Accept necessary cookies only</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="none" id="none" />
              <Label htmlFor="none">Reject all cookies</Label>
            </div>
          </RadioGroup>
        </div>
        <div className="flex justify-end space-x-2">
          <Button onClick={handleSave}>Save preferences</Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
