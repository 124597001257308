
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MapPin, Compass, Navigation, Satellite } from "lucide-react";

export const DeviceLocation = ({ device }: { device: any }) => {
  const hasLocation =
    device.gnss?.latitude && device.gnss?.longitude && device.gnss?.altitude;

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <div className="flex items-center">
            <MapPin className="h-5 w-5 text-brand-500 mr-2" />
            <CardTitle className="text-base sm:text-lg">Location Details</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          {hasLocation ? (
            <div className="space-y-6">
              {/* Coordinates Card */}
              <Card className="border bg-muted/50">
                <CardContent className="pt-6">
                  <div className="flex items-center gap-2 mb-4">
                    <Navigation className="h-4 w-4 text-brand-500" />
                    <h3 className="font-medium">Coordinates</h3>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">Latitude</p>
                      <p className="font-medium">
                        {device.gnss.latitude}° {device.gnss.northSouth}
                      </p>
                    </div>
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">Longitude</p>
                      <p className="font-medium">
                        {device.gnss.longitude}° {device.gnss.eastWest}
                      </p>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Altitude Card */}
              <Card className="border bg-muted/50">
                <CardContent className="pt-6">
                  <div className="flex items-center gap-2 mb-4">
                    <Compass className="h-4 w-4 text-brand-500" />
                    <h3 className="font-medium">Elevation Data</h3>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">Altitude</p>
                      <p className="font-medium">{device.gnss.altitude} meters</p>
                    </div>
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">Geoidal Separation</p>
                      <p className="font-medium">{device.gnss.geoidal} meters</p>
                    </div>
                  </div>
                </CardContent>
              </Card>

              {/* Signal Info Card */}
              <Card className="border bg-muted/50">
                <CardContent className="pt-6">
                  <div className="flex items-center gap-2 mb-4">
                    <Satellite className="h-4 w-4 text-brand-500" />
                    <h3 className="font-medium">Signal Information</h3>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">Satellites</p>
                      <p className="font-medium">{device.gnss.satellites} in view</p>
                    </div>
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">HDOP</p>
                      <p className="font-medium">{device.gnss.hdop}</p>
                    </div>
                    <div>
                      <p className="text-xs text-muted-foreground mb-1">Last Update</p>
                      <p className="font-medium">{device.gnss.utcTime || "N/A"}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          ) : (
            <div className="text-center py-8">
              <Satellite className="h-12 w-12 text-muted-foreground mx-auto mb-4" />
              <p className="text-muted-foreground">
                No location data available. The device may need GPS enabled or might be searching for satellites.
              </p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
