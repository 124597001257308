
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { DeviceAssignmentTable } from "@/components/devices/DeviceAssignmentTable";
import { AddAssignmentSection } from "@/components/devices/AddAssignmentSection";

const DeviceAssignments = () => {
  const handleDeviceAdded = () => {
    // Refresh data if needed
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full">
        <AppSidebar />
        <main className="flex-1">
          <div className="p-8 space-y-8">
            <h1 className="text-2xl font-bold">Device Assignments</h1>
            <AddAssignmentSection onAssignmentAdded={handleDeviceAdded} />
            <DeviceAssignmentTable />
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default DeviceAssignments;
