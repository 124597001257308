import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Info, House, Camera, Map, GalleryHorizontal, Shield, Images, Monitor, Smartphone, Eye, Cog, Bell, MapPin, ImageIcon } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function Documentation() {
  return <SidebarProvider>
      <div className="flex min-h-screen w-full">
        <AppSidebar />
        <main className="flex-1 p-8">
          <ScrollArea className="h-full">
            <div className="max-w-5xl mx-auto space-y-8">
              <div className="space-y-2">
                <h1 className="text-4xl font-bold tracking-tight">Documentation</h1>
                <p className="text-muted-foreground text-lg">
                  Complete guide to using the Snapshot Live Web Portal
                </p>
              </div>

              <Tabs defaultValue="intro" className="w-full">
                <TabsList className="grid w-full grid-cols-2 lg:grid-cols-7 gap-4">
                  <TabsTrigger value="intro" className="flex gap-2 items-center">
                    <Info className="h-4 w-4" />
                    <span className="hidden lg:inline">Introduction</span>
                  </TabsTrigger>
                  <TabsTrigger value="home" className="flex gap-2 items-center">
                    <House className="h-4 w-4" />
                    <span className="hidden lg:inline">Home</span>
                  </TabsTrigger>
                  <TabsTrigger value="camera" className="flex gap-2 items-center">
                    <Camera className="h-4 w-4" />
                    <span className="hidden lg:inline">Camera</span>
                  </TabsTrigger>
                  <TabsTrigger value="map" className="flex gap-2 items-center">
                    <Map className="h-4 w-4" />
                    <span className="hidden lg:inline">Map</span>
                  </TabsTrigger>
                  <TabsTrigger value="gallery" className="flex gap-2 items-center">
                    <GalleryHorizontal className="h-4 w-4" />
                    <span className="hidden lg:inline">Gallery</span>
                  </TabsTrigger>
                  <TabsTrigger value="admin" className="flex gap-2 items-center">
                    <Shield className="h-4 w-4" />
                    <span className="hidden lg:inline">Admin</span>
                  </TabsTrigger>
                  <TabsTrigger value="reporting" className="flex gap-2 items-center">
                    <Images className="h-4 w-4" />
                    <span className="hidden lg:inline">Reporting</span>
                  </TabsTrigger>
                </TabsList>

                <TabsContent value="intro">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Introduction & Navigation</h2>
                    <div className="space-y-4 text-muted-foreground">
                      <p>This documentation is designed to guide users through the Snapshot Live Web Portal, from initial navigation to the more advanced reporting features. </p>
                      <p>The Snapshot Live interface uses a sidebar or top-level navigation menu to separate its core sections. You will typically find pages labelled Home, Map, Gallery, Admin, and Reporting, with each page offering a distinct set of functionalities. The Camera Configuration menu can be accessed from the camera feeds on the Home screen. 


Administrators enjoy extended privileges such as adding new cameras, managing user access, and viewing advanced organisational settings. Standard users, on the other hand, see a simplified interface focused on monitoring and reporting. This clear delineation of roles and pages helps ensure that users can work efficiently without being overwhelmed by irrelevant options.</p>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="home">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Home Page</h2>
                    <div className="space-y-6 text-muted-foreground">
                      <div className="flex flex-col md:flex-row gap-8 items-start">
                        <div className="space-y-4 md:w-1/2">
                          <p>The Home Page is your starting point in the Web Portal and provides a clear overview of your camera fleet. You will see the latest snapshot from each camera, accompanied by its name, a brief description, and a timestamp indicating how long ago it was captured. The edit button in the top right corner of each image, will open the Camera configuration menu. At the top-right corner of the screen, you can click a Refresh button to load the most recent set of images.</p>
                          <p>
                            There is also a chart displaying your remaining monthly image allocation. Each contract includes a preset number of images, resetting at midnight on the first day of every month. The chart highlights how many images you have used and how many remain. If you exceed your contracted limit, the affected camera will stop recording until the next month. However, you receive ten extra overflow images for setting up and testing each camera, which do not appear in this usage chart.
                          </p>
                          <div className="flex items-center gap-2 p-4 bg-muted rounded-lg">
                            <Monitor className="h-5 w-5" />
                            <Smartphone className="h-5 w-5" />
                            <p className="text-sm">
                              The Home Page, along with the Camera Configuration pages, are fully accessible on both mobile and desktop browsers. Other aspects of the portal, such as reporting and certain navigation elements, are optimised only for desktop use. This distinction helps ensure the best possible user experience across a range of devices.
                            </p>
                          </div>
                        </div>
                        <div className="md:w-1/2">
                          <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Home.png?updatedAt=1741602717168" alt="Home page interface screenshot" className="w-full rounded-lg shadow-lg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="camera">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Camera Configuration</h2>
                    <div className="space-y-6 text-muted-foreground">
                      <p>The Camera Configuration area comprises five sections: Overview, Config, Notifications, Location, and Past Images. Given their interrelated nature, these sections can be grouped under a single collapsible menu or a side-expanding interface. This approach keeps the user experience clean and organised, particularly on mobile devices. </p>
                      
                      <Tabs defaultValue="overview" className="w-full">
                        <TabsList className="w-full grid grid-cols-2 lg:grid-cols-5 gap-2">
                          <TabsTrigger value="overview" className="flex gap-2 items-center">
                            <Eye className="h-4 w-4" />
                            <span>Overview</span>
                          </TabsTrigger>
                          <TabsTrigger value="config" className="flex gap-2 items-center">
                            <Cog className="h-4 w-4" />
                            <span>Config</span>
                          </TabsTrigger>
                          <TabsTrigger value="notifications" className="flex gap-2 items-center">
                            <Bell className="h-4 w-4" />
                            <span>Notifications</span>
                          </TabsTrigger>
                          <TabsTrigger value="location" className="flex gap-2 items-center">
                            <MapPin className="h-4 w-4" />
                            <span>Location</span>
                          </TabsTrigger>
                          <TabsTrigger value="pastimages" className="flex gap-2 items-center">
                            <ImageIcon className="h-4 w-4" />
                            <span>Past Images</span>
                          </TabsTrigger>
                        </TabsList>

                        <TabsContent value="overview" className="mt-6">
                          <Card>
                            <CardHeader>
                              <CardTitle>Overview</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <div className="space-y-4">
                                <p className="text-muted-foreground">
                                  Within the Overview section, users can see the most recent image captured by the camera, as well as editable text fields for the camera's Name and Description. Additionally, the screen displays essential technical details such as the current network type, signal strength, and signal quality for the camera's cellular connection. You can also see the battery's charge level and voltage, along with the camera's firmware version and how recently it updated. The device's status reveals whether it is actively recording images according to its schedule or paused until the next active period.
                                </p>
                                <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Config%20-%20Overview.png?updatedAt=1741602713026" alt="Camera overview interface screenshot" className="w-1/2 rounded-lg shadow-lg mx-auto" />
                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>

                        <TabsContent value="config" className="mt-6">
                          <Card>
                            <CardHeader>
                              <CardTitle>Configuration</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <div className="space-y-4">
                                <p className="text-muted-foreground">
                                  The Config page determines the camera's operational schedule and image capture settings. You can assign a Start time and End time for camera activity each day, then choose an Interval Timer that dictates how often an image is captured during those active hours. As you input these details, the interface calculates how many images the camera will upload during a monthly cycle. If the estimated figure exceeds your contractual allowance, a warning will appear.
                                </p>
                                <p className="text-muted-foreground">
                                  Beyond scheduling, the Config page provides manual overrides for camera image settings, including shutter exposure time, ISO levels, and colour saturation. Adjustments to contrast and brightness are also available. Should your monthly image usage be exceeded at any point, the camera will automatically stop recording until the start of the next month, preventing any surprise overages.
                                </p>
                                <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Config%20-%20Config.png?updatedAt=1741602719761" alt="Camera configuration interface screenshot" className="w-1/2 rounded-lg shadow-lg mx-auto" />
                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>

                        <TabsContent value="notifications" className="mt-6">
                          <Card>
                            <CardHeader>
                              <CardTitle>Notifications</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <div className="space-y-4">
                                <p className="text-muted-foreground">
                                  Notifications are controlled through a built-in AI algorithm, which you can enable by toggling the Enable Notifications setting. Once active, you may enter a custom True/False query (for example, "Does this image contain a vehicle?"). If the algorithm's answer is True, the captured image is highlighted on-screen and within future reporting features. You can also specify an email address that will receive alerts whenever an image meets these criteria.
                                </p>
                                <p className="text-muted-foreground">
                                  An additional "Surveillance Mode" setting forces every recorded image to be emailed to the specified address, whether it meets the alert conditions or not. This can be especially valuable when you need exhaustive monitoring of a sensitive site. The page includes an AI Results area that displays the outcome of the last query and any Google Vision Safe Search flags, helping ensure that all imagery remains compliant with Snapshot Live's usage policies.
                                </p>
                                <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Config%20-%20Notifications.png?updatedAt=1741602712104" alt="Camera notifications interface screenshot" className="w-1/2 rounded-lg shadow-lg mx-auto" />
                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>

                        <TabsContent value="location" className="mt-6">
                          <Card>
                            <CardHeader>
                              <CardTitle>Location</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <div className="space-y-4">
                                <p className="text-muted-foreground">
                                  The Location section automatically retrieves the camera's positioning data using a high-accuracy GNSS receiver. This process occurs when the device first powers on, when the previous recorded location is deemed insufficiently accurate, or whenever the battery level remains above 75%. Location details typically include latitude, longitude, altitude, and geoidal separation. Additionally, a number of satellites and HDOP (Horizontal Dilution of Precision) value are displayed to inform users about the reliability of the position lock. A lower HDOP generally corresponds to higher accuracy, and the Last Update timestamp indicates exactly when the camera last attempted or successfully recorded its location.
                                </p>
                                <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Config%20-%20Location.png?updatedAt=1741602712095" alt="Camera location interface screenshot" className="w-1/2 rounded-lg shadow-lg mx-auto" />
                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>

                        <TabsContent value="pastimages" className="mt-6">
                          <Card>
                            <CardHeader>
                              <CardTitle>Past Images</CardTitle>
                            </CardHeader>
                            <CardContent>
                              <div className="space-y-4">
                                <p className="text-muted-foreground">
                                  This section offers a chronological listing of the most recent 12 snapshots taken by the camera. Each thumbnail can be selected or expanded to a larger size, useful for quick checks on smaller mobile devices. Although it does not match the depth of the reporting features available on desktop, it offers a convenient method for users on the move to see a short recent history.
                                </p>
                                <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Config%20-%20Past%20Images.png?updatedAt=1741602713104" alt="Camera past images interface screenshot" className="w-1/2 rounded-lg shadow-lg mx-auto" />
                              </div>
                            </CardContent>
                          </Card>
                        </TabsContent>
                      </Tabs>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="map">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Map Page</h2>
                    <div className="flex flex-col md:flex-row gap-8 items-start">
                      <div className="space-y-4 md:w-1/2">
                        <p className="text-muted-foreground">
                          The Map Page presents a geographical overview of all registered cameras, positioning them at the coordinates provided by each camera's GNSS receiver. This location data updates intermittently, particularly when the device is physically moved, powered on, or woken by pressing the front button. Once the camera acquires sufficient GPS accuracy (indicated by a favourable HDOP), it retains that location until conditions change or the battery falls below 75%.
                        </p>
                        <p className="text-muted-foreground">
                          Because the availability of overhead satellites and environmental factors can influence GPS reliability, users may notice differences in how quickly or precisely a camera's position is updated. Nonetheless, the Map Page provides a clear visual reference for field operations, construction sites, nature reserves, or any environment where ongoing awareness of camera placement is essential.
                        </p>
                      </div>
                      <div className="md:w-1/2">
                        <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Map.png?updatedAt=1741602719056" alt="Map page interface screenshot" className="w-full rounded-lg shadow-lg" />
                      </div>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="gallery">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Gallery Page</h2>
                    <div className="flex flex-col md:flex-row gap-8 items-start">
                      <div className="space-y-4 md:w-1/2">
                        <p className="text-muted-foreground">The Gallery Page showcases a dynamic slideshow of images collected from all active cameras. Every 10 seconds, the gallery cycles to the next image, making it an excellent choice for a digital sign or reception monitor. Users can engage a pop-out or full-screen button (located in the top-right corner), turning the gallery into a self-refreshing display that updates as new images arrive.</p>
                        <p className="text-muted-foreground">
                          This interface is deliberately kept minimal, focusing on a pleasing visual presentation rather than advanced controls. By dedicating the page primarily to straightforward image rotations, the Gallery encourages a more casual, public-facing use case, ideal for conferences or visitor centres.
                        </p>
                      </div>
                      <div className="md:w-1/2">
                        <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Gallery.png?updatedAt=1741602720321" alt="Gallery page interface screenshot" className="w-full rounded-lg shadow-lg" />
                      </div>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="admin">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Admin Page</h2>
                    <div className="space-y-8">
                      <div className="flex flex-col md:flex-row gap-8 items-start">
                        <div className="space-y-4 md:w-1/2">
                          <h3 className="text-xl font-semibold">Devices</h3>
                          <p className="text-muted-foreground">Administrators are offered a dedicated Device Management interface, allowing them to register or remove cameras and rename the organisation. When adding a new camera, they can enter a unique ID code or scan a QR code printed on the camera's exterior. The QR code feature relies on permission for browser-based camera access, which might require users to grant specific rights in their browser settings.</p>
                        </div>
                        <div className="md:w-1/2">
                          <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Admin%20-%20Devices.png?updatedAt=1741602719711" alt="Admin devices management interface screenshot" className="w-full rounded-lg shadow-lg" />
                        </div>
                      </div>

                      <div className="flex flex-col md:flex-row gap-8 items-start">
                        <div className="space-y-4 md:w-1/2">
                          <h3 className="text-xl font-semibold">Users</h3>
                          <p className="text-muted-foreground">
                            Another critical administrative function involves controlling user access. Through the Admin Page, an administrator can invite new individuals to join the organisation, selecting which cameras they may view or modify. This granular approach enhances security by assigning privileges only to those who genuinely need them. Existing user rights can also be updated or revoked as circumstances change, ensuring a flexible but orderly account management system.
                          </p>
                        </div>
                        <div className="md:w-1/2">
                          <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Admin%20-%20Users.png?updatedAt=1741602719637" alt="Admin user management interface screenshot" className="w-full rounded-lg shadow-lg" />
                        </div>
                      </div>
                    </div>
                  </div>
                </TabsContent>

                <TabsContent value="reporting">
                  <div className="mt-6">
                    <h2 className="text-2xl font-semibold mb-4">Reporting</h2>
                    <div className="flex flex-col md:flex-row gap-8 items-start">
                      <div className="space-y-8 md:w-1/2">
                        <div className="space-y-4">
                          <p className="text-muted-foreground">
                            Reporting is handled on a per-device basis, meaning each camera has its own dedicated reporting interface. Users can switch between Grid, List, or Timeline views to examine historical images and any associated AI analysis.
                          </p>
                          <p className="text-muted-foreground">
                            A Grid View might tile several images together for quick pattern recognition, while a List View displays additional metadata in a vertical format. The Timeline View arranges pictures chronologically, highlighting those that trigger AI-based alerts. You can specify how many of the camera's most recent images to show or apply a date filter to focus on a particular timeframe.
                          </p>
                          <p className="text-muted-foreground">
                            AI alerts are visually distinct, often shown in red, to emphasise relevant events such as unexpected activity or changes in the scene. This helps direct attention to potential issues without having to sift through every single image manually.
                          </p>
                        </div>

                        <div>
                          <h3 className="text-xl font-semibold mb-4">Reporting Tools</h3>
                          <div className="space-y-4">
                            <p className="text-muted-foreground">
                              Export options allow you to download a PDF containing the currently displayed images along with the corresponding AI analytics. This feature proves valuable for audits, incident reviews, and formal presentations. You can also save selected images individually, or bundle them into a ZIP file for offline archiving.
                            </p>
                            <p className="text-muted-foreground">
                              A timelapse function enables you to compile up to 100 images into a short video, illustrating gradual changes at a site or providing a dynamic summary of your camera feed over a selected timeframe. These advanced capabilities equip administrators and project managers with actionable information in a format they can easily store or share.
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="md:w-1/2 space-y-8">
                        <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Reporting%20-%20Grid.png?updatedAt=1741602717023" alt="Grid view reporting interface" className="w-full rounded-lg shadow-lg" />
                        <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Reporting%20-%20List.png?updatedAt=1741602712895" alt="List view reporting interface" className="w-full rounded-lg shadow-lg" />
                        <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Reporting%20-%20Timeline.png?updatedAt=1741602717950" alt="Timeline view reporting interface" className="w-full rounded-lg shadow-lg" />
                      </div>
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            </div>
          </ScrollArea>
        </main>
      </div>
    </SidebarProvider>;
}
