import { SidebarProvider } from "@/components/ui/sidebar";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from "sonner";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { DeviceGrid } from "@/components/devices/DeviceGrid";
import { DeviceHistory } from "@/components/devices/tabs/DeviceHistory";
import { useIsMobile } from "@/hooks/use-mobile";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DeviceAssignmentHandler } from "@/components/auth/DeviceAssignmentHandler";
import { AppSidebar } from "@/components/AppSidebar";

interface Profile {
  username: string | null;
  avatar_url: string | null;
}

interface DeviceAssignment {
  device_id: string;
  device_details: {
    device_name: string | null;
    device_description: string | null;
  } | null;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const [isOpen, setIsOpen] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);
  const [previousDeviceId, setPreviousDeviceId] = useState<string | null>(null);
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();

  const { data: devices } = useQuery({
    queryKey: ["device-assignments-with-names"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        navigate("/");
        throw new Error("No session");
      }

      const { data, error } = await supabase
        .from('device_assignments')
        .select(`
          device_id,
          device_details:device_details!device_assignments_device_id_fkey(
            device_name,
            device_description
          )
        `)
        .eq('user_id', session.user.id);
      
      if (error) {
        console.error("Error fetching device assignments:", error);
        throw error;
      }
      return data as DeviceAssignment[];
    }
  });

  const currentDevice = devices?.find(d => d.device_id === deviceId);

  const { data: currentDeviceDetails } = useQuery({
    queryKey: ["device-details", deviceId],
    queryFn: async () => {
      if (!deviceId) return null;
      
      const { data, error } = await supabase
        .from('device_details')
        .select('device_description, device_name')
        .eq('device_id', deviceId)
        .single();
      
      if (error) {
        console.error("Error fetching device details:", error);
        throw error;
      }
      return data;
    },
    enabled: !!deviceId
  });

  useEffect(() => {
    if (deviceId && deviceId !== previousDeviceId) {
      setPreviousDeviceId(deviceId);
    }
  }, [deviceId]);

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      const { data, error } = await supabase
        .from("profiles")
        .select("username, avatar_url")
        .eq("id", user.id)
        .maybeSingle();

      if (error) throw error;
      setProfile(data);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error loading profile");
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
      toast.success("Logged out successfully");
    } catch (error) {
      toast.error("Error logging out");
      console.error("Error:", error);
    }
  };

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ["device-assignments"] });
    queryClient.invalidateQueries({ queryKey: ["device-assignments-with-names"] });
    
    if (deviceId) {
      queryClient.invalidateQueries({ queryKey: ["device-details", deviceId] });
      queryClient.invalidateQueries({ queryKey: ["device", deviceId] });
    }
    
    if (previousDeviceId) {
      queryClient.invalidateQueries({ queryKey: ["device-details", previousDeviceId] });
      queryClient.invalidateQueries({ queryKey: ["device", previousDeviceId] });
    }
    
    devices?.forEach((assignment) => {
      queryClient.invalidateQueries({ queryKey: ["device-details", assignment.device_id] });
      queryClient.invalidateQueries({ queryKey: ["device", assignment.device_id] });
    });
    
    toast.success("Refreshing device data...");
  };

  const handleHomeClick = () => {
    handleRefresh();
    setPreviousDeviceId(null);
  };

  const { data: userRole } = useQuery({
    queryKey: ["user-role"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return null;

      const { data: role } = await supabase.rpc('get_user_role', {
        user_id: session.user.id
      });

      return role;
    }
  });

  return (
    <>
      <DeviceAssignmentHandler />
      <SidebarProvider defaultOpen={isOpen} onOpenChange={setIsOpen}>
        <div className="flex min-h-screen w-full" style={{ "--sidebar-width": "14rem", "--sidebar-width-icon": "4rem" } as React.CSSProperties}>
          <AppSidebar />
          <main className="flex-1">
            <div className="w-full p-4">
              <div className="flex items-center justify-between mb-4">
                {!deviceId ? (
                  <div className="flex items-center gap-4">
                    {isMobile ? (
                      <Link to="/dashboard/profile">
                        <Avatar>
                          <AvatarImage src={profile?.avatar_url || undefined} />
                          <AvatarFallback>
                            {profile?.username?.charAt(0).toUpperCase() || 'U'}
                          </AvatarFallback>
                        </Avatar>
                      </Link>
                    ) : (
                      <Avatar>
                        <AvatarImage src={profile?.avatar_url || undefined} />
                        <AvatarFallback>
                          {profile?.username?.charAt(0).toUpperCase() || 'U'}
                        </AvatarFallback>
                      </Avatar>
                    )}
                    <div>
                      {isMobile ? (
                        <div className="space-y-0.5">
                          <h1 className="font-bold text-base">Welcome</h1>
                          {profile?.username && (
                            <p className="text-sm text-muted-foreground">{profile.username}</p>
                          )}
                          {userRole && (
                            <p className="text-xs text-muted-foreground capitalize">({userRole})</p>
                          )}
                        </div>
                      ) : (
                        <div>
                          <h1 className="font-bold text-xl">
                            Welcome{profile?.username ? `, ${profile.username}` : ''}
                          </h1>
                          {userRole && (
                            <p className="text-sm text-muted-foreground mt-1 capitalize">Role: {userRole}</p>
                          )}
                        </div>
                      )}
                      
                      {!profile?.username && (
                        <p className="text-sm text-muted-foreground">
                          Complete your profile to add a username and avatar
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <h1 className="font-bold text-xl">
                    {currentDevice?.device_details?.device_description || 
                     currentDevice?.device_details?.device_name || 
                     deviceId}
                  </h1>
                )}
                <img 
                  src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" 
                  alt="Snapshot Live Logo" 
                  className="h-12 object-contain"
                />
              </div>
              {deviceId ? (
                <DeviceHistory 
                  device_id={deviceId} 
                  initialData={currentDevice?.device_details}
                />
              ) : (
                <DeviceGrid />
              )}
            </div>
          </main>
        </div>
      </SidebarProvider>
    </>
  );
};

export default Dashboard;
