
import { useState } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

interface DeviceEditData {
  device_id: string;
  device_name: string | null;
  device_description: string | null;
  images_allowed: number;
  images_current: number;
}

export const useDeviceEdit = () => {
  const [isEditing, setIsEditing] = useState(false);
  const queryClient = useQueryClient();

  const updateDeviceDetails = async (data: DeviceEditData) => {
    setIsEditing(true);
    try {
      // Update the database
      const { error: dbError } = await supabase
        .from("device_details")
        .update({
          device_name: data.device_name,
          device_description: data.device_description,
          images_allowed: data.images_allowed,
          images_current: data.images_current,
        })
        .eq("device_id", data.device_id);

      if (dbError) throw dbError;

      // Update the contract via API
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.access_token) throw new Error("No session found");

      // Update device name if provided
      if (data.device_name !== null) {
        const paramsName = new URLSearchParams();
        paramsName.append("deviceID", data.device_id);
        paramsName.append("field", "deviceName");
        paramsName.append("value", data.device_name);

        console.log('Updating device name:', data.device_id, data.device_name);
        const responseName = await fetch(`https://i56o7w.buildship.run/updateDevice`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: paramsName,
        });

        if (!responseName.ok) {
          console.error('Failed to update device name:', await responseName.text());
          throw new Error("Failed to update device name");
        }
      }

      // Update device description if provided
      if (data.device_description !== null) {
        const paramsDesc = new URLSearchParams();
        paramsDesc.append("deviceID", data.device_id);
        paramsDesc.append("field", "deviceDescription");
        paramsDesc.append("value", data.device_description);

        console.log('Updating device description:', data.device_id, data.device_description);
        const responseDesc = await fetch(`https://i56o7w.buildship.run/updateDevice`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${session.access_token}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: paramsDesc,
        });

        if (!responseDesc.ok) {
          console.error('Failed to update device description:', await responseDesc.text());
          throw new Error("Failed to update device description");
        }
      }

      // Update images allowed
      const paramsAllowed = new URLSearchParams();
      paramsAllowed.append("deviceID", data.device_id);
      paramsAllowed.append("field", "contract.imagesAllowed");
      paramsAllowed.append("value", data.images_allowed.toString());

      console.log('Updating images allowed:', data.device_id, data.images_allowed);
      const responseAllowed = await fetch(`https://i56o7w.buildship.run/updateDevice`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: paramsAllowed,
      });

      if (!responseAllowed.ok) {
        console.error('Failed to update images allowed:', await responseAllowed.text());
        throw new Error("Failed to update device contract images allowed");
      }

      // Update images current
      const paramsCurrent = new URLSearchParams();
      paramsCurrent.append("deviceID", data.device_id);
      paramsCurrent.append("field", "contract.imagesCurrent");
      paramsCurrent.append("value", data.images_current.toString());

      console.log('Updating images current:', data.device_id, data.images_current);
      const responseCurrent = await fetch(`https://i56o7w.buildship.run/updateDevice`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: paramsCurrent,
      });

      if (!responseCurrent.ok) {
        console.error('Failed to update images current:', await responseCurrent.text());
        throw new Error("Failed to update device contract images current");
      }

      // Invalidate and refetch queries
      await queryClient.invalidateQueries({ queryKey: ["device-details"] });
      
      toast.success("Device details updated successfully");
      return true;
    } catch (error) {
      console.error("Error updating device:", error);
      toast.error("Failed to update device details");
      return false;
    } finally {
      setIsEditing(false);
    }
  };

  return {
    isEditing,
    updateDeviceDetails
  };
};
