import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Trash } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { toast } from "sonner";

export const DeviceAssignmentTable = () => {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [filters, setFilters] = useState({
    deviceId: "all",
    userId: "all",
    adminOnly: false,
    organizationId: "all",
  });

  const { data: assignments, refetch: refetchAssignments } = useQuery({
    queryKey: ["deviceAssignments"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("device_assignments")
        .select(`
          id,
          device_id,
          user_id,
          assigned_at,
          device_details!device_assignments_device_id_fkey(device_name),
          profiles(username)
        `);
      
      if (error) throw error;
      return data;
    },
  });

  const { data: devices } = useQuery({
    queryKey: ["devices"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("device_details")
        .select("device_id, device_name");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: users } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("profiles")
        .select("id, username");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: userRoles } = useQuery({
    queryKey: ["userRoles"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("user_roles")
        .select("user_id, role, organization_id");
      
      if (error) throw error;
      return data;
    },
  });

  const { data: organizations } = useQuery({
    queryKey: ["organizations"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("organizations")
        .select("id, name");
      
      if (error) throw error;
      return data;
    },
  });

  const filteredAssignments = assignments?.filter((assignment) => {
    const userRole = userRoles?.find(role => role.user_id === assignment.user_id);
    
    if (filters.organizationId !== "all") {
      if (!userRole || userRole.organization_id !== filters.organizationId) {
        return false;
      }
    }

    if (filters.deviceId !== "all" && assignment.device_id !== filters.deviceId) {
      return false;
    }

    if (filters.userId !== "all" && assignment.user_id !== filters.userId) {
      return false;
    }

    if (filters.adminOnly) {
      if (!userRole || userRole.role !== 'admin') {
        return false;
      }
    }

    return true;
  });

  const handleDeleteAssignment = async (id: string) => {
    try {
      const { error } = await supabase
        .from("device_assignments")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast.success("Assignment deleted successfully");
      refetchAssignments();
    } catch (error) {
      console.error("Error deleting assignment:", error);
      toast.error("Failed to delete assignment");
    } finally {
      setDeleteId(null);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-wrap justify-between items-end border-b pb-4">
        <div className="flex flex-wrap gap-4 items-end">
          <div className="grid gap-2">
            <label className="text-sm font-medium">Filter by Device</label>
            <Select
              value={filters.deviceId}
              onValueChange={(value) =>
                setFilters((prev) => ({ ...prev, deviceId: value }))
              }
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="All devices" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All devices</SelectItem>
                {devices?.map((device) => (
                  <SelectItem key={device.device_id} value={device.device_id}>
                    {device.device_name || device.device_id}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid gap-2">
            <label className="text-sm font-medium">Filter by User</label>
            <Select
              value={filters.userId}
              onValueChange={(value) =>
                setFilters((prev) => ({ ...prev, userId: value }))
              }
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="All users" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All users</SelectItem>
                {users?.map((user) => (
                  <SelectItem key={user.id} value={user.id}>
                    {user.username || user.id}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="grid gap-2">
            <label className="text-sm font-medium">Filter by Organization</label>
            <Select
              value={filters.organizationId}
              onValueChange={(value) =>
                setFilters((prev) => ({ ...prev, organizationId: value }))
              }
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="All organizations" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All organizations</SelectItem>
                {organizations?.map((org) => (
                  <SelectItem key={org.id} value={org.id}>
                    {org.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="adminOnly"
              checked={filters.adminOnly}
              onCheckedChange={(checked) =>
                setFilters((prev) => ({ ...prev, adminOnly: checked === true }))
              }
            />
            <label
              htmlFor="adminOnly"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              Admins
            </label>
          </div>
        </div>

        <Button
          variant="outline"
          onClick={() => setFilters({ deviceId: "all", userId: "all", adminOnly: false, organizationId: "all" })}
        >
          Clear Filters
        </Button>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Device</TableHead>
            <TableHead>User</TableHead>
            <TableHead>Assigned At</TableHead>
            <TableHead className="w-[100px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredAssignments?.map((assignment) => (
            <TableRow key={assignment.id}>
              <TableCell>
                {assignment.device_details?.device_name || assignment.device_id}
              </TableCell>
              <TableCell>
                {assignment.profiles?.username || assignment.user_id}
              </TableCell>
              <TableCell>
                {new Date(assignment.assigned_at).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <AlertDialog open={deleteId === assignment.id} onOpenChange={(open) => !open && setDeleteId(null)}>
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => setDeleteId(assignment.id)}
                  >
                    <Trash className="h-4 w-4" />
                  </Button>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This will permanently delete this device assignment. This action cannot be undone.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction onClick={() => handleDeleteAssignment(assignment.id)}>
                        Delete
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
