
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Equipment = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Equipment Hire & Rental Terms</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">4.1 General Terms</h2>
          <p>These terms govern the hire and rental of Equipment from Snapshot Live Ltd. By proceeding with the hire or rental of our Equipment, you agree to be bound by these terms.</p>

          <h2 className="text-xl font-semibold mt-6">4.2 Equipment Hire Period</h2>
          <ul>
            <li>The hire period begins from the date of collection/delivery</li>
            <li>Minimum hire period may apply</li>
            <li>Extensions must be agreed in writing</li>
            <li>Late returns may incur additional charges</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">4.3 Customer Responsibilities</h2>
          <p>The Customer shall:</p>
          <ul>
            <li>Use the Equipment in accordance with operating instructions</li>
            <li>Maintain the Equipment in good condition</li>
            <li>Not modify or tamper with the Equipment</li>
            <li>Report any faults or damage immediately</li>
            <li>Ensure adequate insurance coverage</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">4.4 Damage and Loss</h2>
          <p>4.4.1 The Customer is responsible for:</p>
          <ul>
            <li>Any damage beyond normal wear and tear</li>
            <li>Loss or theft of Equipment</li>
            <li>Replacement costs at current market value</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">4.5 Pricing and Payment</h2>
          <ul>
            <li>Payment terms as agreed at time of hire</li>
            <li>Security deposit may be required</li>
            <li>Late payment fees may apply</li>
            <li>Prices exclude VAT unless stated</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">4.6 Liability</h2>
          <p>Snapshot Live Ltd shall not be liable for:</p>
          <ul>
            <li>Indirect or consequential losses</li>
            <li>Business interruption</li>
            <li>Loss of data or imagery</li>
            <li>Third party claims</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">4.7 Contact Information</h2>
          <p>For all equipment hire enquiries:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default Equipment;
