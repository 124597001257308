import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTitle, DialogTrigger, DialogDescription } from "@/components/ui/dialog";
import { Globe, Shield, Smartphone, ArrowRight, Calendar, Menu, X } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import TrialRequestForm from "@/components/TrialRequestForm";
import { ScrollArea } from "@/components/ui/scroll-area";
import { AuthDialog } from "@/components/auth/AuthDialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type ViewType = 'sign_in' | 'sign_up' | 'magic_link' | 'forgotten_password' | 'update_password';

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [isAuthDialogOpen, setIsAuthDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [isProcessingReset, setIsProcessingReset] = useState(false);
  const [isProcessingMagicLink, setIsProcessingMagicLink] = useState(false);
  const recoveryAttempted = useRef(false);

  const getAuthConfig = () => ({
    supabaseClient: supabase,
    appearance: {
      theme: ThemeSupa,
      variables: {
        default: {
          colors: {
            brand: '#2563eb',
            brandAccent: '#1d4ed8'
          }
        }
      }
    },
    providers: [] as any[],
    redirectTo: `${window.location.origin}/#`,
    magicLink: true,
    showLinks: true,
    view: 'sign_in' as ViewType,
    onlyThirdPartyProviders: false,
    localization: {
      variables: {
        sign_in: {
          email_label: 'Email address',
          password_label: 'Password',
          button_label: 'Sign in',
          loading_button_label: 'Signing in...',
          link_text: 'Already have an account? Sign in'
        },
        forgotten_password: {
          link_text: 'Forgot your password?',
          button_label: 'Send reset instructions',
          loading_button_label: 'Sending reset instructions...',
          confirmation_text: 'Check your email for the password reset link'
        },
        update_password: {
          button_label: 'Update password',
          loading_button_label: 'Updating password...',
          password_label: 'New password'
        }
      }
    }
  });

  useEffect(() => {
    const handleHashParams = async () => {
      const hash = location.hash;
      if (!hash || recoveryAttempted.current) return;

      console.log('Processing URL hash:', hash);
      
      const cleanHash = hash.replace(/^#+/, '');
      const hashParams = new URLSearchParams(cleanHash);
      
      const type = hashParams.get('type');
      const error = hashParams.get('error');
      const errorDescription = hashParams.get('error_description');
      const accessToken = hashParams.get('access_token');
      const refreshToken = hashParams.get('refresh_token');

      console.log('Hash parameters:', {
        type,
        error,
        errorDescription,
        hasAccessToken: !!accessToken,
        hasRefreshToken: !!refreshToken
      });
      
      recoveryAttempted.current = true;

      if (error) {
        console.log('Handling error:', error, errorDescription);
        toast({
          variant: "destructive",
          title: "Error",
          description: errorDescription || "An error occurred while processing your request.",
        });
        setIsAuthDialogOpen(true);
        return;
      }

      if (type === 'recovery' && (accessToken || refreshToken)) {
        console.log('Setting up password reset flow');
        setIsProcessingReset(true);

        try {
          const { error: sessionError } = await supabase.auth.setSession({
            access_token: accessToken || '',
            refresh_token: refreshToken || ''
          });

          if (sessionError) {
            throw sessionError;
          }

          setIsResettingPassword(true);
          setIsAuthDialogOpen(true);
          toast({
            title: "Ready to Reset",
            description: "Please enter your new password."
          });
        } catch (error: any) {
          console.error('Error setting up password reset:', error);
          toast({
            variant: "destructive",
            title: "Error",
            description: error.message || "Failed to set up password reset. Please try again."
          });
          setIsAuthDialogOpen(true);
        } finally {
          setIsProcessingReset(false);
        }
      } else if (accessToken || refreshToken) {
        console.log('Processing magic link login');
        setIsProcessingMagicLink(true);

        try {
          const { error: sessionError } = await supabase.auth.setSession({
            access_token: accessToken || '',
            refresh_token: refreshToken || ''
          });

          if (sessionError) {
            throw sessionError;
          }

          toast({
            title: "Success",
            description: "You have been successfully logged in."
          });

          // Short delay to ensure the success message is seen
          setTimeout(() => {
            navigate("/dashboard");
          }, 1500);
        } catch (error: any) {
          console.error('Error processing magic link:', error);
          toast({
            variant: "destructive",
            title: "Error",
            description: error.message || "Failed to complete login. Please try again."
          });
          setIsAuthDialogOpen(true);
        } finally {
          setIsProcessingMagicLink(false);
        }
      }
    };

    handleHashParams();
  }, [location.hash, toast, navigate]);

  useEffect(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('Auth event:', event);
      console.log('Session state:', session ? 'Session exists' : 'No session');
      
      if (session && !isResettingPassword && !isProcessingReset && !isProcessingMagicLink) {
        navigate("/dashboard");
      }
    });
    return () => subscription.unsubscribe();
  }, [navigate, isResettingPassword, isProcessingReset, isProcessingMagicLink]);

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email || !newPassword) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please enter both email and new password."
      });
      return;
    }

    try {
      setIsProcessingReset(true);
      const { error } = await supabase.auth.updateUser({
        password: newPassword
      });

      if (error) {
        throw error;
      }

      toast({
        title: "Success",
        description: "Your password has been reset successfully. You can now log in with your new password."
      });

      setIsResettingPassword(false);
      setIsAuthDialogOpen(false);
      
      // Short delay to ensure the success message is seen
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
    } catch (error: any) {
      console.error('Error resetting password:', error);
      toast({
        variant: "destructive",
        title: "Error",
        description: error.message || "Failed to reset password. Please try again."
      });
    } finally {
      setIsProcessingReset(false);
    }
  };

  const handleFormDialogChange = (open: boolean) => {
    console.log('Form dialog state changing to:', open);
    setIsFormDialogOpen(open);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-brand-50 to-brand-100">
      <nav className="py-4 px-6 bg-white shadow-sm relative">
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <img src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" alt="Snapshot Live Logo" className="h-12 object-contain" width={144} height={48} loading="eager" decoding="async" fetchPriority="high" />
          
          <button className="md:hidden p-2" onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          <div className="hidden md:flex items-center gap-6">
            <Button variant="ghost" onClick={() => navigate("/features")}>
              Features
            </Button>
            <Button variant="ghost" onClick={() => navigate("/applications")}>
              Applications
            </Button>
            <Dialog open={isAuthDialogOpen} onOpenChange={setIsAuthDialogOpen}>
              <DialogTrigger asChild>
                <Button size="lg">
                  Log In
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-md">
                <DialogTitle>
                  {isResettingPassword ? 'Reset Your Password' : 'Welcome Back'}
                </DialogTitle>
                <DialogDescription>
                  {isResettingPassword 
                    ? 'Enter your new password below to reset it.'
                    : 'Sign in to your account to continue.'}
                </DialogDescription>
                <div className="grid gap-6">
                  <div className="flex flex-col items-center gap-4 mb-4">
                    <img 
                      src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" 
                      alt="Snapshot Live Logo" 
                      className="h-12 object-contain" 
                      width={144} 
                      height={48}
                    />
                  </div>
                  <Auth {...getAuthConfig()} />
                </div>
              </DialogContent>
            </Dialog>
          </div>

          <div className={`${isMobileMenuOpen ? 'flex' : 'hidden'} md:hidden absolute top-full left-0 right-0 bg-white shadow-lg flex-col w-full z-50 border-t`}>
            <Button variant="ghost" className="w-full justify-start px-6 py-4 text-left hover:bg-gray-50" onClick={() => {
            navigate("/features");
            toggleMobileMenu();
          }}>
              Features
            </Button>
            <Button variant="ghost" className="w-full justify-start px-6 py-4 text-left hover:bg-gray-50" onClick={() => {
            navigate("/applications");
            toggleMobileMenu();
          }}>
              Applications
            </Button>
            <Dialog>
              <DialogTrigger asChild>
                <Button className="m-4">
                  Log In
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-md">
                <div className="grid gap-6">
                  <div className="flex flex-col items-center gap-4 mb-4">
                    <img 
                      src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" 
                      alt="Snapshot Live Logo" 
                      className="h-12 object-contain" 
                      width={144} 
                      height={48}
                    />
                    <h2 className="text-2xl font-semibold text-gray-900">Welcome Back</h2>
                  </div>
                  <Auth {...getAuthConfig()} />
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </nav>

      <header className="relative overflow-hidden px-6 lg:px-8">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:pt-20">
            <div className="mx-auto max-w-2xl text-center animate-fade-in">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-500 py-[5px]">Smart Monitoring 
Made Simple</h1>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Providing peace of mind because you can't be everywhere! Snapshot Live provides a small, palm-sized, AI-driven camera platform for operations and safety monitoring.
              </p>
              <div className="mt-10 flex items-center justify-center gap-x-6">
                <Dialog open={isFormDialogOpen} onOpenChange={handleFormDialogChange}>
                  <DialogTrigger asChild>
                    <Button size="lg" className="text-base">
                      Get Started
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
                    <TrialRequestForm onOpenChange={handleFormDialogChange} />
                  </DialogContent>
                </Dialog>
                <Button variant="outline" size="lg" className="text-base" onClick={() => navigate("/features")}>
                  Learn more
                </Button>
              </div>
            </div>
          </div>
        </div>
      </header>

      <AuthDialog 
        open={isAuthDialogOpen}
        onOpenChange={setIsAuthDialogOpen}
        isResettingPassword={isResettingPassword}
        isProcessingReset={isProcessingReset}
        email={email}
        setEmail={setEmail}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        onPasswordReset={handlePasswordReset}
      />

      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-24">
        <h2 className="text-3xl font-bold text-center mb-12">Application Examples</h2>
        <div ref={scrollRef} className="flex overflow-x-hidden gap-4 pb-4" style={{
        WebkitOverflowScrolling: 'touch',
        scrollBehavior: 'smooth'
      }}>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Flood%20Monitorin.png?updatedAt=1740474182812" alt="Flood monitoring" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Flood Monitoring
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Costal%20Erosion.png?updatedAt=1740474181956" alt="Coastal erosion" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Coastal Erosion
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Infrastructure%20Safety.png?updatedAt=1740474182160" alt="Infrastructure monitoring" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Infrastructure Safety
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Construction%20Monitoring.png?updatedAt=1740474185481" alt="Construction site monitoring" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Construction Monitoring
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Utility%20Monitoring.png?updatedAt=1740474182143" alt="Utility monitoring" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Utility Monitoring
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Refuse%20adnd%20Recycling.png?updatedAt=1740474182191" alt="Refuse and recycling" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Refuse and Recycling
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Perimiter%20Monitoring.png?updatedAt=1740474182495" alt="Perimeter monitoring" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Perimeter Monitoring
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Apiculture%20Monitoring.png?updatedAt=1740474185458" alt="Apiculture monitoring" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Apiculture Monitoring
            </div>
          </div>
          <div className="flex-none w-72 aspect-square relative overflow-hidden rounded-lg">
            <img src="https://ik.imagekit.io/SavySnap/Marketing/Website/Traffic%20Management.png?updatedAt=1740474186722" alt="Traffic management" className="object-cover w-full h-full hover:scale-105 transition-transform duration-300" width={288} height={288} loading="lazy" decoding="async" />
            <div className="absolute bottom-0 left-0 right-0 bg-black/60 text-white p-2 text-sm">
              Traffic Management
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-16 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Ready to get started?
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">Join us for a smarter way to manage your sites: comprehensive records, effortless audits, fewer visits, and lower costs. With timely alerts, you can focus on what matters most.</p>
            <div className="mt-10 flex items-center justify-center gap-4">
              <Dialog open={isFormDialogOpen} onOpenChange={handleFormDialogChange}>
                <DialogTrigger asChild>
                  <Button size="lg" className="bg-brand-600 hover:bg-brand-500 text-white">
                    Start Free Trial
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
                  <TrialRequestForm onOpenChange={handleFormDialogChange} />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-white border-t">
        <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-start md:justify-between lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-full">
            <div className="space-y-3">
              <img src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" alt="Snapshot Live Logo" className="h-12 self-start" width={144} height={48} loading="lazy" decoding="async" />
              <p className="text-sm text-gray-600">
                Snapshot Live Ltd<br />
                Registration: 16174031<br />
                Thurnby, Leicester, UK<br />
                Tel: +44 (0)1164 976866
              </p>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Company</h3>
              <div className="mt-6 space-y-4">
                <Button variant="link" onClick={() => navigate("/features")} className="block text-sm">
                  Features
                </Button>
                <Button variant="link" onClick={() => navigate("/applications")} className="block text-sm">
                  Applications
                </Button>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="link" className="block text-sm">
                      Contact Us
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
                    <TrialRequestForm onOpenChange={handleFormDialogChange} />
                  </DialogContent>
                </Dialog>
              </div>
            </div>

            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Legal</h3>
              <div className="mt-6 space-y-4">
                <Button variant="link" onClick={() => navigate("/terms")} className="block text-sm">
                  Terms of Use
                </Button>
                <Button variant="link" onClick={() => navigate("/equipment")} className="block text-sm">
                  Equipment Hire Terms
                </Button>
                <Button variant="link" onClick={() => navigate("/privacy")} className="block text-sm">
                  Privacy Policy
                </Button>
                <Button variant="link" onClick={() => navigate("/cookies")} className="block text-sm">
                  Cookie Policy
                </Button>
                <Button variant="link" onClick={() => navigate("/disclaimer")} className="block text-sm">
                  Disclaimer
                </Button>
              </div>
            </div>

            <div>
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Policies</h3>
              <div className="mt-6 space-y-4">
                <Button variant="link" onClick={() => navigate("/data-protection")} className="block text-sm">
                  Data Protection
                </Button>
                <Button variant="link" onClick={() => navigate("/bribery-policy")} className="block text-sm">
                  Anti-Corruption
                </Button>
                <Button variant="link" onClick={() => navigate("/modern-slavery")} className="block text-sm">
                  Modern Slavery
                </Button>
                <Button variant="link" onClick={() => navigate("/environmental")} className="block text-sm">
                  Environmental
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 pb-6">
          <p className="text-center text-xs leading-5 text-gray-500">
            © {new Date().getFullYear()} Snapshot Live Ltd. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Index;
