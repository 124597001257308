
import { Tables } from "@/integrations/supabase/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { GridLayout } from "../GridLayout";
import { Button } from "@/components/ui/button";
import { RefreshCw } from "lucide-react";
import { toast } from "sonner";

interface DeviceHistoryProps {
  device_id: string;
  initialData?: {
    device_name: string | null;
    device_description: string | null;
  } | null;
}

export const DeviceHistory = ({ device_id, initialData }: DeviceHistoryProps) => {
  const queryClient = useQueryClient();
  const { data: device } = useQuery({
    queryKey: ["device-details", device_id],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("device_details")
        .select("*")
        .eq("device_id", device_id)
        .single();

      if (error) throw error;
      return data as Tables<"device_details">;
    },
    initialData: initialData ? {
      device_id,
      device_name: initialData.device_name,
      device_description: initialData.device_description,
    } as Tables<"device_details"> : undefined
  });

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ["device-details", device_id] });
    queryClient.invalidateQueries({ queryKey: ["device", device_id] });
    toast.success("Refreshing device data...");
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          {device?.device_name || device_id}
        </h1>
        <Button
          onClick={handleRefresh}
          variant="outline"
          size="sm"
          className="gap-2 text-xs"
        >
          <RefreshCw className="h-3 w-3" />
          Refresh
        </Button>
      </div>
      <GridLayout 
        deviceId={device_id} 
        deviceName={device?.device_name || undefined}
        deviceDescription={device?.device_description || undefined}
      />
    </div>
  );
};

