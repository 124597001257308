
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const BriberyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Bribery & Anti-Corruption Policy</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">7.1 Policy Statement</h2>
          <p>We are committed to conducting business ethically and in compliance with all applicable anti-bribery and anti-corruption laws.</p>

          <h2 className="text-xl font-semibold mt-6">7.2 Scope</h2>
          <p>This policy applies to:</p>
          <ul>
            <li>All employees and contractors</li>
            <li>Business partners and suppliers</li>
            <li>Any third parties acting on our behalf</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">7.3 Prohibited Conduct</h2>
          <p>We strictly prohibit:</p>
          <ul>
            <li>Offering or accepting bribes</li>
            <li>Facilitation payments</li>
            <li>Improper gifts or hospitality</li>
            <li>Political contributions</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">7.4 Due Diligence</h2>
          <p>We conduct appropriate due diligence on:</p>
          <ul>
            <li>Business partners</li>
            <li>High-risk transactions</li>
            <li>New market entries</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">7.5 Contact Information</h2>
          <p>For questions about this policy or to report concerns:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default BriberyPolicy;
