import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { useState, useEffect } from "react";
import { toast } from "sonner";
import { Button } from "@/components/ui/button";
import { Bell, Mail, Camera, Save, AlertTriangle, Info, ShieldCheck } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Textarea } from "@/components/ui/textarea";
interface DeviceNotificationsProps {
  device: any;
  deviceId: string;
  onUpdate: () => Promise<void>;
}
export const DeviceNotifications = ({
  device,
  deviceId,
  onUpdate
}: DeviceNotificationsProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [emailValue, setEmailValue] = useState(device.notificationEmial);
  const [questionValue, setQuestionValue] = useState(device.notificationQuestion);
  const [notificationsEnabled, setNotificationsEnabled] = useState(device.enableNotifications === "true");
  const [surveillanceEnabled, setSurveillanceEnabled] = useState(device.enableSurveilance === "true");

  // Ensure surveillance is disabled when notifications are disabled
  useEffect(() => {
    if (!notificationsEnabled && surveillanceEnabled) {
      updateField("enableSurveilance", "false");
      setSurveillanceEnabled(false);
    }
  }, [notificationsEnabled]);
  const updateField = async (field: string, value: string) => {
    setIsUpdating(true);
    try {
      const params = new URLSearchParams();
      params.append("deviceID", deviceId);
      params.append("field", field);
      params.append("value", value);
      const response = await fetch("https://i56o7w.buildship.run/updateDevice", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: params
      });
      if (!response.ok) {
        throw new Error("Failed to update device");
      }
      await onUpdate();
      toast.success("Device updated successfully");
    } catch (error) {
      console.error("Error updating device:", error);
      toast.error("Failed to update device");
    } finally {
      setIsUpdating(false);
    }
  };
  const handleNotificationsChange = async (checked: boolean) => {
    setNotificationsEnabled(checked);
    await updateField("enableNotifications", checked ? "true" : "false");
  };
  const handleSurveillanceChange = async (checked: boolean) => {
    setSurveillanceEnabled(checked);
    await updateField("enableSurveilance", checked ? "true" : "false");
  };
  return <div className="space-y-6">
      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <Bell className="h-5 w-5" />
            <CardTitle className="text-base sm:text-lg">
              Notification Settings
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          <Alert>
            <AlertTriangle className="h-4 w-4" />
            <AlertDescription>
              Images will only be processed and analyzed when notifications are enabled.
            </AlertDescription>
          </Alert>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="enableNotifications" className="text-sm">
                  Enable Notifications
                </Label>
                <p className="text-xs text-muted-foreground">
                  Receive notifications when images are processed
                </p>
              </div>
              <Switch id="enableNotifications" checked={notificationsEnabled} onCheckedChange={handleNotificationsChange} disabled={isUpdating} />
            </div>
            
            <div className="flex items-center justify-between">
              <div className="space-y-0.5">
                <Label htmlFor="enableSurveillance" className="text-sm">
                  Enable Surveillance Mode
                </Label>
                <p className="text-xs text-muted-foreground">
                  Send an email alert for every new photo captured
                </p>
              </div>
              <Switch id="enableSurveillance" checked={surveillanceEnabled} onCheckedChange={handleSurveillanceChange} disabled={isUpdating || !notificationsEnabled} />
            </div>
          </div>

          <div className="space-y-4">
            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Mail className="h-4 w-4" />
                <Label htmlFor="notificationEmail" className="text-sm">
                  Notification Email
                </Label>
              </div>
              <div className="flex gap-2">
                <Input id="notificationEmail" type="email" value={emailValue} onChange={e => setEmailValue(e.target.value)} disabled={isUpdating} className="h-9" placeholder="Enter email address" />
                <Button size="sm" onClick={() => updateField("notificationEmial", emailValue)} disabled={isUpdating} className="h-9">
                  <Save className="h-4 w-4" />
                </Button>
              </div>
            </div>

            <div className="space-y-2">
              <div className="flex items-center gap-2">
                <Camera className="h-4 w-4" />
                <Label htmlFor="notificationQuestion" className="text-sm">AI Image Query</Label>
              </div>
              <div className="flex gap-2">
                <Textarea id="notificationQuestion" value={questionValue} onChange={e => setQuestionValue(e.target.value)} disabled={isUpdating} className="min-h-[120px] resize-none" placeholder="Enter custom message for alerts" />
                <Button size="sm" onClick={() => updateField("notificationQuestion", questionValue)} disabled={isUpdating}>
                  <Save className="h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <div className="flex items-center gap-2">
            <Info className="h-5 w-5" />
            <CardTitle className="text-base sm:text-lg">
              Current Notification Status
            </CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className="space-y-3 rounded-lg bg-muted p-4">
            <div className="space-y-1">
              <span className="text-xs font-medium">Result</span>
              <p className="text-sm">{device.notification.result}</p>
            </div>
            <div className="space-y-1">
              <span className="text-xs font-medium">Reason</span>
              <p className="text-sm">{device.notification.reason}</p>
            </div>
            <div className="space-y-1">
              <span className="text-xs font-medium">Message</span>
              <p className="text-sm">{device.notification.message}</p>
            </div>
            <div className="space-y-1">
              <span className="text-xs font-medium">Subject</span>
              <p className="text-sm">{device.notification.subject}</p>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card>
        <CardHeader className="flex flex-row items-center gap-2">
          <ShieldCheck className="h-5 w-5 text-brand-500" />
          <CardTitle className="text-base sm:text-lg">Google Vision SafeSearch Analysis</CardTitle>
        </CardHeader>
        <CardContent>
          <p className="text-sm text-muted-foreground mb-4">
            Automated content moderation results from Google Cloud Vision AI
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {Object.entries(device.safeSearch).map(([key, value]) => <div key={key} className="space-y-2">
                <h3 className="text-sm font-medium text-muted-foreground capitalize">
                  {key} Content
                </h3>
                <p className="text-sm capitalize">{String(value)}</p>
              </div>)}
          </div>
        </CardContent>
      </Card>
    </div>;
};