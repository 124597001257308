import { CookieBanner } from "./components/CookieBanner"
import { Toaster } from "./components/ui/toaster"
import { Toaster as Sonner } from "./components/ui/sonner"
import { TooltipProvider } from "./components/ui/tooltip"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { supabase } from "@/integrations/supabase/client"
import Index from "./pages/Index"
import Features from "./pages/Features"
import Applications from "./pages/Applications"
import Pricing from "./pages/Pricing"
import Dashboard from "./pages/Dashboard"
import Profile from "./pages/Profile"
import Map from "./pages/Map"
import Admin from "./pages/Admin"
import Gallery from "./pages/Gallery"
import Invite from "./pages/Invite"
import Terms from "./pages/Terms"
import Privacy from "./pages/Privacy"
import Cookies from "./pages/Cookies"
import Equipment from "./pages/Equipment"
import Disclaimer from "./pages/Disclaimer"
import DataProtection from "./pages/DataProtection"
import BriberyPolicy from "./pages/BriberyPolicy"
import ModernSlavery from "./pages/ModernSlavery"
import Environmental from "./pages/Environmental"
import UserRoles from "./pages/UserRoles"
import DeviceAssignments from "./pages/DeviceAssignments"
import DeviceDetails from "./pages/DeviceDetails"
import Documentation from "./pages/Documentation"

const queryClient = new QueryClient()

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setIsAuthenticated(!!session)
    })

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      setIsAuthenticated(!!session)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (isAuthenticated === null) {
    return null
  }

  return (
    <QueryClientProvider client={queryClient}>
      <TooltipProvider>
        <CookieBanner />
        <Toaster />
        <Sonner />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/features" element={<Features />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/cookies" element={<Cookies />} />
            <Route path="/equipment" element={<Equipment />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/data-protection" element={<DataProtection />} />
            <Route path="/bribery-policy" element={<BriberyPolicy />} />
            <Route path="/modern-slavery" element={<ModernSlavery />} />
            <Route path="/environmental" element={<Environmental />} />
            <Route path="/invite" element={<Invite />} />
            <Route
              path="/dashboard/*"
              element={
                isAuthenticated ? (
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/map" element={<Map />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/device/:deviceId" element={<Dashboard />} />
                    <Route path="/user-roles" element={<UserRoles />} />
                    <Route path="/device-details" element={<DeviceDetails />} />
                    <Route path="/device-assignments" element={<DeviceAssignments />} />
                    <Route path="/documentation" element={<Documentation />} />
                  </Routes>
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
  )
}

export default App
