import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { SimplifiedUserRole } from "@/types/user";
import { UserRoleRow } from "@/components/users/UserRoleRow";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useState } from "react";

export default function UserRoles() {
  const [selectedOrg, setSelectedOrg] = useState<string>("all");
  const [selectedRole, setSelectedRole] = useState<string>("all");

  const { data: userRoles, isLoading } = useQuery<SimplifiedUserRole[]>({
    queryKey: ["user-roles"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('user_roles')
        .select(`
          *,
          profiles (
            username
          ),
          organizations (
            name
          )
        `);

      if (error) throw error;
      return data || [];
    }
  });

  // Extract unique organizations and roles for filters
  const organizations = [...new Set(userRoles?.map(role => role.organizations?.name).filter(Boolean))];
  const roles = [...new Set(userRoles?.map(role => role.role))];

  // Filter the roles based on selected filters
  const filteredRoles = userRoles?.filter(role => {
    const matchesOrg = selectedOrg === "all" || role.organizations?.name === selectedOrg;
    const matchesRole = selectedRole === "all" || role.role === selectedRole;
    return matchesOrg && matchesRole;
  });

  if (isLoading) {
    return (
      <div className="p-4 flex items-center justify-center">
        <div className="animate-spin h-8 w-8 border-4 border-blue-500 border-t-transparent rounded-full"></div>
      </div>
    );
  }

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full">
        <AppSidebar />
        <main className="flex-1">
          <div className="p-8 space-y-8">
            <h1 className="text-2xl font-bold">User Roles Management</h1>
            
            <div className="mb-4 flex gap-4">
              <Select value={selectedOrg} onValueChange={setSelectedOrg}>
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="Filter by organization" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Organizations</SelectItem>
                  {organizations.map((org) => (
                    <SelectItem key={org} value={org || ''}>
                      {org}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Select value={selectedRole} onValueChange={setSelectedRole}>
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder="Filter by role" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="all">All Roles</SelectItem>
                  {roles.map((role) => (
                    <SelectItem key={role} value={role}>
                      {role}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            
            <div className="bg-white rounded-lg shadow">
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>User ID</TableHead>
                      <TableHead>Username</TableHead>
                      <TableHead>Organization</TableHead>
                      <TableHead>Role</TableHead>
                      <TableHead>Actions</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredRoles?.map((role) => (
                      <UserRoleRow
                        key={role.id}
                        role={role}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
}
