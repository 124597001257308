
import { SidebarProvider } from "@/components/ui/sidebar";
import { AppSidebar } from "@/components/AppSidebar";
import { useSuperuserGuard } from "@/hooks/use-superuser-guard";
import { AddDeviceSection } from "@/components/devices/AddDeviceSection";
import { DeviceDetailsTable } from "@/components/devices/DeviceDetailsTable";

const DeviceDetails = () => {
  // This hook will redirect non-superusers away from this page
  useSuperuserGuard();

  const handleDeviceAdded = () => {
    // Refresh data if needed
  };

  return (
    <SidebarProvider>
      <div className="flex min-h-screen w-full">
        <AppSidebar />
        <main className="flex-1">
          <div className="p-8 space-y-8">
            <h1 className="text-2xl font-bold">Device Details</h1>
            <AddDeviceSection onDeviceAdded={handleDeviceAdded} />
            <DeviceDetailsTable />
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default DeviceDetails;
