
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

export const useSuperuserGuard = () => {
  const navigate = useNavigate();
  
  const { data: role, isLoading } = useQuery({
    queryKey: ["user-role"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return null;

      const { data: role } = await supabase.rpc('get_user_role', {
        user_id: session.user.id
      });

      return role;
    }
  });

  useEffect(() => {
    if (!isLoading && role !== 'superuser') {
      navigate('/dashboard');
    }
  }, [role, isLoading, navigate]);

  return { isLoading, isSuperuser: role === 'superuser' };
};
