
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const Privacy = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">3.1 Introduction</h2>
          <p>Your privacy is important to us. This Privacy Policy describes how Snapshot Live Ltd collects, uses, and protects any personal information you provide when using our Website or when hiring our Equipment. It also outlines how we handle any data or imagery captured using the Equipment.</p>

          <h2 className="text-xl font-semibold mt-6">3.2 Data We Collect</h2>
          <p>3.2.1 We may collect personal information, including your name, contact details, address, payment information, and details relevant to your booking when you:</p>
          <ul>
            <li>Create an account or make a booking on our Website.</li>
            <li>Subscribe to our newsletters or updates.</li>
            <li>Contact us for support or enquiries.</li>
          </ul>
          <p>3.2.2 We may also collect non-personal data (e.g., browser type, device information) to improve our services.</p>
          <p>3.2.3 If you utilise our Equipment to record or stream images (including via SIM data and platform connectivity), Snapshot Live Ltd will generally not process or store your content unless it is specifically provided to us for support or troubleshooting.</p>

          {/* ... Additional sections ... */}
          <h2 className="text-xl font-semibold mt-6">3.3 How We Use Your Data</h2>
          <p>We use your data to:</p>
          <ul>
            <li>Process your orders and manage your hire agreements.</li>
            <li>Communicate with you regarding bookings, enquiries, or technical support.</li>
            <li>Improve our Website, services, and customer experience.</li>
            <li>Comply with legal or regulatory obligations.</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">3.4 Data Sharing</h2>
          <p>3.4.1 We may share your personal data with third-party service providers (e.g., payment processors, couriers, logistics providers) to facilitate our services.</p>
          <p>3.4.2 We will not sell, trade, or lease your personal data to third parties without your explicit consent, unless required by law.</p>

          <h2 className="text-xl font-semibold mt-6">3.5 Data Storage and Security</h2>
          <p>3.5.1 We employ appropriate technical and organisational security measures to protect your data from unauthorised access or disclosure.</p>
          <p>3.5.2 We will retain your data only for as long as necessary to fulfil the purposes outlined in this Privacy Policy or as required by law.</p>

          <h2 className="text-xl font-semibold mt-6">3.6 Your Rights</h2>
          <p>Subject to applicable data protection laws in the UK, you have the right to:</p>
          <ul>
            <li>Request access to your personal data.</li>
            <li>Ask us to rectify or erase your personal data.</li>
            <li>Object to certain data processing activities or request a restriction on processing.</li>
            <li>Withdraw consent (where processing is based on consent).</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">3.7 Contact Us</h2>
          <p>If you have any questions about this Privacy Policy or wish to exercise your rights, please contact us at:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
