
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { syncDeviceDetails } from "@/utils/deviceSync";

const SUPABASE_URL = import.meta.env.VITE_SUPABASE_URL || "https://ndqudoynysmynztrepwj.supabase.co";

export const useDeviceDetails = (deviceId: string | undefined) => {
  const queryClient = useQueryClient();

  const { data: device, isLoading, error } = useQuery({
    queryKey: ["device-details", deviceId],
    queryFn: async () => {
      if (!deviceId) {
        throw new Error("No device ID provided");
      }

      console.log("Fetching device details for:", deviceId);
      const params = new URLSearchParams();
      params.append("deviceID", deviceId);

      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) {
        throw new Error("No session found");
      }

      const response = await fetch(`${SUPABASE_URL}/functions/v1/deviceDetails`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
        },
        body: params,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch device details");
      }

      const data = await response.json();
      console.log("Device details response:", data);
      
      // Sync the API response with our database
      await syncDeviceDetails(deviceId, data);
      
      return data;
    },
    enabled: !!deviceId, // Only run the query if deviceId is provided
    staleTime: Infinity, // Only fetch once until manually refreshed
  });

  const updateDevice = async (field: string, value: string | number): Promise<void> => {
    if (!deviceId) {
      toast.error("No device ID provided");
      return;
    }

    try {
      console.log("Updating device:", deviceId, field, value);
      const params = new URLSearchParams();
      params.append("deviceID", deviceId);
      params.append("field", field);
      params.append("value", value.toString());

      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session?.access_token) {
        throw new Error("No session found");
      }

      const response = await fetch(`${SUPABASE_URL}/functions/v1/updateDevice`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${session.access_token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: params,
      });

      if (!response.ok) {
        throw new Error("Failed to update device");
      }

      const updatedData = await response.json();
      console.log("Update response:", updatedData);

      // Sync the updated data with our database
      await syncDeviceDetails(deviceId, updatedData);

      // Invalidate and refetch queries
      await queryClient.invalidateQueries({ queryKey: ["device-details", deviceId] });
      await queryClient.invalidateQueries({ queryKey: ["device", deviceId] });

      toast.success("Device updated successfully");
    } catch (error) {
      console.error("Error updating device:", error);
      toast.error("Failed to update device");
      throw error;
    }
  };

  const refreshDevice = async (): Promise<void> => {
    if (!deviceId) {
      toast.error("No device ID provided");
      return;
    }

    await queryClient.invalidateQueries({ queryKey: ["device-details", deviceId] });
    await queryClient.invalidateQueries({ queryKey: ["device", deviceId] });
  };

  return {
    device,
    isLoading,
    error,
    updateDevice,
    refreshDevice
  };
};
