
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const DataProtection = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Data Protection Policy</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">6.1 Our Commitment</h2>
          <p>We are committed to protecting your personal data and ensuring compliance with data protection laws.</p>

          <h2 className="text-xl font-semibold mt-6">6.2 Data Collection</h2>
          <p>6.2.1 We collect:</p>
          <ul>
            <li>Personal identification information</li>
            <li>Contact information</li>
            <li>Technical data</li>
            <li>Usage data</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">6.3 Data Processing</h2>
          <p>We process personal data:</p>
          <ul>
            <li>With explicit consent</li>
            <li>To fulfill contractual obligations</li>
            <li>To comply with legal requirements</li>
            <li>For legitimate business interests</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">6.4 Data Security</h2>
          <p>We implement appropriate measures to protect data:</p>
          <ul>
            <li>Encryption of sensitive data</li>
            <li>Regular security assessments</li>
            <li>Staff training on data protection</li>
            <li>Access controls and monitoring</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">6.5 Contact Information</h2>
          <p>For data protection inquiries, contact our Data Protection Officer:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default DataProtection;
