import { SidebarProvider } from "@/components/ui/sidebar";
import Map, { Marker, NavigationControl, Popup } from 'react-map-gl';
import { AppSidebar } from "@/components/AppSidebar";
import { RefreshCw, Layers, Camera } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useIsMobile } from "@/hooks/use-mobile";
import { DeviceMapMarker } from "@/components/devices/DeviceMapMarker";
import 'mapbox-gl/dist/mapbox-gl.css';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface DeviceAssignment {
  id: string;
  device_id: string;
  user_id: string;
  device_details?: {
    device_name: string | null;
    device_description: string | null;
    lat_wgs84: number | null;
    long_wgs84: number | null;
    imagekit?: {
      thumbnailUrl?: string;
    } | null;
  } | null;
}

const MapPage = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const queryClient = useQueryClient();
  const isMobile = useIsMobile();
  const [selectedDevice, setSelectedDevice] = useState<DeviceAssignment | null>(null);
  const [mapboxToken, setMapboxToken] = useState<string | null>(null);
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v12');

  const mapStyles = [
    { name: 'Streets', value: 'mapbox://styles/mapbox/streets-v12' },
    { name: 'Satellite', value: 'mapbox://styles/mapbox/satellite-v9' },
    { name: 'Satellite Streets', value: 'mapbox://styles/mapbox/satellite-streets-v12' },
    { name: 'Light', value: 'mapbox://styles/mapbox/light-v11' },
    { name: 'Dark', value: 'mapbox://styles/mapbox/dark-v11' },
    { name: 'Navigation Day', value: 'mapbox://styles/mapbox/navigation-day-v1' },
    { name: 'Navigation Night', value: 'mapbox://styles/mapbox/navigation-night-v1' },
    { name: 'Outdoors', value: 'mapbox://styles/mapbox/outdoors-v12' }
  ];

  useEffect(() => {
    const fetchMapboxToken = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/getMapboxToken`, {
          headers: {
            Authorization: `Bearer ${session.access_token}`,
          },
        });

        if (!response.ok) throw new Error('Failed to fetch Mapbox token');
        
        const { token } = await response.json();
        setMapboxToken(token);
      } catch (error) {
        console.error('Error fetching Mapbox token:', error);
        toast.error('Error loading map configuration');
      }
    };

    fetchMapboxToken();
  }, []);

  const { data: assignments, isLoading, error, refetch } = useQuery({
    queryKey: ["device-assignments-with-names"],
    queryFn: async () => {
      const { data: session } = await supabase.auth.getSession();
      
      if (!session?.session) {
        navigate("/");
        throw new Error("No session");
      }

      const { data, error } = await supabase
        .from('device_assignments')
        .select(`
          *,
          device_details:device_details!device_assignments_device_id_fkey(
            device_name,
            device_description,
            lat_wgs84,
            long_wgs84,
            imagekit
          )
        `)
        .eq('user_id', session.session.user.id);
      
      if (error) {
        console.error("Error fetching assignments:", error);
        throw error;
      }
      
      console.log("Fetched assignments:", data);
      return data as DeviceAssignment[];
    },
    staleTime: Infinity,
    retry: 1,
  });

  const devicesWithLocation = assignments?.filter(assignment => {
    const lat = assignment.device_details?.lat_wgs84;
    const long = assignment.device_details?.long_wgs84;
    
    const isValidLat = lat !== null && !isNaN(lat) && lat >= -90 && lat <= 90;
    const isValidLong = long !== null && !isNaN(long) && long >= -180 && long <= 180;
    
    console.log("Device coordinate validation:", {
      deviceId: assignment.device_id,
      lat,
      long,
      isValidLat,
      isValidLong
    });
    
    return isValidLat && isValidLong;
  }) || [];

  const initialViewState = devicesWithLocation.length > 0
    ? {
        latitude: devicesWithLocation.reduce((sum, device) => 
          sum + (device.device_details?.lat_wgs84 || 0), 0) / devicesWithLocation.length,
        longitude: devicesWithLocation.reduce((sum, device) => 
          sum + (device.device_details?.long_wgs84 || 0), 0) / devicesWithLocation.length,
        zoom: 8
      }
    : {
        latitude: 51.5074,
        longitude: -0.1278,
        zoom: 8
      };

  useEffect(() => {
    const loadData = async () => {
      await refetch();
      if (assignments) {
        assignments.forEach((assignment) => {
          queryClient.invalidateQueries({ queryKey: ["device-details", assignment.device_id] });
          queryClient.invalidateQueries({ queryKey: ["device", assignment.device_id] });
        });
      }
    };

    loadData();
  }, [assignments, queryClient, refetch]);

  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: ["device-assignments"] });
    assignments?.forEach((assignment) => {
      queryClient.invalidateQueries({ queryKey: ["device-details", assignment.device_id] });
      queryClient.invalidateQueries({ queryKey: ["device", assignment.device_id] });
    });
    toast.success("Refreshing device data...");
  };

  return (
    <SidebarProvider defaultOpen={isOpen} onOpenChange={setIsOpen}>
      <div className="flex min-h-screen w-full" style={{ "--sidebar-width": "14rem", "--sidebar-width-icon": "4rem" } as React.CSSProperties}>
        <AppSidebar />
        <main className="flex-1">
          <div className="w-full p-4">
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-bold">Map</h1>
              <div className="flex flex-col items-end gap-2">
                <img 
                  src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" 
                  alt="Snapshot Live Logo" 
                  className="h-12 object-contain"
                />
                <div className="flex gap-2">
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="outline"
                        size="sm"
                        className="gap-2 text-xs"
                      >
                        <Layers className="h-3 w-3" />
                        Map Style
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[200px]">
                      {mapStyles.map((style) => (
                        <DropdownMenuItem
                          key={style.value}
                          className="cursor-pointer"
                          onClick={() => setMapStyle(style.value)}
                        >
                          {style.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                  <Button
                    onClick={handleRefresh}
                    variant="outline"
                    size="sm"
                    className="gap-2 text-xs"
                  >
                    <RefreshCw className="h-3 w-3" />
                    Refresh
                  </Button>
                </div>
              </div>
            </div>
            {error && (
              <div className="text-sm text-red-500">
                Error loading devices. Please try refreshing the page.
              </div>
            )}
            {isLoading && <div className="text-sm">Loading devices...</div>}
            
            <div className="w-full h-[calc(100vh-12rem)] rounded-lg overflow-hidden border relative">
              {mapboxToken ? (
                <Map
                  mapboxAccessToken={mapboxToken}
                  initialViewState={initialViewState}
                  style={{ width: "100%", height: "100%" }}
                  mapStyle={mapStyle}
                  reuseMaps
                >
                  <NavigationControl position="top-right" />
                  
                  {devicesWithLocation.map((device) => {
                    const lat = device.device_details?.lat_wgs84;
                    const long = device.device_details?.long_wgs84;
                    const thumbnailUrl = device.device_details?.imagekit?.thumbnailUrl;
                    
                    if (!lat || !long) return null;
                    
                    return (
                      <Marker
                        key={device.device_id}
                        latitude={lat}
                        longitude={long}
                        onClick={(e) => {
                          e.originalEvent.stopPropagation();
                          setSelectedDevice(device);
                        }}
                      >
                        <div className="cursor-pointer w-12 h-12 rounded-full overflow-hidden border-2 border-white shadow-lg hover:scale-110 transition-transform">
                          {thumbnailUrl ? (
                            <img
                              src={thumbnailUrl}
                              alt={device.device_details?.device_name || "Device"}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full bg-brand-500 flex items-center justify-center">
                              <Camera className="h-6 w-6 text-white" />
                            </div>
                          )}
                        </div>
                      </Marker>
                    );
                  })}

                  {selectedDevice && selectedDevice.device_details?.lat_wgs84 && selectedDevice.device_details?.long_wgs84 && (
                    <Popup
                      latitude={selectedDevice.device_details.lat_wgs84}
                      longitude={selectedDevice.device_details.long_wgs84}
                      closeOnClick={false}
                      onClose={() => setSelectedDevice(null)}
                      offset={[0, -10]}
                    >
                      <DeviceMapMarker
                        id={selectedDevice.device_id}
                        name={selectedDevice.device_details?.device_name || selectedDevice.device_id}
                        description={selectedDevice.device_details?.device_description || undefined}
                        thumbnailUrl={selectedDevice.device_details?.imagekit?.thumbnailUrl}
                      />
                    </Popup>
                  )}
                </Map>
              ) : (
                <div className="w-full h-full flex items-center justify-center">
                  <p className="text-sm text-muted-foreground">Loading map...</p>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </SidebarProvider>
  );
};

export default MapPage;
