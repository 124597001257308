
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, ArrowRight, Download } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import TrialRequestForm from "@/components/TrialRequestForm";

const Applications = () => {
  const navigate = useNavigate();
  const applications = [{
    title: "Construction",
    description: "Monitor construction sites and ensure project safety with real-time tracking and alerts.",
    benefits: ["Site monitoring", "Equipment tracking", "Safety compliance", "Progress tracking"],
    image: "https://ik.imagekit.io/SavySnap/Marketing/Website/Construction.png?updatedAt=1740474529120",
    downloadLink: "https://ik.imagekit.io/SavySnap/Marketing/SSL%20Intro%20Safety%20V3.pdf"
  }, {
    title: "Manufacturing",
    description: "Keep production lines running smoothly with comprehensive machine monitoring and maintenance.",
    benefits: ["Production monitoring", "Predictive maintenance", "Health and Safety monitoring", "Resource management"],
    image: "https://ik.imagekit.io/SavySnap/Marketing/Website/Manufacturing.png?updatedAt=1740474528223",
    downloadLink: "https://ik.imagekit.io/SavySnap/Marketing/SSL%20Intro%20Safety%20V3.pdf"
  }, {
    title: "Infrastructure",
    description: "Monitor and maintain critical infrastructure with connected device monitoring solutions.",
    benefits: ["Asset monitoring", "Predictive maintenance", "Security and perimeter monitoring", "Public safety monitoring"],
    image: "https://ik.imagekit.io/SavySnap/Marketing/Website/Infrastructure.png?updatedAt=1740474528619",
    downloadLink: "https://ik.imagekit.io/SavySnap/Marketing/SSL%20Intro%20Sites%20V3.pdf"
  }, {
    title: "Agriculture",
    description: "Optimize farming operations with IoT device management and environmental monitoring.",
    benefits: ["Crop monitoring", "Resource management", "Weather tracking", "Perimeter security"],
    image: "https://ik.imagekit.io/SavySnap/Marketing/Website/Agriculture.png?updatedAt=1740474528580",
    downloadLink: "https://ik.imagekit.io/SavySnap/Marketing/SSL%20Intro%20Sites%20V3.pdf"
  }, {
    title: "Events Management",
    description: "Enhance event security and operations with real-time monitoring and crowd management solutions.",
    benefits: ["Crowd monitoring", "Security surveillance", "Public safety monitoring", "Resource coordination"],
    image: "https://ik.imagekit.io/SavySnap/Marketing/Website/Event%20Management.png?updatedAt=1740474528597"
  }, {
    title: "Destination Marketing",
    description: "Showcase scenic locations and attractions with high-quality visual content and monitoring solutions.",
    benefits: ["Location advertising", "Attraction monitoring", "Visitor analytics", "Content management"],
    image: "https://ik.imagekit.io/SavySnap/Marketing/Website/Destination%20Marketing.png?updatedAt=1740474529119"
  }];

  return <div className="min-h-screen bg-gradient-to-b from-brand-50 to-brand-100">
      <div className="px-6 py-4">
        <Button variant="ghost" className="flex items-center gap-2" onClick={() => navigate("/")}>
          <ArrowLeft className="h-4 w-4" />
          Back to Home
        </Button>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-10 pb-16 sm:pb-24">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-brand-600 to-brand-500 sm:text-4xl py-[5px]">Our solution offers a fresh approach 
to monitoring, for any environment</h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">Our platform can be adapted and customised to various application, providing you with a tailored solution for your specific needs.</p>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 pb-24">
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:gap-12">
          {applications.map(app => <Card key={app.title} className="group overflow-hidden bg-white/80 backdrop-blur-sm hover:shadow-lg transition-shadow duration-300">
              <div className="aspect-video w-full overflow-hidden">
                <img src={app.image} alt={`${app.title} application`} className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300" />
              </div>
              <CardHeader>
                <CardTitle className="text-2xl font-bold text-gray-900">
                  {app.title}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-gray-600 mb-6">
                  {app.description}
                </p>
                <div className="space-y-3">
                  {app.benefits.map(benefit => <div key={benefit} className="flex items-center gap-3">
                      <div className="h-2 w-2 rounded-full bg-brand-600" />
                      <span className="text-sm text-gray-600">{benefit}</span>
                    </div>)}
                </div>
              </CardContent>
              {app.downloadLink && <CardFooter>
                  <Button variant="outline" className="w-full flex items-center gap-2 text-brand-600 border-brand-600 hover:bg-brand-50" onClick={() => window.open(app.downloadLink, '_blank')}>
                    <Download className="h-4 w-4" />
                    Download PDF
                  </Button>
                </CardFooter>}
            </Card>)}
        </div>
      </div>

      <div className="bg-white py-16 sm:py-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Ready to get started?
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">Join us for a smarter way to manage your sites: comprehensive records, effortless audits, fewer visits, and lower costs. With timely alerts, you can focus on what matters most.</p>
            <div className="mt-10 flex items-center justify-center gap-4">
              <Dialog>
                <DialogTrigger asChild>
                  <Button size="lg" className="bg-brand-600 hover:bg-brand-500 text-white">
                    Start Free Trial
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-2xl max-h-[90vh] overflow-y-auto">
                  <TrialRequestForm />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>;
};

export default Applications;
