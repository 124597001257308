import { SidebarProvider } from "@/components/ui/sidebar";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Camera, Plus, Trash2, User } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "sonner";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QRScanner } from "@/components/devices/QRScanner";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Checkbox } from "@/components/ui/checkbox";
import { AppSidebar } from "@/components/AppSidebar";

const Admin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isOpen, setIsOpen] = useState(true);
  const [organizationName, setOrganizationName] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [existingInvitation, setExistingInvitation] = useState<any>(null);
  const [deviceId, setDeviceId] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [deviceToRemove, setDeviceToRemove] = useState<string | null>(null);

  const { data: userRole, isLoading: isLoadingRole } = useQuery({
    queryKey: ["user-role"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate("/");
        return null;
      }

      try {
        const { data: userRoles, error } = await supabase
          .from('user_roles')
          .select('role')
          .eq('user_id', session.user.id)
          .maybeSingle();

        if (error) throw error;
        return userRoles?.role || null;
      } catch (error) {
        console.error("Error fetching user role:", error);
        toast.error("Failed to verify admin status");
        return null;
      }
    }
  });

  const { data: organization, isLoading: isLoadingOrg } = useQuery({
    queryKey: ["organization"],
    queryFn: async () => {
      console.log("Fetching organization data...");
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return null;

      try {
        const { data: userRoles, error: rolesError } = await supabase
          .from('user_roles')
          .select('organization_id')
          .eq('user_id', session.user.id)
          .maybeSingle();

        if (rolesError) throw rolesError;

        if (!userRoles?.organization_id) {
          console.log("No organization found for user");
          return null;
        }

        const { data: org, error: orgError } = await supabase
          .from('organizations')
          .select('*')
          .eq('id', userRoles.organization_id)
          .maybeSingle();

        if (orgError) throw orgError;

        console.log("Organization data fetched:", org);
        return org;
      } catch (error) {
        console.error("Error fetching organization:", error);
        toast.error("Failed to load organization data");
        return null;
      }
    },
    enabled: !!userRole
  });

  const { data: organizationUsers, isLoading: isLoadingUsers } = useQuery({
    queryKey: ["organization-users", organization?.id],
    queryFn: async () => {
      if (!organization?.id) {
        console.log("No organization ID found, skipping user fetch");
        return [];
      }

      try {
        console.log("Fetching organization users for org ID:", organization.id);
        const { data, error } = await supabase
          .from('user_roles')
          .select(`
            user_id,
            role,
            profiles!user_roles_user_id_profile_fk (
              username,
              avatar_url
            )
          `)
          .eq('organization_id', organization.id);

        if (error) throw error;
        
        console.log("Raw organization users data:", data);
        console.log("Number of users found:", data?.length || 0);
        
        data?.forEach((user, index) => {
          console.log(`User ${index + 1}:`, {
            userId: user.user_id,
            role: user.role,
            username: user.profiles?.username,
            hasAvatar: !!user.profiles?.avatar_url
          });
        });

        return data || [];
      } catch (error) {
        console.error("Error fetching organization users:", error);
        toast.error("Failed to load organization users");
        return [];
      }
    },
    enabled: !!organization?.id
  });

  const { data: organizationDevices, isLoading: isLoadingOrgDevices } = useQuery({
    queryKey: ["organization-devices", organization?.id],
    queryFn: async () => {
      if (!organization?.id) return [];

      try {
        const { data: devices, error } = await supabase
          .from('device_details')
          .select('*');

        if (error) throw error;
        return devices || [];
      } catch (error) {
        console.error("Error fetching organization devices:", error);
        toast.error("Failed to load organization devices");
        return [];
      }
    },
    enabled: !!organization?.id
  });

  const { data: userDeviceAssignments, isLoading: isLoadingAssignments } = useQuery({
    queryKey: ["user-device-assignments"],
    queryFn: async () => {
      try {
        const { data, error } = await supabase
          .from('device_assignments')
          .select('device_id, user_id');

        if (error) throw error;
        return data || [];
      } catch (error) {
        console.error("Error fetching device assignments:", error);
        toast.error("Failed to load device assignments");
        return [];
      }
    }
  });

  const { data: assignedDevices, isLoading: isLoadingAssignedDevices } = useQuery({
    queryKey: ["admin-devices"],
    queryFn: async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return [];

      try {
        const { data: assignments, error } = await supabase
          .from('device_assignments')
          .select(`
            device_id,
            device_details:device_details!device_assignments_device_id_fkey (
              device_name,
              device_description
            )
          `)
          .eq('user_id', session.user.id);

        if (error) throw error;
        return assignments || [];
      } catch (error) {
        console.error("Error fetching assigned devices:", error);
        toast.error("Failed to load assigned devices");
        return [];
      }
    }
  });

  useEffect(() => {
    if (organization?.name) {
      setOrganizationName(organization.name);
    }
  }, [organization]);

  useEffect(() => {
    if (userRole && userRole !== 'admin') {
      navigate('/dashboard');
    }
  }, [userRole, navigate]);

  const updateOrganization = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast.error("You must be logged in");
        return;
      }

      if (!organizationName.trim()) {
        toast.error("Organization name cannot be empty");
        return;
      }

      if (!organization) {
        const { data: newOrg, error: createError } = await supabase
          .from('organizations')
          .insert([{ name: organizationName }])
          .select()
          .single();

        if (createError) throw createError;

        const { error: roleError } = await supabase
          .from('user_roles')
          .insert([{
            user_id: session.user.id,
            organization_id: newOrg.id,
            role: 'admin'
          }]);

        if (roleError) throw roleError;

        toast.success("Organization created successfully");
      } else {
        const { error: updateError } = await supabase
          .from('organizations')
          .update({ name: organizationName })
          .eq('id', organization.id);

        if (updateError) throw updateError;

        toast.success("Organization updated successfully");
      }

      queryClient.invalidateQueries({ queryKey: ["organization"] });
    } catch (error) {
      console.error("Error in updateOrganization:", error);
      toast.error("An unexpected error occurred");
    }
  };

  const inviteUser = async (force: boolean = false) => {
    if (!organization || !inviteEmail) {
      toast.error("Please provide an email address");
      return;
    }

    try {
      const { data: { session } } = await supabase.auth.getSession();

      if (!force) {
        const { data: existingInvitation, error: checkError } = await supabase
          .from('user_invitations')
          .select('id, status')
          .eq('email', inviteEmail)
          .eq('organization_id', organization.id)
          .maybeSingle();

        if (checkError) throw checkError;

        if (existingInvitation) {
          setExistingInvitation(existingInvitation);
          if (existingInvitation.status === 'pending') {
            toast.error("This email has already been invited and the invitation is pending", {
              action: {
                label: "Apply Anyway",
                onClick: () => inviteUser(true)
              }
            });
            return;
          } else if (existingInvitation.status === 'accepted' && !force) {
            toast.error("This email has already been invited and the invitation was accepted", {
              action: {
                label: "Apply Anyway",
                onClick: () => inviteUser(true)
              }
            });
            return;
          }
        }
      }

      const { error } = await supabase
        .from('user_invitations')
        .upsert([{
          organization_id: organization.id,
          email: inviteEmail,
          device_ids: selectedDevices,
          invited_by: session?.user.id,
          status: 'pending'
        }], {
          onConflict: 'email,organization_id'
        });

      if (error) throw error;

      const inviteLink = `${window.location.origin}/invite?email=${encodeURIComponent(inviteEmail)}&org=${organization.id}`;

      const { error: emailError } = await supabase.functions.invoke('send-invitation', {
        body: {
          email: inviteEmail,
          organizationName: organization.name,
          inviteLink: inviteLink
        },
      });

      if (emailError) throw emailError;

      setInviteEmail("");
      setSelectedDevices([]);
      setExistingInvitation(null);
      toast.success("Invitation sent successfully");
    } catch (error: any) {
      console.error("Error inviting user:", error);
      toast.error("Failed to send invitation");
    }
  };

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
      toast.success("Logged out successfully");
    } catch (error) {
      toast.error("Error logging out");
      console.error("Error:", error);
    }
  };

  const handleDeviceRemoval = async (deviceId: string) => {
    try {
      const { error } = await supabase
        .from('device_assignments')
        .delete()
        .eq('device_id', deviceId);

      if (error) throw error;

      toast.success("Device removed successfully");
      queryClient.invalidateQueries({ queryKey: ["assigned-devices"] });
      setDeviceToRemove(null);
    } catch (error) {
      console.error("Error removing device:", error);
      toast.error("Failed to remove device");
    }
  };

  const handleDeviceAssignment = async () => {
    if (!deviceId.trim()) {
      toast.error("Please enter a device ID");
      return;
    }

    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast.error("You must be logged in");
        return;
      }

      const { data: deviceExists, error: deviceCheckError } = await supabase
        .from('device_details')
        .select('device_id')
        .eq('device_id', deviceId)
        .single();

      if (deviceCheckError || !deviceExists) {
        toast.error("Device ID not found");
        return;
      }

      const { data: existingAssignment, error: assignmentCheckError } = await supabase
        .from('device_assignments')
        .select('id')
        .eq('device_id', deviceId)
        .single();

      if (existingAssignment) {
        toast.error("Device is already assigned to a user");
        return;
      }

      const { error: assignmentError } = await supabase
        .from('device_assignments')
        .insert([{
          device_id: deviceId,
          user_id: session.user.id
        }]);

      if (assignmentError) throw assignmentError;

      toast.success("Device assigned successfully");
      setDeviceId("");
      queryClient.invalidateQueries({ queryKey: ["assigned-devices"] });
    } catch (error) {
      console.error("Error assigning device:", error);
      toast.error("Failed to assign device");
    }
  };

  const handleQRScan = (result: string) => {
    setDeviceId(result);
    setShowScanner(false);
    toast.success("QR code scanned successfully");
  };

  const handleDeviceToggle = async (userId: string, deviceId: string, isAssigned: boolean) => {
    try {
      if (isAssigned) {
        const { error } = await supabase
          .from('device_assignments')
          .delete()
          .eq('user_id', userId)
          .eq('device_id', deviceId);

        if (error) throw error;
        toast.success("Device access removed");
      } else {
        const { error } = await supabase
          .from('device_assignments')
          .insert([{
            user_id: userId,
            device_id: deviceId
          }]);

        if (error) throw error;
        toast.success("Device access granted");
      }

      queryClient.invalidateQueries({ queryKey: ["user-device-assignments"] });
    } catch (error) {
      console.error("Error toggling device assignment:", error);
      toast.error("Failed to update device access");
    }
  };

  const isDeviceAssigned = (userId: string, deviceId: string) => {
    return userDeviceAssignments?.some(
      assignment => assignment.user_id === userId && assignment.device_id === deviceId
    ) || false;
  };

  if (isLoadingRole || !userRole) {
    return null;
  }

  return (
    <SidebarProvider defaultOpen={isOpen} onOpenChange={setIsOpen}>
      <div className="flex min-h-screen w-full" style={{ "--sidebar-width": "14rem", "--sidebar-width-icon": "4rem" } as React.CSSProperties}>
        <AppSidebar />
        <main className="flex-1">
          <div className="container mx-auto p-6 space-y-8">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold">Admin Dashboard</h1>
              <img 
                src="https://ik.imagekit.io/SavySnap/Marketing/SSL-Logo.jpg" 
                alt="Snapshot Live Logo" 
                className="h-12 object-contain"
              />
            </div>

            {isLoadingOrg ? (
              <div className="flex items-center justify-center p-8">
                <p className="text-muted-foreground">Loading organization details...</p>
              </div>
            ) : (
              <Card className="p-6">
                <h2 className="text-lg font-semibold mb-4">Organisation Management</h2>
                <div className="space-y-4">
                  <div>
                    <label className="text-sm font-medium mb-2 block">
                      Organisation Name
                    </label>
                    <div className="flex gap-2">
                      <Input
                        value={organizationName}
                        onChange={(e) => setOrganizationName(e.target.value)}
                        placeholder="Enter organization name"
                      />
                      <Button onClick={updateOrganization}>
                        {organization ? "Update" : "Create"}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            )}

            <Card className="p-6">
              <h2 className="text-lg font-semibold mb-4">Add Device</h2>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium mb-2 block">
                    Device ID
                  </label>
                  <div className="flex gap-2">
                    <Input
                      value={deviceId}
                      onChange={(e) => setDeviceId(e.target.value)}
                      placeholder="Enter device ID"
                    />
                    <Button onClick={() => setShowScanner(true)}>
                      <Camera className="h-4 w-4 mr-2" />
                      Scan QR
                    </Button>
                    <Button onClick={handleDeviceAssignment}>
                      <Plus className="h-4 w-4 mr-2" />
                      Add Device
                    </Button>
                  </div>
                </div>
                {showScanner && (
                  <div className="mt-4">
                    <QRScanner
                      onScan={handleQRScan}
                      onClose={() => setShowScanner(false)}
                    />
                  </div>
                )}
              </div>
            </Card>

            <Card className="p-6">
              <h2 className="text-lg font-semibold mb-4">Manage Devices</h2>
              {isLoadingAssignedDevices ? (
                <div className="text-center py-4">
                  <p className="text-muted-foreground">Loading devices...</p>
                </div>
              ) : assignedDevices && assignedDevices.length > 0 ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {assignedDevices?.map((assignment) => (
                    <div key={assignment.device_id} className="flex items-center justify-between p-2 border rounded-lg text-sm">
                      <div className="flex flex-col space-y-0.5">
                        <div className="font-medium">{assignment.device_details.device_name || assignment.device_id}</div>
                        {assignment.device_details.device_description && (
                          <div className="text-xs text-muted-foreground">{assignment.device_details.device_description}</div>
                        )}
                        <div className="text-xs text-muted-foreground">ID: {assignment.device_id}</div>
                      </div>
                      <Button
                        variant="destructive"
                        size="sm"
                        className="h-8 px-3"
                        onClick={() => setDeviceToRemove(assignment.device_id)}
                      >
                        <Trash2 className="h-3.5 w-3.5 mr-1" />
                        Remove
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-4">
                  <p className="text-muted-foreground">No devices assigned</p>
                </div>
              )}
            </Card>

            <Card className="p-6">
              <h2 className="text-lg font-semibold mb-4">Invite User</h2>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium mb-2 block">
                    Email Address
                  </label>
                  <div className="flex gap-2">
                    <Input
                      type="email"
                      value={inviteEmail}
                      onChange={(e) => setInviteEmail(e.target.value)}
                      placeholder="Enter email address"
                    />
                    <Button onClick={() => inviteUser(false)}>
                      <Plus className="h-4 w-4 mr-2" />
                      Invite
                    </Button>
                  </div>
                </div>

                {isLoadingAssignedDevices ? (
                  <div>Loading devices...</div>
                ) : (
                  <div>
                    <label className="text-sm font-medium mb-2 block">
                      Assign Devices
                    </label>
                    <ScrollArea className="h-[150px] border rounded-md p-4">
                      <div className="grid grid-cols-2 gap-4">
                        {assignedDevices?.map((device) => (
                          <div
                            key={device.device_id}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="checkbox"
                              id={device.device_id}
                              checked={selectedDevices.includes(device.device_id)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedDevices([...selectedDevices, device.device_id]);
                                } else {
                                  setSelectedDevices(selectedDevices.filter(id => id !== device.device_id));
                                }
                              }}
                              className="rounded border-gray-300"
                            />
                            <label htmlFor={device.device_id} className="text-sm">
                              {device.device_details?.device_name || device.device_id}
                              {device.device_details?.device_description && (
                                <span className="text-muted-foreground ml-2">
                                  ({device.device_details.device_description})
                                </span>
                              )}
                            </label>
                          </div>
                        ))}
                      </div>
                    </ScrollArea>
                  </div>
                )}
              </div>
            </Card>

            <Card className="p-6">
              <h2 className="text-lg font-semibold mb-4">Manage Users</h2>
              {isLoadingUsers || isLoadingOrgDevices || isLoadingAssignments ? (
                <div className="text-center py-4">
                  <p className="text-muted-foreground">Loading...</p>
                </div>
              ) : organizationUsers && organizationUsers.length > 0 ? (
                <Accordion type="single" collapsible className="space-y-4">
                  {organizationUsers.map((user) => (
                    <AccordionItem key={user.user_id} value={user.user_id}>
                      <AccordionTrigger className="hover:no-underline">
                        <div className="flex items-center space-x-3 p-3">
                          {user.profiles?.avatar_url ? (
                            <img
                              src={user.profiles.avatar_url}
                              alt={user.profiles?.username || 'User avatar'}
                              className="w-8 h-8 rounded-full"
                            />
                          ) : (
                            <div className="w-8 h-8 rounded-full bg-muted flex items-center justify-center">
                              <User className="w-4 h-4 text-muted-foreground" />
                            </div>
                          )}
                          <div className="text-left">
                            <p className="font-medium">
                              {user.profiles?.username || 'Anonymous User'}
                            </p>
                            <p className="text-xs text-muted-foreground capitalize">
                              {user.role}
                            </p>
                          </div>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="p-4 space-y-4">
                        <div className="space-y-4">
                          <h3 className="text-sm font-medium">Device Access Management</h3>
                          <div className="grid grid-cols-2 gap-3">
                            {organizationDevices?.map((device) => (
                              <div key={device.device_id} className="flex items-center space-x-2">
                                <Checkbox
                                  id={`device-${device.device_id}-${user.user_id}`}
                                  checked={isDeviceAssigned(user.user_id, device.device_id)}
                                  onCheckedChange={(checked) => {
                                    handleDeviceToggle(user.user_id, device.device_id, !!isDeviceAssigned(user.user_id, device.device_id));
                                  }}
                                />
                                <label
                                  htmlFor={`device-${device.device_id}-${user.user_id}`}
                                  className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                >
                                  {device.device_name || device.device_id}
                                  {device.device_description && (
                                    <span className="text-xs text-muted-foreground ml-2">
                                      ({device.device_description})
                                    </span>
                                  )}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  ))}
                </Accordion>
              ) : (
                <div className="text-center py-4">
                  <p className="text-muted-foreground">No users found in the organization</p>
                </div>
              )}
            </Card>
          </div>
        </main>
      </div>

      <AlertDialog open={!!deviceToRemove} onOpenChange={() => setDeviceToRemove(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove Device</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to remove this device? This action cannot be undone.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => deviceToRemove && handleDeviceRemoval(deviceToRemove)}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Remove
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </SidebarProvider>
  );
};

export default Admin;
