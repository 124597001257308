
import React from 'react';
import { TableCell, TableRow } from "@/components/ui/table";
import { UserCog } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { UserRoleEdit } from "./UserRoleEdit";
import { SimplifiedUserRole } from "@/types/user";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

interface UserRoleRowProps {
  role: SimplifiedUserRole;
}

export function UserRoleRow({ role }: UserRoleRowProps) {
  const queryClient = useQueryClient();

  const handleUpdateUser = async (userId: string, formData: any) => {
    try {
      const { error: profileError } = await supabase
        .from('profiles')
        .update({ 
          username: formData.username,
        })
        .eq('id', userId);

      if (profileError) throw profileError;

      const { error: roleError } = await supabase
        .from('user_roles')
        .update({ 
          role: formData.role,
          organization_id: formData.organization_id 
        })
        .eq('user_id', userId);

      if (roleError) throw roleError;

      await queryClient.invalidateQueries({ queryKey: ["user-roles"] });
      toast.success('User details updated successfully');
    } catch (error) {
      console.error('Error updating user details:', error);
      toast.error('Failed to update user details');
    }
  };

  return (
    <TableRow>
      <TableCell>{role.user_id}</TableCell>
      <TableCell>{role.profiles?.username || '-'}</TableCell>
      <TableCell>{role.organizations?.name || '-'}</TableCell>
      <TableCell>{role.role}</TableCell>
      <TableCell>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="ghost" size="sm">
              <UserCog className="h-4 w-4" />
              <span className="sr-only">Edit User</span>
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit User Details</DialogTitle>
            </DialogHeader>
            <UserRoleEdit 
              userId={role.user_id}
              defaultValues={{
                username: role.profiles?.username || '',
                organization_id: role.organization_id || '',
                role: role.role
              }}
              onSubmit={(values) => handleUpdateUser(role.user_id, values)}
              isLoading={false}
            />
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
}
