
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ArrowLeft } from "lucide-react";

const ModernSlavery = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-white">
      <div className="mx-auto max-w-4xl px-6 py-8">
        <Button variant="ghost" onClick={() => navigate(-1)} className="mb-6">
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>
        
        <h1 className="text-3xl font-bold mb-8">Modern Slavery Statement</h1>
        
        <div className="prose max-w-none">
          <h2 className="text-xl font-semibold mt-6">8.1 Our Commitment</h2>
          <p>We are committed to preventing modern slavery in our operations and supply chain.</p>

          <h2 className="text-xl font-semibold mt-6">8.2 Our Structure</h2>
          <p>8.2.1 Business Overview:</p>
          <ul>
            <li>Technology services provider</li>
            <li>UK-based operations</li>
            <li>Global supply chain</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">8.3 Due Diligence</h2>
          <p>Our approach includes:</p>
          <ul>
            <li>Supplier risk assessments</li>
            <li>Regular audits</li>
            <li>Training programs</li>
            <li>Reporting mechanisms</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">8.4 Risk Assessment</h2>
          <p>We regularly assess risks in:</p>
          <ul>
            <li>Supply chain operations</li>
            <li>Recruitment practices</li>
            <li>Third-party relationships</li>
          </ul>

          <h2 className="text-xl font-semibold mt-6">8.5 Contact Information</h2>
          <p>For modern slavery concerns or inquiries:</p>
          <p>
            Snapshot Live Ltd<br />
            Thurnby, Leicester, UK<br />
            Tel: +44 (0)1164 976866
          </p>
        </div>
      </div>
    </div>
  );
};

export default ModernSlavery;
